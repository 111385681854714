<template>
  <div class="initial">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
    </div>

    <div class="modal-body">
      <div class="content top">
        <AuctionRegistrationModalBatchCard v-if="state.batch" />
      </div>

      <div class="content middle">
        <p class="description">Este lote será ofertado no evento:</p>

        <AuctionRegistrationModalAuctionCard v-if="state.auction" />
      </div>

      <div class="content bottom">
        <div class="block">
          <button
            class="button is-primary gavel-bt"
            @click="setActiveTab('bid')"
          >
            <GavelIcon class="mr-2" />
            Agendar um lance
          </button>
        </div>

        <div class="block">
          <button class="button is-primary is-outlined" @click="setFlow">
            Fazer cadastro no evento
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chicoImg from "images/chico/chico.png";
import GavelIcon from "icons/cached/gavel.svg";

import AuctionRegistrationModalAuctionCard from "./AuctionRegistrationModalAuctionCard.vue";
import AuctionRegistrationModalBatchCard from "./AuctionRegistrationModalBatchCard.vue";

import {
  state,
  setActiveTab,
  closeModal,
} from "./auctionRegistrationModalContext";

import { onMounted } from "vue";

export default {
  components: {
    GavelIcon,
    AuctionRegistrationModalAuctionCard,
    AuctionRegistrationModalBatchCard,
  },
  setup() {
    onMounted(() => {
      state.previuosTab = "initial";
    });

    const setFlow = () => {
      state.currentFlow = "register";
      setActiveTab("identification");
    };

    return {
      chicoImg,
      state,
      setActiveTab,
      closeModal,
      setFlow,
    };
  },
};
</script>
