<template>
  <div
    id="LoteInfos"
    class="column is-offset-1-desktop is-6-desktop is-12-touch p-4 has-text-weight-semibold section-infos-title section-infos has-background-beige"
  >
    <div class="section-infos-title has-text-weight-medium">
      Informações do Lote
    </div>
  </div>

  <div
    class="column is-offset-1-desktop is-6-desktop is-12-touch has-background-beige animal-infos is-flex"
    :class="animals.length > 4 ? 'infos-shadow' : ''"
  >
    <div
      v-for="(animal, index) in animals"
      :key="index"
      class="animal-register py-2 px-4 is-relative mr-4 has-text-centered has-text-weight-medium is-flex is-align-items-center is-uppercase"
      :class="{ active: current == index }"
      @click="() => (current = index)"
    >
      {{
        animal.register
          ? animal.register
          : animal.register_cod
          ? animal.register_cod
          : `Lote ${batchNumber}`
      }}
      <div class="triangle" />
    </div>
  </div>

  <div
    v-show="animals[current].org || animals[current].ceip"
    class="column is-offset-1-desktop is-6-desktop is-12-touch p-0 mb-3"
  >
    <div class="highlights columns has-text-weight-medium">
      <div
        v-if="animals[current].org"
        class="column certificate is-flex is-align-items-center mb-2"
      >
        <VerifiedUserIcon />
        <div
          class="is-flex is-justify-content-space-between is-align-items-center is-flex-grow-1"
        >
          <span class="ml-4">
            Animal registrado
            <span v-show="animals[current].org == 'zebu'"> na ABCZ </span>
          </span>
        </div>
      </div>
      <div
        v-if="animals[current].ceip"
        class="column ceip is-flex is-align-items-center"
      >
        <figure class="image is-24x24">
          <img :src="ceipPic" alt="ceip" />
        </figure>
        <div
          class="is-flex is-justify-content-space-between is-align-items-center is-flex-grow-1"
        >
          <span class="ml-4"> Animal com CEIP </span>
        </div>
      </div>
    </div>
  </div>

  <div
    v-show="animals[current].description"
    class="column is-offset-1-desktop is-6-desktop is-12-touch p-4 batch-description has-background-beige"
  >
    {{ animals[current].description }}
  </div>

  <div class="column is-offset-1-desktop is-6-desktop is-12-touch p-0">
    <div class="table-infos is-mobile is-clipped">
      <div
        v-if="
          animals[current].name ||
          animals[current].register ||
          animals[current].register_cod
        "
        class="row"
      >
        <div
          v-if="
            animals[current].name &&
            (animals[current].register || animals[current].register_cod)
          "
        >
          Nome (Registro)
        </div>
        <div
          v-else-if="
            !animals[current].name &&
            (animals[current].register || animals[current].register_cod)
          "
        >
          Registro
        </div>
        <div v-else>Nome</div>
        <div
          v-if="
            animals[current].name &&
            (animals[current].register || animals[current].register_cod)
          "
        >
          {{ animals[current].name }} ({{
            animals[current].register || animals[current].register_cod
          }})
        </div>
        <div
          v-else-if="
            !animals[current].name &&
            (animals[current].register || animals[current].register_cod)
          "
        >
          {{ animals[current].register || animals[current].register_cod }}
        </div>
        <div v-else>{{ animals[current].name }}</div>
      </div>

      <div
        v-if="
          animals[current].genetic_type ||
          (animals[current].quantity &&
            animals[current].age &&
            animals[current].breed)
        "
        class="row"
      >
        <div>Categoria</div>
        <div>
          {{ animals[current].quantity }}
          {{
            animals[current].quantity > 1
              ? agesPlural[animals[current].age]
              : animals[current].age
          }}
          {{ animals[current].breed }}
          {{ geneticTypeTranslator[animals[current].genetic_type] }}
        </div>
      </div>

      <div v-if="animals[current].birthdate" class="row">
        <div>Nascimento</div>
        <div>
          {{ animals[current].birthdate }}
        </div>
      </div>

      <div v-if="animals[current].zebu_index" class="row">
        <div>iABCZ {{ animals[current].zebu_ranking ? "- Ranking" : "" }}</div>
        <div class="is-uppercase">
          {{ animals[current].zebu_index }}
          {{
            animals[current].zebu_ranking
              ? `- ${animals[current].zebu_ranking}`
              : ""
          }}
        </div>
      </div>

      <div v-if="animals[current].ceip" class="row">
        <div>Código CEIP</div>
        <div>{{ animals[current].ceip }}</div>
      </div>

      <div v-if="animals[current].reproductive_status" class="row">
        <div>Estado Reprodutivo</div>
        <div>
          {{ animals[current].reproductive_status }}
        </div>
      </div>

      <div v-if="animals[current].calf_register" class="row">
        <div>Registro da Cria ao Pé</div>
        <div>
          {{ animals[current].calf_register }}
        </div>
      </div>

      <div v-if="animals[current].calf_sex" class="row">
        <div>Sexo da Cria ao Pé</div>
        <div>
          {{ animals[current].calf_sex }}
        </div>
      </div>

      <div v-if="animals[current].insemination_date" class="row">
        <div>Data de Inseminação</div>
        <div>
          {{ animals[current].insemination_date }}
        </div>
      </div>

      <div v-if="animals[current].birth_prediction_date" class="row">
        <div>Previsão do Parto</div>
        <div>
          {{ animals[current].birth_prediction_date }}
        </div>
      </div>

      <div v-if="animals[current].mating_format" class="row">
        <div>Form. de Acasalamento</div>
        <div>
          {{ animals[current].mating_format }}
        </div>
      </div>

      <div v-if="animals[current].calf_father_name" class="row">
        <div>Touro Reprodutor</div>
        <div>
          {{ animals[current].calf_father_name }}
        </div>
      </div>

      <div v-if="animals[current].weight" class="row">
        <div>Peso</div>
        <div>
          {{ animals[current].weight }}

          <template v-if="animals[current].weight_unit">
            {{ animals[current].weight_unit }}
          </template>
        </div>
      </div>

      <div v-if="animals[current].scrotal_circumference" class="row">
        <div>CE</div>
        <div>
          {{ animals[current].scrotal_circumference.replace(".", ",") }}cm
        </div>
      </div>

      <div v-if="animals[current].weighing_date" class="row">
        <div>Data da pesagem</div>
        <div>
          {{ animals[current].weighing_date }}
        </div>
      </div>

      <div v-if="animals[current].pelage" class="row">
        <div>Pelagem</div>
        <div>
          {{ animals[current].pelage }}
        </div>
      </div>

      <div v-if="provider" class="row">
        <div>Fornecedor</div>
        <div>
          {{ provider }}
        </div>
      </div>

      <div v-if="full_location" class="row">
        <div>Localização</div>
        <div>
          {{ full_location }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="column is-offset-1-desktop is-6-desktop is-12-touch"
    :class="{
      'is-hidden': !animals[current].mother || !animals[current].father,
    }"
  >
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-mobile is-multiline">
          <div class="column is-6">
            <div
              class="has-text-centered has-text-grey-lighter has-text-weight-semibold is-size-7"
            >
              Avós
            </div>
          </div>

          <div class="column is-6">
            <div
              class="has-text-centered has-text-grey-lighter has-text-weight-semibold is-size-7"
            >
              Pais
            </div>
          </div>

          <div class="column is-6 is-paddingless">
            <div
              class="is-flex is-flex-direction-column is-justify-content-space-around parent is-relative"
            >
              <div class="is-flex is-align-items-center is-relative">
                <div class="male is-flex is-align-items-center">
                  <MaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].fat_grandfather }}
                  </div>
                </div>
                <div class="trace-horizontal right" />
              </div>

              <div class="is-flex is-align-items-center is-relative">
                <div class="female is-flex is-align-items-center">
                  <FemaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].fat_grandmother }}
                  </div>
                </div>
                <div class="trace-horizontal right" />
              </div>

              <div class="trace-vertical" />
            </div>

            <div
              class="is-flex is-flex-direction-column is-justify-content-space-around parent is-relative"
            >
              <div class="is-flex is-align-items-center is-relative">
                <div class="male is-flex is-align-items-center">
                  <MaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].mat_grandfather }}
                  </div>
                </div>
                <div class="trace-horizontal right" />
              </div>

              <div class="is-flex is-align-items-center is-relative">
                <div class="female is-flex is-align-items-center">
                  <FemaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].mat_grandmother }}
                  </div>
                </div>
                <div class="trace-horizontal right" />
              </div>

              <div class="trace-vertical" />
            </div>
          </div>

          <div
            class="column is-6 is-paddingless is-flex is-justify-content-space-around is-flex-direction-column"
          >
            <div
              class="is-flex is-flex-direction-column is-justify-content-space-around parent is-relative"
            >
              <div class="is-flex is-align-items-center is-relative">
                <div class="male is-flex is-align-items-center">
                  <MaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].father }}
                  </div>
                </div>
                <div class="trace-horizontal left" />
              </div>
            </div>

            <div
              class="is-flex is-flex-direction-column is-justify-content-space-around parent is-relative"
            >
              <div class="is-flex is-align-items-center is-relative">
                <div class="female is-flex is-align-items-center">
                  <FemaleIcon />
                  <div class="is-flex is-align-items-center parent-name">
                    {{ animals[current].mother }}
                  </div>
                </div>
                <div class="trace-horizontal left" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FemaleIcon from "icons/cached/female.svg";
import MaleIcon from "icons/cached/male.svg";
import VerifiedUserIcon from "icons/cached/verified_user.svg";
import ceipPic from "images/CEIP-min.png";
import agesPlural from "utils/agesPlural";

import { ref } from "vue";
export default {
  components: {
    FemaleIcon,
    MaleIcon,
    VerifiedUserIcon,
  },
  props: {
    animals: Array,
    batchNumber: String,
    provider: String,
    full_location: String,
  },

  setup(props) {
    const current = ref(0);
    const currentAnimal = ref(props.animals);
    const geneticTypeTranslator = {
      CEIP: "Animal CEIP",
      "P.O.": "P.O. - Puro de Origem",
      "P.A.": "P.A. - Puro por Avaliação",
      "P.C.": "P.C. - Puro por Cruzamento",
    };

    return {
      current,
      currentAnimal,
      geneticTypeTranslator,
      ceipPic,
      agesPlural,
    };
  },
};
</script>
