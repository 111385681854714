<template>
  <ProfileHeader title="Dados pessoais" />

  <section class="user-edit container p-4">
    <FlashMessage
      :is-open="!!flash"
      :message="flash"
      type="notice"
      is-syncronized
    />

    <div v-if="!editMode" class="form is-flex is-flex-direction-column">
      <div>
        <label>E-mail</label>
        <p>{{ email }}</p>
      </div>

      <div>
        <label>Senha</label>
        <p>* * * * * * * * * *</p>
      </div>

      <div>
        <label>Nome completo</label>
        <p>{{ name }}</p>
      </div>

      <div>
        <label>Telefone</label>
        <p>{{ formatPhoneWithDDI(phoneNumber, phone_ddi) }}</p>
      </div>

      <div>
        <label>CPF</label>
        <p>{{ formatCpfCnpj(cpf) }}</p>
      </div>

      <div>
        <label>CNPJ</label>
        <p>{{ formatCpfCnpj(cnpj) || "Não informado" }}</p>
      </div>

      <button
        class="button is-warning is-outlined mt-4"
        @click="editMode = true"
      >
        <p class="has-text-weight-semibold">Editar dados</p>
        <EditIcon class="ml-4" />
      </button>
    </div>

    <div v-else class="form is-flex is-flex-direction-column">
      <div>
        <label>E-mail</label>
        <AppInput
          name="user[email]"
          type="email"
          required
          :value="email"
          is-synchronized
          @input="(value) => (email = value)"
        />
      </div>

      <div>
        <label>Senha</label>
        <div
          class="is-flex is-justify-content-space-between is-clickable password-field"
          @click="openPasswordModal = true"
        >
          * * * * * * * * * *
          <div class="has-text-primary mr-4 has-text-weight-semibold is-size-7">
            ALTERAR
          </div>
        </div>
      </div>

      <div>
        <label>Nome completo</label>
        <AppInput
          name="user[name]"
          required
          :value="name"
          is-synchronized
          @input="(value) => (name = value)"
        />
      </div>

      <div>
        <label>Telefone</label>
        <div class="is-flex is-align-items-center">
          <div class="select-input bigger">
            <select id="select" name="user[phone_ddi]" v-model="phone_ddi">
              <option value="" disabled selected>DDI</option>
              <option
                v-for="(value, key) in ddiOptions"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </div>

          <AppInput
            v-mask="
              phone_ddi == '55'
                ? ['(##) ####-#####', '(##) # ####-####']
                : '###############'
            "
            class="is-flex-grow-1"
            name="user[phone]"
            :value="phoneNumber"
            type="tel"
            required
            @input="(value) => (phoneNumber = value)"
          />
        </div>
      </div>

      <div>
        <label>CPF</label>
        <AppInput
          v-mask="'###.###.###-##'"
          name="user[cpf]"
          :value="cpf"
          is-synchronized
          @input="(value) => (cpf = value)"
          required
          type="tel"
        />
      </div>

      <div>
        <label>CNPJ</label>
        <AppInput
          v-mask="'##.###.###/####-##'"
          name="user[cnpj]"
          :value="cnpj"
          is-synchronized
          @input="(value) => (cnpj = value)"
        />
      </div>

      <button class="button is-warning mt-4" type="submit">
        <p class="has-text-weight-semibold">Confirmar alteração</p>
        <CheckIcon class="ml-4" />
      </button>
    </div>
  </section>

  <UpdatePasswordModal
    v-if="openPasswordModal"
    @close="openPasswordModal = false"
    @update="flash = $event"
  />
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import { formatCpfCnpj, formatPhoneWithDDI } from "utils/formatters";
import { mask } from "vue-the-mask";

import ProfileHeader from "../profile/ProfileHeader.vue";
import AppInput from "components/ui/AppInput.vue";
import UpdatePasswordModal from "../UpdatePasswordModal.vue";

import EditIcon from "icons/cached/edit.svg";
import CheckIcon from "icons/cached/check.svg";
import FlashMessage from "components/ui/FlashMessage.vue";

export default {
  directives: {
    mask,
  },
  components: {
    ProfileHeader,
    AppInput,
    UpdatePasswordModal,
    FlashMessage,
    EditIcon,
    CheckIcon,
  },
  props: {
    user: Object,
    ddiOptions: Object,
  },
  setup(props) {
    const { email, name, phone, phone_area, cpf, cnpj, phone_ddi } = toRefs(
      reactive(props.user),
    );

    const phoneNumber = ref(
      phone_area.value ? phone_area.value + phone.value : phone.value,
    );
    const editMode = ref(false);
    const openPasswordModal = ref(false);
    const flash = ref(null);

    return {
      editMode,
      formatCpfCnpj,
      formatPhoneWithDDI,
      email,
      name,
      phoneNumber,
      cpf,
      cnpj,
      phone_ddi,
      openPasswordModal,
      flash,
    };
  },
};
</script>

<style></style>
