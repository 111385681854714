<template>
  <button
    :class="{ 'is-loading': state.isLoading }"
    class="is-clickable editing"
    @click="generateDescription"
  >
    <OpenaiIcon />
  </button>
</template>

<script>
import OpenaiIcon from "icons/cached/openai.svg";
import {
  generateDescription,
  state,
} from "./describeAnimalContext.js";
import { onMounted } from "vue";

export default {
  components: {
    OpenaiIcon,
  },
  props: {
    animalId: Object,
  },
  setup(props) {
    onMounted(() => {
      state.animalId = props.animalId;
    });

    return {
      state,
      generateDescription,
    };
  },
};
</script>
