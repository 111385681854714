<template>
  <transition name="start-quiz">
    <div v-if="!finishedQuiz">
      <transition name="change-quest">
      <div v-if="showProgressBar" class="question-header">
        <ProgressBar :value="(currentQuestion-1)*100/6" barHeight="bar-height-big" />
        <span>{{ currentQuestion > 6 ? 6 : currentQuestion }} de 6</span>
      </div>
      </transition>
      <transition name="change-quest">
        <component :is="quizComponent" v-if="showComponent" :question="currentQuestion" :name="name" :email="email" :phone="phone"/>
      </transition>
    </div>
  </transition>
</template>

<script>
import ProgressBar from "components/ui/ProgressBar.vue";
import Question from "./Question.vue";
import RegisterForm from "./RegisterForm.vue";

import { quizState, sendChatbotAnswer } from './questions.js';
import { ref, watch, toRefs } from "vue";
export default {
  props: {
    name: String,
    email: String,
    phone: String,
  },
  components : {
    Question,
    ProgressBar,
    RegisterForm,
  },
  setup(props){
    const { currentQuestion, knownCustomer, finishedQuiz } = toRefs(quizState);
    const showComponent = ref(true);
    const showProgressBar = ref(true);
    const quizComponent = ref('Question');
    var questionNumber = currentQuestion.value;

    const nextQuestion = async () => {
      setTimeout(() => {
        showComponent.value = false;
      }, 300);

      if (questionNumber <= 6) {
        setTimeout(() => {
          showComponent.value = true;
        }, 800);
      }
      else {
        finishedQuestions();
      }
    }

    const prevQuest = async () => {
      mixpanel.track('Ideal Bull - Return Clicked', { 'Question': questionNumber });

      await setTimeout(() => {
        showComponent.value = false;
      }, 300);
      if (questionNumber == 6) {
        setTimeout(() => {
          quizComponent.value = 'Question';
          showProgressBar.value = true;
          showComponent.value = true;
        }, 1000);
      } else {
        setTimeout(() => {
          showComponent.value = true;
        }, 800);
      }
    }

    const finishedQuestions = async () => {
      if(knownCustomer.value){
        let answer = [{
          question: "Quiz incompleto?",
          answer: "false",
          attribute_name: "[TOURO IDEAL] Incompletos",
        }];
        sendChatbotAnswer(answer, props.email);
        finishedQuiz.value = true;
      }
      else {
        showProgressBar.value = false;
        setTimeout(() => {
          quizComponent.value = 'RegisterForm';
          showComponent.value = true;
        }, 1000);
      }
    };

    watch(currentQuestion, () => {
      if(currentQuestion.value > questionNumber) {
        questionNumber++;
        nextQuestion();
      } else {
        questionNumber--;
        prevQuest();
      }
    });


    return {
      finishedQuiz,
      showComponent,
      showProgressBar,
      quizComponent,
      currentQuestion,
    }
  }

}
</script>
