<template>
  <div
    class="columns conditions is-variable is-1 is-centered is-touch is-multiline"
  >
    <div
      v-if="shipping"
      class="column is-6-destkop is-12-touch padding-bottom-8"
    >
      <div class="condition" @click="openAndTrackShipping()">
        <LocalShippingIcon />
        <div class="is-flex-grow-1">
          {{ shipping }}
        </div>
        <span class="circle">?</span>
      </div>
    </div>
    <div v-if="payment" class="column is-6-destkop is-12-touch no-padding">
      <div class="condition" @click="openAndTrackPayment()">
        <MonetizationIcon />
        <div class="is-flex-grow-1">
          <span>{{ payment }}</span>
        </div>
        <span class="circle">?</span>
      </div>
    </div>
  </div>

  <AuctionPaymentModal
    :open="openPaymentModal"
    :payment="payment"
    :paymentExplanation="paymentExplanation"
    @close="() => (openPaymentModal = false)"
  />

  <AuctionShippingModal
    :open="openShippingModal"
    :shipping="shipping"
    :shippingExplanation="shippingExplanation"
    @close="() => (openShippingModal = false)"
  />
</template>

<script>
import { ref } from "vue";
import MonetizationIcon from "icons/cached/monetization_on.svg";
import LocalShippingIcon from "icons/cached/local_shipping.svg";
import AuctionPaymentModal from "./AuctionPaymentModal.vue";
import AuctionShippingModal from "./AuctionShippingModal.vue";

export default {
  components: {
    MonetizationIcon,
    LocalShippingIcon,
    AuctionPaymentModal,
    AuctionShippingModal,
  },
  props: {
    payment: String,
    paymentExplanation: String,
    shipping: String,
    shippingExplanation: String,
    mixpanelData: Object,
  },
  setup(props) {
    const openPaymentModal = ref(false);
    const openShippingModal = ref(false);

    const openAndTrackPayment = () => {
      openPaymentModal.value = true;
      mixpanel.track('Payment Info Clicked', { 'Page': 'Landing Page', ...props.mixpanelData });
    }

    const openAndTrackShipping = () => {
      openShippingModal.value = true;
      mixpanel.track('Shipping Info Clicked', { 'Page': 'Landing Page', ...props.mixpanelData });
    }

    return {
      openPaymentModal,
      openShippingModal,
      openAndTrackShipping,
      openAndTrackPayment,
    };
  },
};
</script>
