<template>
  <div class="pre-registration-form">
    <div class="columns is-multiline">
      <div class="column is-12">
        <AppInput
          placeholder="Digite"
          label="Seu nome"
          :error="false"
          :value="userName"
          @input="(v) => (userName = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          ref="emailInput"
          name="email"
          type="email"
          placeholder="Digite"
          label="E-mail"
          :value="email"
          :show-message="emailInputError"
          :error="emailInputError"
          @input="(v) => (userEmail = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          v-mask="['(##) ####-####', '(##) # ####-####']"
          placeholder="(00) 0 0000 - 0000"
          type="tel"
          message="Validate"
          label="Telefone com DDD"
          :value="phone"
          :show-message="false"
          :error="false"
          @input="(v) => (userPhone = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          v-mask="['###.###.###-##']"
          type="tel"
          :value="userCPF"
          placeholder="Digite seu CPF"
          label="CPF"
          :error="errorCPF"
          message="CPF inválido"
          :show-message="errorCPF"
          is-synchronized
          required
          @input="
            (value) => {
              userCPF = value;
            }
          "
        />
      </div>
      <div v-show="showCallbackMessage" class="column is-12">
        <div class="notification is-light" :class="notificationClassStatus">
          {{ callbackMessage }}
        </div>
      </div>
    </div>
    <div class="has-text-centered">
      <button
        class="button is-warning"
        :disabled="isDisabled || !isValidCPF()"
        :class="{
          'is-loading': submitting,
        }"
        @click="preRegistrationFormSubmit"
      >
        {{ cta || "Enviar" }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, watch } from "vue";
import { mask } from "vue-the-mask";
import AppInput from "components/ui/AppInput.vue";
import { cpfValidate } from "utils/documentValidate";

import {
  updatePreRegistrationState,
  preRegistrationState,
} from "components/registration_flow/preRegistrationContext";
import api from "services/api";
import { trackFacebookWithRedundancy } from "services/facebookTracker";
import { trackGoogleAnalytics } from "services/googleAnalyticsTracker";

export default {
  directives: {
    mask,
  },
  components: {
    AppInput,
  },
  props: {
    cta: String,
    name: String,
    email: String,
    phone: String,
    lpRedirectLink: String,
    lpConversion: Object,
    trackData: Object,
    formDisplay: String,
    page: String,
    section: String,
    mixpanelData: Object,
  },
  setup(props) {
    const { conversion } = toRefs(preRegistrationState);

    const userName = ref(props.name);
    const userEmail = ref(props.email);
    const userPhone = ref(props.phone);
    const userCPF = ref("");
    const currentFormDisplay = ref(props.formDisplay);

    const registered = ref(false);
    const submitting = ref(false);
    const isDisabled = computed(() => {
      if (submitting.value || registered.value) {
        return true;
      }

      return !(
        !!checkEmail(userEmail.value) &&
        !!userName.value &&
        !!checkPhone(userPhone.value)
      );
    });

    const checkPhone = (value) => {
      if (value.includes("(") && value.length >= 14) return true;

      return (
        !value.includes("(") && (value.length === 10 || value.length === 11)
      );
    };

    const emailInput = ref(null);
    const emailInputError = ref(false);
    const emailInputErrorMessage = "E-mail inválido! Tente novamente";

    watch(userEmail, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    });

    const checkEmail = (value) => {
      if (value) {
        return !emailInputError.value;
      } else {
        emailInputError.value = false;

        return false;
      }
    };

    const showCallbackMessage = ref(false);
    const callbackMessage = ref("");
    const notificationClassStatus = ref("");

    const preRegistrationFormSubmit = () => {
      const currentConversion = props.lpConversion;

      showCallbackMessage.value = false;
      submitting.value = true;
      currentConversion.lp_conversion.name = userName.value;
      currentConversion.lp_conversion.email = userEmail.value;
      currentConversion.lp_conversion.document_type = "CPF";
      currentConversion.lp_conversion.document_number = userCPF.value;
      currentConversion.additional_infos.push({
        question: "CPF",
        answer: userCPF.value,
      });

      const phone = userPhone.value.replace(/\(|\)|\-|\s/g, "");

      currentConversion.lp_conversion.phone =
        phone.slice(0, 2) + " " + phone.slice(2);

      api
        .post(currentConversion.action, currentConversion)
        .then(({ data }) => {
          const track = ref(props.trackData);

          registered.value = true;
          submitting.value = false;
          track.value.email = currentConversion.lp_conversion.email;
          callbackMessage.value = "Cadastro realizado com sucesso!";
          showCallbackMessage.value = true;
          notificationClassStatus.value = "is-success";

          trackFacebookWithRedundancy("Criou Pré-Cadastro", data.customer_id);

          trackFacebookWithRedundancy("Conversão Lote", data.customer_id);
          trackGoogleAnalytics("conversao_lote");

          if (data.new_pipe) {
            trackFacebookWithRedundancy("Novo Pipe", data.customer_id);
            trackGoogleAnalytics("novo_pipe");
          }

          if (data.new_repurchase_pipe) {
            trackFacebookWithRedundancy("Novo Pipe Revenda", data.customer_id);
            trackGoogleAnalytics("novo_pipe_revenda");
          }

          const cvData = {
            "Conversion Name": userName.value,
            "Conversion Email": userEmail.value,
            "Conversion Phone": userPhone.value,
            "Form Display": currentFormDisplay.value,
            Page: props.page,
            Section: props.section,
            ...props.mixpanelData,
          };

          if (props.section == "CTA-Button") {
            cvData["Section"] = document
              .getElementById("ctaButton")
              .getAttribute("data-track");
          }

          if (["Event", "Product", "Landing Page"].includes(props.page)) {
            mixpanel.track(`${props.page} - User Converted`, cvData);
          }

          if (props.lpRedirectLink) {
            submitting.value = true;
            window.location.href = props.lpRedirectLink;
          } else {
            updatePreRegistrationState({
              conversion: data,
              openRegistrationModal: true,
            });
          }
        })
        .catch((err) => {
          submitting.value = false;
          callbackMessage.value =
            "Ocorreu um erro inesperado! Tente novamente.";
          showCallbackMessage.value = true;
          notificationClassStatus.value = "is-danger";
        });
    };

    watch(conversion, () => {
      if (conversion.value) {
        registered.value = true;
        callbackMessage.value = "Cadastro realizado com sucesso!";
        showCallbackMessage.value = true;
        notificationClassStatus.value = "is-success";
      }
    });

    const errorCPF = ref(false);
    const isValidCPF = () => {
      const cpf = userCPF.value;
      if (!cpf) return false;

      const isValid = cpfValidate(cpf.replace(/[\.\-\/]/g, ""));
      errorCPF.value = !isValid && cpf.length === 14;

      return isValid;
    };

    return {
      userName,
      userEmail,
      userPhone,
      userCPF,
      isDisabled,
      emailInput,
      emailInputError,
      emailInputErrorMessage,
      preRegistrationFormSubmit,
      submitting,
      showCallbackMessage,
      notificationClassStatus,
      callbackMessage,
      registered,
      conversion,
      errorCPF,
      isValidCPF,
    };
  },
};
</script>
