<template>
  <div
    class="modal ordination-bottom-sheet is-justify-content-end is-hidden-desktop"
    :class="{ 'is-active': bottomSheetState.isActive }"
  >
    <div class="modal-background" @click="toggleActive()" />
    <div class="modal-content is-relative fade-in-up">
      <CloseIcon class="close-icon is-clickable" @click="toggleActive()" />

      <h1 class="is-size-5 has-text-weight-bold has-text-centered mb-4">
        Ordenar lotes por
      </h1>
      <div class="line mb-4"></div>

      <div class="ordination-tags is-flex is-flex-wrap-wrap">
        <div
          v-for="ordination in bottomSheetState.ordinationTypes"
          class="tag is-size-7 is-clickable has-text-weight-semibold p-4"
          :class="{ active: ordination.active }"
          @click="changeOrdination(ordination)"
        >
          {{ ordination.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  bottomSheetState,
  toggleActive,
  changeOrdination,
} from "./auctionBatchesContext";

import CloseIcon from "icons/cached/close.svg";

export default {
  components: { CloseIcon },
  props: {},
  setup() {
    return { bottomSheetState, toggleActive, changeOrdination };
  },
};
</script>
