<template>
  <div class="address-container page-header">
    <div class="message-title">
      <div class="back" @click.prevent="changePage('GeneralPage')">
        <KeyboardBackSpaceIcon class="arrow-back" />
      </div>
      <div class="message-text has-text-weight-bold">Nosso endereço</div>
    </div>
    <div class="message-subtitle is-size-7 mb-4">
      Escolha abaixo o meio de comunicação<br />
      que preferir.
    </div>
    <div class="address-field mb-5">
      <div class="address-state has-border-beige-dark">
        <div class="state-title">
          <div class="icon-building">
            <ApartmentIcon />
          </div>
          <div class="title-text has-text-weight-semibold">Salvador - BA</div>
        </div>
        <div class="state-text is-size-7">
          <div class="mb-2 has-text-grey-light">
            Av Professor Magalhães Neto, 1856, Pituba<br />
          </div>
          <div class="mb-2 has-text-grey-light">
            Edifício TK Tower - Sala 1806<br />
          </div>
          <div class="mb-2 has-text-grey-light">
            Salvador, Bahia - 41810-011<br />
          </div>
          <a
            class="state-link"
            target="_blank"
            href="https://www.google.com/maps/place/erural/@-12.9846245,-38.4515151,15z/data=!4m2!3m1!1s0x0:0x5fd3c15ce4fd23fc?sa=X&ved=2ahUKEwits7Wh5Lb0AhUXFbkGHb8NBPUQ_BJ6BAhFEAU"
          >
            Clique para ver no mapa
          </a>
        </div>
      </div>
    </div>
    <div class="contact-infos">
      <div class="contact-info mb-4">
        <span class="is-size-7 has-text-weight-semibold">
          Horário de atendimento:<br />
        </span>
        <span class="is-size-7">
          De segunda a sexta, das 10h às 18h,<br />
          exceto feriados.<br />
        </span>
      </div>
      <div class="contact-info">
        <span class="is-size-7 has-text-weight-semibold">
          Telefone de Contato:<br />
        </span>
        <span class="is-size-7"> (16) 9 9742-0031<br /> </span>
      </div>
    </div>
  </div>
</template>

<script>
import KeyboardBackSpaceIcon from "icons/cached/keyboard_backspace.svg";
import ApartmentIcon from "icons/cached/apartment.svg";

import { changePage } from "./currentPage";

export default {
  components: {
    KeyboardBackSpaceIcon,
    ApartmentIcon,
  },

  setup() {
    return {
      changePage,
    };
  },
};
</script>

<style></style>
