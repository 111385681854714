<template>
  <div class="message-container page-header">
    <div class="message-title">
      <div class="back" @click.prevent="changePage('GeneralPage')">
        <KeyboardBackSpaceIcon class="arrow-back" />
      </div>
      <div class="message-text has-text-weight-bold">Envie uma mensagem</div>
    </div>
    <div class="message-subtitle is-size-7 mb-4">
      Preencha o formulário abaixo e nos envie<br />
      uma mensagem:
    </div>
    <div class="form-container">
      <form :action="contact_path()" method="post">
        <AppInput
          label="Seu nome"
          name="contact[name]"
          placeholder="Escreva aqui"
          :small="true"
          :required="true"
        />
        <AppInput
          ref="emailInput"
          label="Seu e-mail"
          name="contact[email]"
          placeholder="Escreva aqui"
          type="email"
          :value="contactEmail"
          :error="emailInputError"
          :required="true"
          :small="true"
          @input="(v) => (contactEmail = v)"
        />
        <AppInput
          v-mask="['(##) # ####-####', '(##) ####-####']"
          label="Seu telefone"
          name="contact[phone]"
          placeholder="Escreva aqui"
          :required="true"
          :isSynchronized="true"
          :small="true"
        />
        <div class="select-input select input-component mb-5">
          <label for="reasonSelect">Qual o motivo?</label>
          <div class="select-container">
            <select
              id="reasonSelect"
              name="contact[type_message]"
              class="select-options"
              required
            >
              <option value="" disabled selected>Selecione</option>
              <option value="Problemas com conta">Problemas com conta</option>
              <option value="Problemas com login">Problemas com acesso</option>
              <option value="Dúvida">Dúvida</option>
              <option value="Outros">Outros</option>
            </select>
          </div>
        </div>
        <div class="text-input">
          <label class="text-message">Sua mensagem</label>
          <textarea
            required
            class="textarea"
            name="contact[message]"
            placeholder="Digite"
          />
        </div>
        <div class="form-button">
          <button class="button is-primary" type="submit">
            Enviar mensagem
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { contact_path } from "utils/routes";
import AppInput from "components/ui/AppInput.vue";
import KeyboardBackSpaceIcon from "icons/cached/keyboard_backspace.svg";
import { mask } from "vue-the-mask";

import { changePage } from "./currentPage";

export default {
  components: {
    KeyboardBackSpaceIcon,
    AppInput,
  },
  directives: { mask },

  setup() {
    const contactEmail = ref("");
    const emailInput = ref(null);
    const emailInputError = ref(false);

    watch(contactEmail, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    });

    return {
      contact_path,
      changePage,
      contactEmail,
      emailInput,
      emailInputError,
    };
  },
};
</script>

<style></style>
