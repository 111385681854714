import { reactive, ref, toRefs, computed } from "vue";

const activeAuthStep = ref("login");
const updateActiveAuthStep = (currentAuthStep) => {
  activeAuthStep.value = currentAuthStep;
}

const userAuthInfos = reactive({
  login: '',
  password: '',
  email: '',
  cpf: '',
  name: '',
  phone: '',
  phone_ddi: '55',
  terms: false,
  cookies: false,
  newsletter: false,
  notification: null,
  countryDdi: {}
});

const updateAuthUser = (userObj) => {
  for (let [key, value] of Object.entries(userObj)) {
    userAuthInfos[key] = value;
  }
}

const validateLogin = (login) => {
  const emailRegex = /\S+@\S+\.\S+/;
  const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;

  return emailRegex.test(login) || cpfRegex.test(login);
};

const cpfMask = "###.###.###-##";
const emailMask = "*".repeat(128);
const loginMask = reactive({
  mask: computed(() =>
    userAuthInfos.login.length > 2 && !/[^0-9\.-]/.test(userAuthInfos.login)
      ? cpfMask
      : emailMask,
  ),
  tokens: {
    "#": { pattern: /\d/ },
    "*": { pattern: /./ },
  },
});

export const useAuthInfos = () => {
  return {
    activeAuthStep,
    ...toRefs(userAuthInfos),
    updateActiveAuthStep,
    updateAuthUser,
    validateLogin,
    loginMask,
  };
}
