<template>
  <div
    v-show="featuredVisible"
    class="container crawled-featured-auction-content"
  >
    <div
      class="columns is-marginless has-text-centered-mobile has-text-weight-semibold mt-5 pt-2 mb-4 pb-1"
    >
      Eventos em Destaque
      <span class="is-hidden-mobile">:</span>
    </div>

    <div class="columns m-0 is-multiline is-mobile featured-auction-cards">
      <FeaturedAuctionCard
        v-for="auction in auctions"
        :key="auction.id"
        :auction="auction"
        page="Scheduler"
        section="Featured Events"
      />
    </div>
  </div>
</template>

<script>
import { useCrawledAuctions } from "hooks/useCrawledAuctions";
import FeaturedAuctionCard from "components/auction/FeaturedAuctionCard.vue";

export default {
  components: {
    FeaturedAuctionCard,
  },
  props: {
    auctions: Object,
    host: String,
  },

  setup() {
    const { featuredVisible } = useCrawledAuctions();

    return {
      featuredVisible,
    };
  }
};
</script>
