<template>
  <button
    class="button is-fullwidth"
    :class="customClass"
    :track-section="trackSection"
    @click="setFlow"
  >
    {{ cta }}
  </button>
</template>

<script>
import { state, setActiveTab } from "./auctionRegistrationModalContext";
import { buttonProps } from "utils/buttonProps";

export default {
  props: {
    cta: String,
    customClass: String,
    currentFlow: String,
    trackSection: String,
    mixpanelData: Object,
    trackData: Object,
    page: String,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
  },
  setup(props) {
    const setFlow = (e) => {      
      if (props.currentFlow == "proposal") {
        setActiveTab("proposalIdentification");
      } else {
        setActiveTab(props.currentFlow);
      }
      
      if (props.currentFlow == "identification") {
        state.currentFlow = "register";
      } else {
        state.currentFlow = props.currentFlow;
      }

      state.isModalOpen = true;
      state.currentConversion = JSON.parse(JSON.stringify(props.lpConversion));

      state.currentTrackedUser.name = props.name;
      state.currentTrackedUser.email = props.email;
      state.currentTrackedUser.phone = props.phone;

      let element = e.currentTarget;

      state.cvData = {
        "Conversion Name": props.name,
        "Conversion Email": props.email,
        "Conversion Phone": props.phone,
        Page: props.page,
        ...props.mixpanelData,
        ...buttonProps(element),
      };

      if (state.currentFlow == "register" || state.currentFlow == "proposal") {
        mixpanel.track(`${props.page} CTA Clicked #1`, state.cvData);
      } else if (state.currentFlow == "message") {
        mixpanel.track(`${props.page} CTA Clicked #2`, state.cvData);
      }
    };

    return {
      state,
      setFlow,
    };
  },
};
</script>
