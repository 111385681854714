<template>
  <div v-if="active" class="live-modal">
    <div class="live-text px-4">
      Estamos ao vivo agora com o
      <br />
      <p class="has-text-weight-semibold is-uppercase live-title">
        {{ auctionTitle }}
      </p>
    </div>
    <a
      class="button is-warning mt-2 live-button"
      :href="auctions_show_live_url(auctionUrlFriendly)"
    >
      <div>Assistir {{ auctionTypeName[auctionType] }}</div>
    </a>

    <a
      class="close-button delete"
      @click.prevent="active = false"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { auctions_show_live_url } from "utils/routes";

export default {
  props: {
    auctionTitle: String,
    auctionType: String,
    auctionUrlFriendly: String,
  },
  setup() {
    const active = ref(false);

    const auctionTypeName = {
      0: "Leilão 360º",
      1: "Leilão erural",
      2: "Shopping",
      3: "Venda permanente",
    };

    onMounted(() => {
      setTimeout(() => {
        active.value = true;
      }, 5000);
    });

    return {
      active,
      auctionTypeName,
      auctions_show_live_url,
    };
  },
};
</script>
