<template>
  <div class="what-sell columns" :class="{ open: choosedOpen }">
    <div ref="whatTitle" class="what-sell-title mb-4 has-text-weight-bold">
      O que você deseja vender hoje?
    </div>
    <div class="choose-field">
      <div
        class="choose-sell mb-6"
        :class="{ 'choosed-border': choosedOpen }"
        @click="
          $refs.whatTitle.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        "
      >
        <div
          class="choosed"
          :class="{ 'choosed-item': gadoOrPo && choosedOpen }"
          @click.prevent="
            () => {
              choosedOpen = true;
              gadoOrPo = true;
            }
          "
        >
          Reposição
        </div>
        <div
          class="choosed"
          :class="{ 'choosed-item': !gadoOrPo && choosedOpen }"
          @click.prevent="
            () => {
              choosedOpen = true;
              gadoOrPo = false;
            }
          "
        >
          Genética
        </div>
      </div>

      <div v-if="choosedOpen" class="choose-options mb-6">
        <div v-if="gadoOrPo" class="gado-options mb-6">
          <div class="gado-city mb-6">
            <AppInput
              id="autoCompleteInput"
              ref="adressInputRef"
              label="Em que cidade está?"
              placeholder="Digite"
              class="input-city"
              required
              :value="adressInput"
              :isSynchronized="true"
              :small="true"
              @input="(v) => (adressInput = v)"
            />
          </div>
          <div class="quantity-button">
            <div class="quantity-title mb-3">
              Quantos animais pretende vender,<br />
              em média?
            </div>
            <div class="quantity-field">
              <div class="minus-button" @click.prevent="minusGado()">
                <RemoveIcon />
              </div>
              <div v-if="gadoMax" class="quantity-number">100 +</div>
              <div v-else class="quantity-number">
                {{ quantityGado }}
              </div>
              <div class="plus-button" @click.prevent="plusGado">
                <AddIcon />
                <div />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!gadoOrPo" class="po-options mb-6">
          <div class="quantity-button mb-6">
            <div class="quantity-title mb-3">
              Quantos animais pretende vender,<br />
              em média?
            </div>
            <div class="quantity-field">
              <div class="minus-button" @click.prevent="minusPo">
                <RemoveIcon />
              </div>
              <div v-if="poMax" class="quantity-number">70 +</div>
              <div v-else class="quantity-number">
                {{ quantityPo }}
              </div>
              <div class="plus-button" @click.prevent="plusPo">
                <AddIcon />
                <div />
              </div>
            </div>
          </div>
          <div class="radio-option">
            <div class="radio-title mb-2 has-text-centered">
              Você costuma comercializar seus animais<br />
              em Leilões?
            </div>
            <div class="radio-options">
              <div class="radio">
                <input
                  id="answer-no"
                  v-model="doAuction"
                  type="radio"
                  value="Não"
                  name="answer"
                  required
                /><label for="answer-no">Não</label>
              </div>
              <div class="radio">
                <input
                  id="answer-yes"
                  v-model="doAuction"
                  type="radio"
                  value="Sim"
                  name="answer"
                  required
                /><label for="answer-yes">Sim</label>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-info">
          <div
            class="info-title has-text-weight-semibold mb-5 has-text-centered"
          >
            Deixe os seus contatos e receba os próximos<br
              class="is-hidden-desktop"
            />
            passos pelo Whatsapp!
          </div>
          <AppInput
            label="Nome"
            name="name"
            class="input-item"
            placeholder="Digite"
            :value="name"
            :isSynchronized="true"
            :small="true"
            :required="true"
            @input="(v) => (name = v)"
          />
          <AppInput
            ref="emailInput"
            label="E-mail"
            name="email"
            type="email"
            class="input-item"
            placeholder="Digite"
            :value="email"
            :error="emailInputError"
            :isSynchronized="true"
            :small="true"
            :required="true"
            @input="(v) => (email = v)"
          />
          <AppInput
            v-mask="['(##) # ####-####', '(##) ####-####']"
            label="Celular Whatsapp"
            name="tel"
            type="tel"
            class="input-item"
            placeholder="(00) 0 0000 - 0000"
            :value="phone"
            :isSynchronized="true"
            :small="true"
            :required="true"
            @input="(v) => (phone = v)"
          />
          <button
            type="button"
            class="button is-primary button-contact"
            :disabled="isDisabled"
            @click.prevent="submitConversion"
          >
            Vender conosco
          </button>
        </div>
      </div>
    </div>
    <div v-if="!choosedOpen" class="nothing-choosed mt-2">
      Selecione no botão acima o que você<br />
      gostaria de vender conosco.
    </div>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from "vue";
import RemoveIcon from "icons/cached/remove.svg";
import AddIcon from "icons/cached/add.svg";
import AppInput from "components/ui/AppInput.vue";
import { mask } from "vue-the-mask";
import api from "services/api";

export default {
  components: { RemoveIcon, AddIcon, AppInput },
  directives: { mask },

  setup() {
    const adressInput = ref("");
    const email = ref("");
    const name = ref("");
    const phone = ref("");
    const adressInputRef = ref(null);
    const chooseOptions = ref(null);
    const choosedOpen = ref(false);
    const gadoOrPo = ref(false);
    const quantityGado = ref(0);
    const gadoMax = ref(false);
    const quantityPo = ref(0);
    const poMax = ref(false);
    const doAuction = ref("");
    const emailInput = ref(null);
    const emailInputError = ref(false);
    const isDisabled = ref(true);
    var whatsappHref = "";

    watch(email, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    });

    watch(adressInputRef, () => {
      if (!adressInputRef.value) return;
      const autocomplete = new google.maps.places.Autocomplete(
        adressInputRef.value.inputEl,
      );

      autocomplete.addListener("place_changed", () => {
        adressInput.value = autocomplete.getPlace().formatted_address;
      });
    });

    watchEffect(() => {
      var whatsappNumber = "5571999839707";
      var whatsappText = "";

      if (!choosedOpen.value) {
        isDisabled.value = true;
        return;
      }

      if (gadoOrPo.value) {
        if (!adressInput.value || quantityGado.value == 0) {
          isDisabled.value = true;
          return;
        }

        var max = `cerca de ${quantityGado.value}`;
        if (gadoMax.value) {
          max = "mais de 100";
        }

        whatsappText = `Oi, Danilo! Sou ${name.value} e tenho interesse em vender animais comerciais na erural. Estou em ${adressInput.value} e quero comercializar ${max} animais. Quais são os próximos passos? Poderia me explicar melhor?`;
      } else {
        if (!doAuction.value || quantityPo.value == 0) {
          isDisabled.value = true;
          return;
        }

        var answerAuction = "";

        if (doAuction.value === "Sim") {
          answerAuction = "Participo de leilões";
        } else answerAuction = "Não costumo vender em leilões";

        var max = `cerca de ${quantityPo.value}`;
        if (poMax.value) {
          max = "mais de 70";
        }

        whatsappText = `Oi, Danilo! Sou ${name.value} e tenho interesse em vender animais P.O na erural. ${answerAuction} e quero contar com vocês para comercializar ${max} animais. Quais são os próximos passos? Poderia me explicar melhor?`;
      }

      if (
        !name.value ||
        emailInputError.value ||
        !email.value ||
        !phone.value
      ) {
        isDisabled.value = true;
        return;
      }

      whatsappHref = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${whatsappText}`;
      isDisabled.value = false;
    });

    const submitConversion = () => {
      var conversionData;
      var phoneConversion = phone.value.replace(/(\s[^\s]*)\s/g, "$1");

      if (gadoOrPo.value) {
        var plus = "";
        if (gadoMax.value) plus = " +";
        conversionData = {
          lp_conversion: {
            landing_page_title: "Venda conosco - Reposição",
            name: name.value,
            email: email.value,
            phone: phoneConversion,
          },
          additional_infos: [
            {
              question: "Em que cidade está?",
              answer: adressInput.value,
            },
            {
              question: "Quantos animais pretende vender?",
              answer: `${quantityGado.value}${plus}`,
            },
          ],
          additional_attributes: {
            "PADRÃO - Interesse inferido": "Comercial",
          },
        };
      } else {
        var plus = "";
        if (poMax.value) plus = " +";
        conversionData = {
          lp_conversion: {
            landing_page_title: "Venda conosco - Genética",
            name: name.value,
            email: email.value,
            phone: phoneConversion,
          },
          additional_infos: [
            {
              question: "Quantos animais pretende vender, em média?",
              answer: `${quantityPo.value}${plus}`,
            },
            {
              question: "Você realiza leilões?",
              answer: doAuction.value,
            },
          ],
          additional_attributes: {
            "PADRÃO - Interesse inferido": "P.O.",
          },
        };
      }

      api
        .post("/conversoes_landing_page", conversionData)
        .then(() => window.location.reload());

      window.open(whatsappHref, "_blank");
    };

    const plusGado = () => {
      if (quantityGado.value >= 100) {
        gadoMax.value = true;
        return;
      }
      quantityGado.value += 20;
    };

    const minusGado = () => {
      if (gadoMax.value) {
        gadoMax.value = false;
        return;
      }
      if (quantityGado.value > 0) quantityGado.value -= 20;
    };

    const plusPo = () => {
      if (quantityPo.value >= 70) {
        poMax.value = true;
        return;
      }
      quantityPo.value += 10;
    };

    const minusPo = () => {
      if (poMax.value) {
        poMax.value = false;
        return;
      }
      if (quantityPo.value > 0) quantityPo.value -= 10;
    };

    return {
      adressInput,
      adressInputRef,
      choosedOpen,
      gadoOrPo,
      quantityGado,
      gadoMax,
      plusGado,
      minusGado,
      quantityPo,
      poMax,
      plusPo,
      minusPo,
      doAuction,
      name,
      email,
      phone,
      emailInput,
      emailInputError,
      submitConversion,
      isDisabled,
      chooseOptions,
    };
  },
};
</script>

<style></style>
