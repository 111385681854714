<template>
  <section class="container">
    <section class="profile-header has-text-centered py-4 mb-4">
      <h1>Escolha suas preferências de compra</h1>
      <h3>Selecione abaixo quantas opções quiser</h3>
    </section>

    <div
      class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
    >
      <div
        v-if="currentSex == 'males'"
        class="is-flex is-align-items-center sex-container male"
      >
        <p class="mr-1 has-text-weight-semibold">Touros</p>
        <MaleIcon />
      </div>
      <div v-else class="is-flex sex-container female">
        <p class="mr-1 has-text-weight-semibold">Matrizes melhoradoras</p>
        <FemaleIcon />
      </div>

      <div
        v-if="preferenceTypes.length == 2"
        class="is-flex is-align-items-center mt-2 steps-container"
      >
        <div class="step mr-1 active" />
        <div
          class="step"
          :class="{ active: currentSex == preferenceTypes[1] }"
        />
      </div>
    </div>

    <div class="py-2 mb-6">
      <section v-for="section in SECTIONS" class="mb-5">
        <h2 class="mb-3 has-text-weight-semibold">{{ section.label }}</h2>
        <div class="tags pb-4">
          <ChoiceTag
            v-for="option in OPTIONS[section.value]"
            :key="option"
            :text="option"
            :selected="
              selectedOptions[currentSex][section.value]?.includes(option)
            "
            :unique="UNIQUE_OPTIONS.includes(section.value)"
            @click="toggleOption(section.value, option)"
          />
        </div>
      </section>
    </div>
  </section>

  <NavigationFooter
    :disable-continue="disableContinue"
    :on-confirm="onConfirm"
    :on-go-back="onGoBack"
    :confirm-text="currentSex == preferenceTypes.at(-1) ? 'Concluir' : 'Seguir'"
  />
</template>

<script>
import {
  OPTIONS,
  UNIQUE_OPTIONS,
  selectedOptions,
  setSelectedOptions,
} from "./purchasePreferencesContext";

import ChoiceTag from "./ChoiceTag.vue";
import NavigationFooter from "./NavigationFooter.vue";

import MaleIcon from "icons/cached/male.svg";
import FemaleIcon from "icons/cached/female.svg";

import { computed, onMounted, ref } from "vue";
import api from "services/api";
import {
  purchase_preferences_index_url,
  purchase_preferences_save_purchase_preferences_url,
} from "utils/routes";

export default {
  components: {
    ChoiceTag,
    NavigationFooter,
    MaleIcon,
    FemaleIcon,
  },
  props: {
    purchasePreferences: Object,
  },
  setup(props) {
    const preferenceTypes = ref(Object.keys(props.purchasePreferences));
    const currentSex = ref(preferenceTypes.value[0]);
    onMounted(() => {
      setSelectedOptions(props.purchasePreferences);
    });

    const SECTIONS = computed(() => [
      { label: "Objetivos", value: "objectives" },
      { label: "Raça", value: "breeds" },
      { label: "Tipos genéticos", value: "genetic_types" },
      { label: "Preço", value: "prices" },
      { label: "Qual avaliação (iABCZ) você procura?", value: "i_abcz" },
      { label: "Idade", value: "ages" },
    ]);

    const toggleOption = (section, option) => {
      const selected = selectedOptions.value[currentSex.value][section];

      if (UNIQUE_OPTIONS.includes(section)) {
        selectedOptions.value[currentSex.value][section] =
          selected === option ? "" : option;
        return;
      }

      if (selected?.includes(option)) {
        selectedOptions.value[currentSex.value][section] = selected.filter(
          (selected) => selected !== option,
        );
      } else {
        selectedOptions.value[currentSex.value][section].push(option);
      }
    };

    const disableContinue = computed(
      () =>
        Object.values(selectedOptions.value[currentSex.value])
          .flat()
          .filter(Boolean).length === 0,
    );

    const onConfirm = () => {
      api
        .post(purchase_preferences_save_purchase_preferences_url(), {
          purchase_preference: selectedOptions.value[currentSex.value],
          preference_type: currentSex.value,
        })
        .then(() => {
          buildMixpanelTracks(() => {
            if (currentSex.value == preferenceTypes.value.at(-1)) {
              window.location.href = purchase_preferences_index_url();
              return;
            }
            currentSex.value = preferenceTypes.value.at(-1);
            window.scrollTo(0, 0);
          });
        });
    };

    const onGoBack = () => {
      const currentIndex = preferenceTypes.value.indexOf(currentSex.value);
      if (currentIndex === 0) {
        window.history.back();
        return;
      }
      currentSex.value = preferenceTypes.value[currentIndex - 1];
    };

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const buildMixpanelTracks = (callback) => {
      const tracks = Object.keys(
        selectedOptions.value[currentSex.value],
      ).reduce((hash, key) => {
        hash[capitalizeFirstLetter(key)] = JSON.parse(
          JSON.stringify(selectedOptions.value[currentSex.value][key]),
        );
        return hash;
      }, {});

      mixpanel.track("Buy Preferences", tracks, callback);
    };

    return {
      OPTIONS,
      SECTIONS,
      toggleOption,
      selectedOptions,
      disableContinue,
      UNIQUE_OPTIONS,
      currentSex,
      preferenceTypes,
      onConfirm,
      onGoBack,
    };
  },
};
</script>

<style></style>
