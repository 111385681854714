import { reactive, ref } from "vue";
const openPasswordModal = ref(false);
const openAddressModal = ref(false);
const openDocumentModal = ref(false);
const openCreateDocumentModal = ref(false);
const hasAddress = ref(false);

const userState = reactive({
  email: "",
  avatar_url: "",
  name: "",
  rg: "",
  cpf: "",
  phone_ddi: "55",
  phone_area: "",
  phone: "",
  occupation: ""
});

const fillState = (user) => {
  for (let [key, value] of Object.entries(user)) {
    userState[key] = user[key];

    if (key == 'phone') {
      userState[key] = (user['phone_area'] || "") + (user['phone'] || "");
    }
  }
};

const userAddress = reactive({
  id: "",
  cep: "",
  city: "",
  street: "",
  state: "",
  number: "",
  district: "",
  complement: ""
});

const fillAddress = (address) => {
  let attributeCount = 0;
  for (let [key, value] of Object.entries(address)) {
    userAddress[key] = address[key];

    if (value) {
      attributeCount++;
    }
  }

  if (attributeCount > 2) {
    hasAddress.value = true;
  }
}

const userDocuments = reactive({
  id: 0,
  user_id: 0,
  document_type: "",
  document_file_name: "",
  document_url: ""
});

const fillDocuments = (document) => {
  for (let [key, value] of Object.entries(document)) {
    userDocuments[key] = document[key];
  }
}

export {
  userState, fillState, userAddress, fillAddress, openPasswordModal,
  openAddressModal, hasAddress, openDocumentModal, userDocuments,
  openCreateDocumentModal, fillDocuments
};
