<template>
  <div class="column is-7-desktop is-12-mobile auth-container is-relative">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('policies')"
      />
      <span class="title is-size-7 mb-5">Leia nossos Termos e Políticas</span>
      <div class="select-button is-flex mb-5">
        <div class="left" @click="updateActiveAuthStep('term')">
          Termos de uso
        </div>
        <div class="right is-active">pol. de privacidade</div>
      </div>
      <p class="description">Política de Privacidade</p>
      <div class="content terms">
        <h6>
          Leia com atenção estas Políticas de privacidade antes de cadastrar-se
          ou mesmo navegar no site do erural.
        </h6>

        <p>
          Nós, do erural, estamos comprometidos em resguardar sua privacidade. O
          intuito deste documento é esclarecer quais informações são coletadas
          dos usuários de nosso site
          <a href="https://www.erural.net">www.erural.net</a> - e respectivos
          serviços – e de que forma esses dados são manipulados e utilizados.
          Alertamos que se você não concorda com o conteúdo desta política, não
          é recomendável utilizar quaisquer de nossos serviços.
        </p>

        <h6>Sobre a coleta e armazenamento de dados</h6>

        <p>
          Para utilizar os serviços do erural é necessário realizar um cadastro
          e informar os dados pessoais completos e exatos.
        </p>

        <p>
          Em nossos sites, as informações são coletadas das seguintes formas:
          Informações fornecidas por você – Coletamos informações de
          identificação pessoal como: nome, telefone, email, endereço e CPF –
          via preenchimento dos formulários para criação da conta em nosso
          portal. Todas as contas são associadas a uma senha que é definida pelo
          próprio usuário no momento de criação da conta. Informações de
          navegação no site – Quando você visita nosso site, é inserido um
          ‘cookie’ no seu navegador por meio do software Google Analytics, para
          identificar quantas vezes você retorna ao nosso endereço. São
          coletadas, anonimamente, informações, como endereço IP, localização
          geográfica, fonte de referência, tipo de navegador, duração da visita
          e páginas visitadas. Além disso, fazemos o mapeamento de suas
          atividades no site, com o intuito de otimizar conteúdo que mais te
          agrada. Além disso, todas as negociações que são feitas através da
          plataforma do erural estão sujeitas à aprovação por parte da empresa.
          Todas as negociações são monitoradas pela equipe a fim de garantir a
          segurança e integridade das negociações.
        </p>

        <p class="has-text-weight-bold">
          O usuário, ao cadastrar-se no erural, está ciente e concorda
          expressamente que o erural poderá intermediar e armazenar as trocas de
          mensagens eletrônicas entre os usuários dentro da comunidade com o
          objetivo principal de proporcionar a segurança nas relações e
          comunicações da comunidade.
        </p>

        <p>
          Todos os dados pessoais dos usuários serão armazenados em um arquivo
          automatizado de dados pessoais, localizado nos EUA. O Usuário, ao
          cadastrar-se no erural, confirma que está informado da localização
          deste arquivo e autoriza esta transferência internacional de seus
          dados.
        </p>

        <p>
          A qualquer momento o Usuário cadastrado no erural poderá solicitar a
          exclusão definitiva de seus dados ou o cancelamento de seu cadastro,
          ressalvadas as hipótese de manutenção previstas em lei.
        </p>

        <h6>Sobre o uso de suas Informações Pessoais</h6>

        <p>
          O presente termo permite que o erural use suas informações pessoais
          para diferentes finalidades. O email é utilizado para a operação de
          envio do material ou informação por você requisitada no preenchimento
          do formulário de cadastro. Também pode ser usado para envio de
          Newsletters, sempre relacionadas as ofertas disponibilizadas no site
          do erural. Por fim, o email será utilizado ainda para comunicar o
          lançamento de novos materiais gratuitos ou de novos produtos do erural
          e parceiros. No entanto, o usuário pode cancelar a assinatura a
          qualquer momento. Colaboradores do erural poderão eventualmente entrar
          em contato via email ou telefone para fazer pesquisas ou apresentar
          produtos e serviços.
        </p>

        <p class="has-text-weight-bold">
          O erural, pela natureza dos serviços prestados, poderá acessar,
          coletar, armazenar e, em alguns casos, revelar informações de seus
          usuários e visitantes relativas aos dados cadastrais e registros de
          acesso aos seus Sites, para terceiros, tais como, não se limitando a,
          empresas integrantes de seu grupo econômico, fornecedores, parceiros
          comerciais, membros do Programa de Proteção à Propriedade Intelectual,
          autoridades e pessoas físicas ou jurídicas que aleguem ter sido
          lesadas por usuários cadastrados.
        </p>

        <h6>Sobre o acesso às suas informações pessoais</h6>

        <p>
          Poderão ver suas informações pessoais apenas funcionários do erural e
          de empresas do mesmo grupo econômico. Eventualmente, caso a inserção
          de suas informações se dê em ações criadas em parcerias, os parceiros
          explicitamente identificados também terão acesso à informação. Nenhuma
          informação pessoal poderá ser divulgada publicamente. O erural tem o
          direito de vender, alugar ou repassar algumas de suas informações,
          como nome, e-mail e telefone, para terceiros quando for de interesse
          ou em casos de exigência judicial. Além disso, embora trabalhemos com
          boas práticas de proteção e segurança utilizando criptografia SSL na
          transmissão das informações geradas na utilização do site, nenhum
          serviço web possui 100% de garantia contra invasões e não podemos nos
          responsabilizar caso isso ocorra.
        </p>

        <p>
          O erural não será responsável por interceptações ilegais ou violação
          de seus sistemas ou bases de dados por pessoas não autorizadas,
          tampouco se responsabilizará pela indevida utilização da informação
          obtida por esses meios.
        </p>

        <h6>
          Sobre o cancelamento da assinatura e alteração/exclusão de informações
          pessoais
        </h6>

        <p>
          Você pode optar por não receber mais qualquer tipo de email do erural.
          É importante mencionar que ao preencher qualquer formulário novamente
          ficará caracterizada a reinserção do seu email à lista. Portanto, a
          requisição de cancelamento deve ser feita novamente caso seja de seu
          interesse. Para alterar suas informações pessoais ou mesmo excluí-las
          do nosso banco de dados, basta enviar um email para
          contato@erural.com.br.
        </p>

        <h6>Mudanças na Política de Privacidade</h6>

        <p>
          Essa Política de Privacidade pode passar por atualizações. Desta
          forma, recomendamos visitar periodicamente esta página para que você
          tenha conhecimento sobre as modificações.
        </p>
      </div>
      <div class="columns is-hidden-mobile">
        <div class="column">
          <button class="button is-primary is-fullwidth" @click="acceptTerm">
            Li tudo e concordo
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="accept-bt-container is-hidden-desktop">
    <button class="button is-primary is-fullwidth" @click="acceptTerm">
      Li tudo e concordo
    </button>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import { useAuthInfos } from "components/user/useAuthInfos.js";

export default {
  components: {
    ArrowBackIcon,
  },
  setup() {
    const { updateActiveAuthStep, updateAuthUser } = useAuthInfos();

    const acceptTerm = () => {
      updateAuthUser({
        terms: true,
      });

      updateActiveAuthStep("policies");
    };

    return {
      updateActiveAuthStep,
      acceptTerm,
    };
  },
};
</script>
