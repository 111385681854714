<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('new_password')"
      />
      <span class="title">6/6</span>
      <p class="description">Só falta isso:</p>
      <div class="columns">
        <div class="column">
          <div class="block">
            <div class="checkbox">
              <input
                id="termsCheckbox"
                v-model="termsCheckbox"
                type="checkbox"
              />
              <label for="termsCheckbox">
                * Li e aceito os
                <a @click="updateActiveAuthStep('term')">Termos e Condições</a>
                e
                <a @click="updateActiveAuthStep('privacy')">
                  Políticas de Privacidade
                </a>
                da erural
              </label>
            </div>
          </div>
          <div class="block is-hidden">
            <div class="checkbox">
              <input
                id="cookiesCheckbox"
                v-model="cookiesCheckbox"
                type="checkbox"
              />
              <label for="cookiesCheckbox">
                Concordo com a utilização de cookies para promover marketing
                personalizado e melhorar minha experiência na plataforma.
              </label>
            </div>
          </div>
          <div class="block is-hidden">
            <div class="checkbox">
              <input
                id="newsletterCheckbox"
                v-model="newsletterCheckbox"
                type="checkbox"
              />
              <label for="newsletterCheckbox">
                Aceito receber e-mails e mensagens WhatsApp com promoções e
                novidades da erural.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="error" class="button-container">
        <span class="is-size-12 mb-3 is-block">
          Não foi possível criar a sua conta. <br />
          Verifique suas informações e tente novamente.
        </span>
        <button
          class="button is-warning is-outlined"
          @click="updateActiveAuthStep(firstError)"
        >
          Verificar informações
        </button>
      </div>

      <div v-else class="button-container">
        <span class="is-size-12 mb-3 is-block">
          Itens com * são obrigatórios.
        </span>
        <button
          class="button is-warning create-account-bt"
          :disabled="isDisabled"
          :class="{ 'is-loading': submitting }"
          @click="createAccount"
        >
          Criar conta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import api from "services/api";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { computed, ref, watch } from "vue";
import { users_path } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
  },
  setup() {
    const {
      updateActiveAuthStep,
      updateAuthUser,
      email,
      cpf,
      name,
      phone,
      phone_ddi,
      terms,
      cookies,
      newsletter,
      password,
    } = useAuthInfos();

    const termsCheckbox = ref(terms.value);
    const cookiesCheckbox = ref(cookies.value);
    const newsletterCheckbox = ref(newsletter.value);

    const isDisabled = computed(() => {
      return !termsCheckbox.value || submitting.value;
    });

    const updatePolicies = () => {
      updateAuthUser({
        terms: termsCheckbox.value,
        cookies: cookiesCheckbox.value,
        newsletter: newsletterCheckbox.value,
      });
    };

    watch([termsCheckbox, cookiesCheckbox, newsletterCheckbox], updatePolicies);

    const error = ref(false);
    const firstError = ref("name");

    const submitting = ref(false);
    const createAccount = () => {
      if (!termsCheckbox.value) return;

      submitting.value = true;

      let url = new URL(window.location.href);
      let redirect_path = url.searchParams.get("redirect_path");

      api
        .post(users_path(), {
          user: {
            name: name.value,
            email: email.value,
            cpf: cpf.value,
            phone: phone.value,
            phone_ddi: phone_ddi.value,
            password: password.value,
            allows_cookies: cookies.value,
            allows_newsletter: newsletter.value,
          },
          redirect_path: redirect_path,
        })
        .then((response) => {
          window.location = response.data.redirect_url;
        })
        .catch((err) => {
          error.value = true;
          firstError.value = findFirstError(err.response?.data?.errors);
        })
        .finally(() => {
          submitting.value = false;
        });
    };

    const findFirstError = (errors) => {
      if (!errors) return "name";

      if ("name" in errors) return "name";
      if ("email" in errors) return "email";
      if ("cpf" in errors) return "cpf";
      if ("phone_ddi" in errors || "phone_area" in errors || "phone" in errors)
        return "phone";
      if ("password" in errors) return "new_password";

      return "name";
    };

    return {
      updateActiveAuthStep,
      termsCheckbox,
      cookiesCheckbox,
      newsletterCheckbox,
      isDisabled,
      updatePolicies,
      createAccount,
      submitting,
      error,
      firstError,
    };
  },
};
</script>
