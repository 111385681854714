<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div v-if="userLoginReviewStep" class="box auth-box email-review">
      <ArrowBackIcon class="arrow-back" @click="handleLoginReviewStep" />
      <span class="title">Já tenho conta</span>

      <p class="description">Revise seu CPF / e-mail</p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            required
            type="text"
            v-mask="loginMask"
            :value="login"
            placeholder="Digite seu CPF ou e-mail"
            :error="loginInputError"
            :show-message="loginInputError"
            message="CPF / e-mail inválido! Tente novamente"
            @input="(v) => (login = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-success"
          :disabled="isDisabled"
          :class="{ 'is-loading': submitting }"
          @click="findUser"
        >
          Está correto
        </button>
      </div>
    </div>

    <div v-else class="box auth-box email-review">
      <ArrowBackIcon class="arrow-back" @click="handleLoginReviewStep" />
      <span class="title">Já tenho conta</span>

      <p class="has-text-centered mb-4">
        Não achamos nenhuma conta com esse CPF / e-mail na plataforma...
      </p>
      <p class="has-text-centered mb-6">
        Você pode voltar e revisar o seu CPF / e-mail ou criar uma conta nova.
      </p>

      <div class="button-container">
        <button
          class="button is-success is-outlined mr-2"
          @click="handleLoginReviewStep"
        >
          Revisar
        </button>

        <button class="button is-success" @click="updateActiveAuthStep('name')">
          Criar uma conta nova
        </button>
      </div>
    </div>

    <div class="columns is-centered">
      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import api from "services/api";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { ref, computed } from "vue";
import { users_name_by_login_path } from "utils/routes";
import { mask } from "vue-the-mask";

export default {
  components: {
    AppInput,
    ArrowBackIcon,
    FacebookAuthButton,
  },
  directives: {
    mask,
  },
  setup() {
    const {
      updateAuthUser,
      updateActiveAuthStep,
      login,
      validateLogin,
      loginMask,
    } = useAuthInfos();

    const submitting = ref(false);
    const isDisabled = computed(
      () => !validateLogin(login.value) || submitting.value,
    );
    const loginInputError = computed(
      () => !!login.value && !validateLogin(login.value),
    );

    const userLoginReviewStep = ref(true);
    const findUser = () => {
      submitting.value = true;

      api
        .post(users_name_by_login_path(), { login: login.value })
        .then((response) => {
          if (login.value.includes("@")) {
            updateAuthUser({ email: login.value, name: response.data.name });
          } else {
            updateAuthUser({ cpf: login.value, name: response.data.name });
          }

          updateActiveAuthStep("password");
        })
        .catch((err) => {
          submitting.value = false;
          userLoginReviewStep.value = false;
        });
    };

    const handleLoginReviewStep = () => {
      if (userLoginReviewStep.value) {
        updateActiveAuthStep("name");
      } else {
        userLoginReviewStep.value = true;
      }
    };

    return {
      isDisabled,
      findUser,
      submitting,
      loginMask,
      login,
      userLoginReviewStep,
      updateActiveAuthStep,
      handleLoginReviewStep,
      loginInputError,
    };
  },
};
</script>
