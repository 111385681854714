<template>
  <div
    class="modal registration-modal"
    :class="{ 'is-active': openRegistrationModal }"
  >
    <div class="modal-background" @click="openRegistrationModal = false" />
    <div class="modal-content">
      <div class="modal-header">
        <a
          class="delete registration-exit"
          @click="openRegistrationModal = false"
        />
      </div>
      <ShoppingRegistration />
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { preRegistrationState } from "./preRegistrationContext";
import ShoppingRegistration from "./ShoppingRegistration.vue";

export default {
  components: {
    ShoppingRegistration,
  },
  setup() {
    const { conversion, openRegistrationModal } = toRefs(preRegistrationState);

    return {
      conversion,
      openRegistrationModal,
      preRegistrationState,
    };
  },
};
</script>
