<template>
  <div class="modal pre-registration-modal" :class="{ 'is-active': open }">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content" :class="{ 'big-width': bannerBtn }">
      <div class="modal-header">
        <button class="delete" @click="$emit('close')" />
      </div>
      <div class="modal-body">
        <div class="columns is-centered">
          <div class="column">
            <ActiveAuctionPreRegistrationForm
              :name="name"
              :email="email"
              :phone="phone"
              :lpConversion="lpConversion"
              :trackData="trackData"
              :formDisplay="formDisplay"
              :page="page"
              :section="section"
              :mixpanelData="mixpanelData"
              :lpRedirectLink="redirectLink"
              :cta="cta"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActiveAuctionPreRegistrationForm from "./ActiveAuctionPreRegistrationForm.vue";

export default {
  components: {
    ActiveAuctionPreRegistrationForm,
  },
  props: {
    open: Boolean,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    page: String,
    formDisplay: String,
    section: String,
    mixpanelData: Object,
    bannerBtn: Boolean,
    redirectLink: String,
    cta: String,
  },
  emits: ["close"],
  setup() {
    return {};
  },
};
</script>
