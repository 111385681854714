<template>
  <div v-if="showGenealogy" class="card-genealogy">
    <div class="card-top">
      <p class="card-title">Avós</p>
      <p class="card-title">Pais</p>
    </div>

    <div class="kinship mb-2">
      <div class="grandparents">
        <div class="grandparents-names">
          <div class="grandfathers-name">
            <MaleIcon class="male" />
            <p>
              {{ animal.fat_grandfather }}
            </p>
            <div class="trace-horizontal right" />
          </div>
          <div class="grandfathers-name">
            <FemaleIcon class="female" />
            <p>
              {{ animal.fat_grandmother }}
            </p>
            <div class="trace-horizontal right" />
          </div>
          <div class="trace-vertical" />
        </div>
      </div>
      <div class="parents">
        <div class="parent-name">
          <MaleIcon class="male" />
          <p>
            {{ animal.father }}
          </p>
          <div class="trace-horizontal left" />
        </div>
      </div>
    </div>

    <div class="kinship">
      <div class="grandparents">
        <div class="grandparents-names">
          <div class="grandfathers-name">
            <MaleIcon class="male" />
            <p>
              {{ animal.mat_grandfather }}
            </p>
            <div class="trace-horizontal right" />
          </div>
          <div class="grandfathers-name">
            <FemaleIcon class="female" />
            <p>
              {{ animal.mat_grandmother }}
            </p>
            <div class="trace-horizontal right" />
          </div>
          <div class="trace-vertical" />
        </div>
      </div>
      <div class="parents">
        <div class="parent-name">
          <MaleIcon class="male" />
          <p>
            {{ animal.mother }}
          </p>
          <div class="trace-horizontal left" />
        </div>
      </div>
    </div>
  </div>
  <h1 v-else class="has-text-weight-semibold has-text-centered has-text-dark">
    Genealogia indisponível <br />
    para este lote
  </h1>
</template>

<script>
import FemaleIcon from "icons/cached/female.svg";
import MaleIcon from "icons/cached/male.svg";
import { computed } from "vue";

export default {
  components: { FemaleIcon, MaleIcon },
  props: { animal: Object },
  setup(props) {
    const showGenealogy = computed(() =>
      Object.values(props.animal).some((v) => v),
    );

    return { showGenealogy };
  },
};
</script>
