<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon class="arrow-back" @click="updateActiveAuthStep('name')" />
      <span class="title">2/6</span>

      <p class="description">{{ title }}</p>

      <div class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            ref="emailInput"
            type="email"
            placeholder="Digite o seu e-mail"
            required
            :value="email"
            @input="(v) => (email = v)"
            @enterPressed="$refs.confirmBt.click()"
            :error="emailError"
            :show-message="emailError"
            message="Já existe uma conta com este e-mail na erural."
          />
        </div>
      </div>

      <div class="button-container">
        <button
          v-if="emailError"
          class="button is-outlined"
          @click="recoverPassword"
        >
          Esqueci minha senha
        </button>

        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          @click="nextStep"
        >
          Próximo
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('login_review')"
        >
          Já tenho uma conta
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { onMounted, ref, computed, watch } from "vue";
import api from "services/api";
import { users_name_by_login_path } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
    AppInput,
    FacebookAuthButton,
  },
  setup() {
    const { email, updateAuthUser, updateActiveAuthStep } =
      useAuthInfos();

    const emailInput = ref(null);

    const isDisabled = computed(
      () => !email.value || !emailInput.value?.inputEl.checkValidity(),
    );

    const title = ref("");
    onMounted(
      () =>
        (title.value = email.value
          ? "Confirme o seu e-mail"
          : "Qual o seu e-mail?"),
    );

    const emailError = ref(false);
    watch(email, () => (emailError.value = false));

    const submitting = ref(false);
    const nextStep = () => {
      submitting.value = true;

      api
        .post(users_name_by_login_path(), { login: email.value })
        .then(() => (emailError.value = true))
        .catch(() => updateActiveAuthStep("cpf"))
        .finally(() => (submitting.value = false));
    };

    const recoverPassword = () => {
      updateAuthUser({ login: email.value });
      updateActiveAuthStep("password_recovery");
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      emailInput,
      email,
      title,
      emailError,
      submitting,
      nextStep,
      recoverPassword,
    };
  },
};
</script>
