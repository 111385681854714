<template>
  <div class="selection-page">
    <div class="selection-top">
      <div class="selection-identifier">
        <VerifiedIcon />
        <span>Seleções erural</span>
      </div>
      <h1 class="selection-title">{{ cardInfo.current_selection.title }}</h1>
      <p class="selection-subtitle">
        {{ cardInfo.current_selection.subtitle }}
      </p>

      <div
        class="columns is-flex is-justify-content-space-between is-align-items-center px-2"
      >
        <div class="options-button" @click="showOptions = true">
          <span>Veja outras seleções</span>
          <ExpandMoreIcon />
        </div>
        <AlertButton />
      </div>

      <div class="modal options-modal" :class="{ 'is-active': showOptions }">
        <div class="modal-background" @click="modalHandler(false)" />
        <div class="modal-content">
          <b class="option-title">Escolha uma seleção:</b>
          <a
            v-for="(selection, i) in cardInfo.all_selections"
            :key="i"
            :href="selections_show_url(selection.link)"
            class="option-name"
          >
            <span v-if="selection.link != cardInfo.current_selection.link">
              {{ selection.title }}
            </span>
            <div v-else class="current-option">
              <span>
                {{ cardInfo.current_selection.title }}
              </span>
              <VerifiedIcon />
            </div>
          </a>
        </div>
      </div>

      <div v-if="showOptions" class="close-button" @click="modalHandler(false)">
        <CloseIcon />
      </div>
    </div>

    <div v-if="cardInfo.active_batches.length == 0" class="container">
      <AlertPreForm :hasResults="false" />
    </div>

    <div v-if="cardInfo.active_batches.length > 0" class="cards-container">
      <SelectionCard
        v-for="(batch, i) in cardInfo.active_batches"
        :key="i"
        :batch="batch"
        page="Selections Page"
        section="Selections"
        :has-automatic-alert="hasAutomaticAlert"
      />
    </div>

    <div
      v-if="
        cardInfo.active_batches.length > 0 && cardInfo.sold_batches.length > 0
      "
      class="container"
    >
      <AlertPreForm :hasResults="true" />
    </div>

    <div v-if="cardInfo.sold_batches.length > 0">
      <h2 class="sold-cards-title">
        Explore lotes que já estiveram disponíveis
      </h2>

      <div class="cards-container sold-cards">
        <SelectionCard
          v-for="(batch, i) in cardInfo.sold_batches"
          :key="i"
          :batch="batch"
          page="Selections Page - Sold out"
          section="Selections"
          :has-automatic-alert="hasAutomaticAlert"
        />
      </div>

      <div class="is-flex is-justify-content-center mb-4">
        <a
          class="button is-primary is-outlined see-more-bt"
          target="_blank"
          :href="search_ad_url()"
        >
          Ver mais lotes
        </a>
      </div>
    </div>

    <AlertBottomSheet v-if="!showOptions && state.isBottomSheetOpen" />
  </div>
</template>

<script>
import SelectionCard from "./SelectionCard.vue";
import AlertButton from "components/alert_subscription/AlertButton.vue";
import AlertBottomSheet from "components/alert_subscription/AlertBottomSheet.vue";
import AlertPreForm from "components/alert_subscription/AlertPreForm.vue";
import { state } from "../alert_subscription/alertState";

import VerifiedIcon from "icons/cached/verified.svg";
import ExpandMoreIcon from "icons/cached/expand_more.svg";
import CloseIcon from "icons/cached/close.svg";
import ChicoSadImg from "images/chico/chico_sad_face.png";

import { selections_show_url, search_ad_url } from "utils/routes";
import { ref, onMounted } from "vue";

export default {
  components: {
    SelectionCard,
    VerifiedIcon,
    ExpandMoreIcon,
    CloseIcon,
    AlertButton,
    AlertPreForm,
    AlertBottomSheet,
  },
  props: {
    cardInfo: Object,
    conversionData: Object,
    hasAutomaticAlert: Boolean,
  },
  setup(props) {
    const showOptions = ref(false);

    const modalHandler = (openModal) => {
      if (openModal) {
        showOptions.value = true;
        document.querySelector("html").classList.add("is-clipped");
      } else {
        showOptions.value = false;
        document.querySelector("html").classList.remove("is-clipped");
      }
    };

    if (props.cardInfo.active_batches.length > 0) {
      setTimeout(() => {
        if (!state.isBottomSheetOpen && !state.registered) {
          state.isBottomSheetOpen = true;
          state.activeTab = "phone";
          state.origin = "Alert Timer";
          mixpanel.track("Radar - Pop Up Viewed", {
            Origin: state.origin,
          });
        }
      }, 30000);
    }

    onMounted(() => {
      const { name, email, phone } = props.conversionData.lp_conversion;

      state.userName = name;
      state.userEmail = email;
      state.userPhone = phone;
      state.conversionData = props.conversionData;

      if (document.referrer) {
        const url = new URL(document.referrer);
        const alertParam = url.searchParams.get("suggest_alert");

        if (alertParam) {
          state.isBottomSheetOpen = true;
          state.activeTab = "phone";
        }
      }
    });

    return {
      showOptions,
      ChicoSadImg,
      modalHandler,
      state,
      selections_show_url,
      search_ad_url,
    };
  },
};
</script>
