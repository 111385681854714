import { ref } from "vue";

const OPTIONS = {
  objectives: [
    "Crescimento (ganho de peso)",
    "Habilidade materna",
    "Reprodução",
    "Carcaça",
    "Morfologia",
  ],
  breeds: [
    "Nelore",
    "Nelore Pintado",
    "Nelore Mocho",
    "Sindi",
    "Senepol",
    "Tabapuã",
    "Girolando",
    "Gir",
    "Guzerá",
    "Brahman",
    "Brangus",
    "Angus",
  ],
  genetic_types: [
    "P.O. (Puro de Origem)",
    "P.A. (Puro por Avaliação)",
    "P.C. (Puro por Cruzamento)",
  ],
  i_abcz: [
    "A partir de 9",
    "A partir de 12",
    "A partir de 15",
    "A partir de 20",
    "A partir de 25",
  ],
  prices: [
    "R$6.000 a R$9.000",
    "R$9.000 a R$12.000",
    "R$12.000 a R$15.000",
    "R$15.000 a R$18.000",
    "R$18.000 a R$21.000",
    "R$21.000 a R$24.000",
    "Acima de R$24.000",
  ],
  ages: [
    "Até 18 meses",
    "Entre 18 e 24 meses",
    "Entre 24 e 36 meses",
    "A partir de 36 meses",
  ],
};

const UNIQUE_OPTIONS = ["i_abcz"];

const selectedTypes = ref([]);
const selectedOptions = ref({
  males: {
    objectives: [],
    breeds: [],
    genetic_types: [],
    i_abcz: null,
    prices: [],
    ages: [],
  },
  females: {
    objectives: [],
    breeds: [],
    genetic_types: [],
    i_abcz: null,
    prices: [],
    ages: [],
  },
});

const setSelectedOptions = (data) => {
  if (data.males) {
    Object.keys(data.males).forEach((key) => {
      selectedOptions.value.males[key] = data.males[key] || [];
    });
  }

  if (data.females) {
    Object.keys(data.females).forEach((key) => {
      selectedOptions.value.females[key] = data.females[key] || [];
    });
  }
};

export {
  selectedTypes,
  selectedOptions,
  setSelectedOptions,
  OPTIONS,
  UNIQUE_OPTIONS,
};
