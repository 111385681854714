<template>
  <div class="modal auction-conditions-modal" :class="{ 'is-active': open }">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content">
      <div class="modal-header">
        <button class="delete" @click="$emit('close')" />
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="columns">
            <div class="column">
              <div class="has-text-centered">
                <LocalShippingIcon />
              </div>
              <span class="title">{{ shipping }} </span>
              <p class="description pre-wrap">
                {{ shippingExplanation }}
              </p>
              <div class="has-text-centered">
                <button class="button is-success" @click="$emit('close')">
                  Entendi!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalShippingIcon from "icons/cached/local_shipping.svg";

export default {
  components: {
    LocalShippingIcon,
  },
  props: {
    open: Boolean,
    shipping: String,
    shippingExplanation: String,
  },
  emits: ["close"],
};
</script>
