<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <span class="title">Entre ou cadastre-se</span>
      <p class="description">Digite seu CPF ou e-mail</p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            type="text"
            v-mask="loginMask"
            :value="login"
            placeholder="Digite seu CPF ou e-mail"
            required
            :error="loginInputError"
            :show-message="loginInputError"
            message="CPF / email inválido. Tente novamente."
            @input="(v) => (login = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          :class="{ 'is-loading': submitting }"
          @click="findUser"
        >
          Próximo
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('password_recovery')"
        >
          Esqueci minha senha
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import { ref, computed } from "vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import api from "services/api";
import { users_name_by_login_path } from "utils/routes";
import { mask } from "vue-the-mask";

export default {
  components: {
    AppInput,
    FacebookAuthButton,
  },
  directives: {
    mask,
  },
  setup() {
    const {
      updateAuthUser,
      updateActiveAuthStep,
      login,
      validateLogin,
      loginMask,
    } = useAuthInfos();

    const submitting = ref(false);
    const isDisabled = computed(
      () => !validateLogin(login.value) || submitting.value,
    );
    const loginInputError = computed(
      () => !!login.value && !validateLogin(login.value),
    );

    const findUser = () => {
      submitting.value = true;

      api
        .post(users_name_by_login_path(), { login: login.value })
        .then((response) => {
          updateAuthUser({ name: response.data.name });

          updateActiveAuthStep("password");
        })
        .catch(() => {
          if (login.value.includes("@")) {
            updateAuthUser({ email: login.value });
          } else {
            updateAuthUser({ cpf: login.value });
          }

          updateActiveAuthStep("name");
        });
    };

    return {
      isDisabled,
      findUser,
      submitting,
      loginMask,
      login,
      updateActiveAuthStep,
      loginInputError,
    };
  },
};
</script>
