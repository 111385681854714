<template>
  <button
    class="button is-fullwidth fb-button is-relative"
    @click="openFacebookPopup"
  >
    <span class="circle"><FacebookIcon /></span>
    Entre com o Facebook
  </button>
</template>

<script type="text/javascript">
import { omniauth_facebook_url } from "utils/routes";
import FacebookIcon from "icons/cached/facebook.svg";

export default {
  components: {
    FacebookIcon,
  },
  setup() {
    const openFacebookPopup = () => {
      const width = 400;
      const height = 600;

      const left = screen.width / 2 - width / 2;
      const top = screen.height / 2 - height / 2;

      window.open(
        omniauth_facebook_url(),
        "authPopup",
        `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`,
      );
    };

    return {
      openFacebookPopup,
    };
  },
};
</script>
