<template>
  <div class="contact-container-mobile">
    <div class="contact-mobile-title">
      <div class="title is-size-6 has-text-weight-bold">Fale conosco</div>
      <div class="subtitle is-size-7">
        Escolha abaixo o meio de comunicação<br />
        que preferir
      </div>

      <FlashMessage
        :isOpen="flashOpen"
        :message="hasMessage"
        :type="flashType"
      />
    </div>
    <div class="contact-options mb-5">
      <div
        class="contact-option has-border-green-light"
        @click.prevent="changePage('MessagePage')"
      >
        <div class="icon-option">
          <EmailIcon />
        </div>
        <div class="message-text">Envie uma mensagem</div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5516997420031&text=Oi%2C%20eu%20estava%20na%20p%C3%A1gina%20fale%20conosco%2C%20e%20tenho%20uma%20d%C3%BAvida..."
        class="contact-option has-border-green-light"
      >
        <div class="icon-option">
          <WhatsappIcon />
        </div>
        <div class="message-text">Chame no WhatsApp</div>
      </a>
      <a
        class="contact-option has-border-green-light"
        href="tel:+5516997420031"
      >
        <div class="icon-option">
          <PhoneInTalkIcon />
        </div>
        <div class="message-text">Ligue pra gente</div>
      </a>
      <div
        class="contact-option has-border-green-light"
        @click.prevent="changePage('AddressPage')"
      >
        <div class="icon-option">
          <Apartment />
        </div>
        <div class="message-text">Nossos endereços</div>
      </div>
    </div>
    <div class="contact-infos">
      <div class="contact-info mb-4">
        <span class="is-size-7 has-text-weight-semibold">
          Horário de atendimento:<br />
        </span>
        <span class="is-size-7">
          De segunda a sexta, das 10h às 18h,<br />
          exceto feriados.<br />
        </span>
      </div>
      <div class="contact-info">
        <span class="is-size-7 has-text-weight-semibold">
          Telefone de Contato:<br />
        </span>
        <span class="is-size-7"> (16) 9 9742-0031<br /> </span>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessage from "components/ui/FlashMessage.vue";

import WhatsappIcon from "icons/cached/whatsapp.svg";
import EmailIcon from "icons/cached/email.svg";
import Apartment from "icons/cached/apartment.svg";
import PhoneInTalkIcon from "icons/cached/phone_in_talk.svg";

import { changePage } from "./currentPage";

export default {
  components: {
    WhatsappIcon,
    EmailIcon,
    Apartment,
    PhoneInTalkIcon,
    FlashMessage,
  },

  props: {
    hasMessage: String,
    flashOpen: Boolean,
    flashType: String,
  },

  setup() {
    return {
      changePage,
    };
  },
};
</script>

<style></style>
