<template>
  <div class="youtube-player">
    <img
      v-if="preview"
      v-show="loading"
      :src="
        previewUrl || `https://i.ytimg.com/vi_webp/${videoId}/sddefault.webp`
      "
      :alt="`Preview de vídeo - ${videoId}`"
      :class="customPreviewClasses"
      class="is-overlay"
    />

    <div ref="playerEl" :class="customClasses"></div>

    <div
      v-if="!loading && showUnmuteBar"
      class="unmute-bar is-hidden-desktop"
      :class="{ short: shortUnmute }"
      @click.prevent="unmute"
    >
      <VolumeOffIcon />
      <p>Clique para ativar som</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import YouTubePlayer from "youtube-player";
import VolumeOffIcon from "icons/cached/volume_off.svg";

export default {
  components: {
    VolumeOffIcon,
  },
  props: {
    customPreviewClasses: Array,
    customClasses: Array,
    videoId: String,
    previewUrl: String,
    playerVars: Object,
    width: String,
    height: String,
    autoplay: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const playerEl = ref(null);
    const player = ref(null);
    const loading = ref(true);
    const shortUnmute = ref(false);

    const playerVars = {
      modestbranding: 1,
      playsinline: 1,
      enablejsapi: 1,
      showinfo: 0,
      rel: 0,
      origin: window.location.origin,
      autoplay: props.autoplay ? 1 : 0,
      mute: props.autoplay ? 1 : 0,
      loop: props.loop ? 1 : 0,
      ...props.playerVars,
    };

    onMounted(() => {
      player.value = YouTubePlayer(playerEl.value, {
        videoId: props.videoId,
        playerVars: playerVars,
        width: props.width || "100%",
        height: props.height || "100%",
      });

      player.value.on("ready", () => {
        loading.value = false;
        setTimeout(() => (shortUnmute.value = true), 2000);
      });

      if (props.autoplay) player.value.playVideo();

      if (props.loop) {
        player.value.on("stateChange", (event) => {
          if (event.data == YT.PlayerState.ENDED) {
            event.target.seekTo(0);
          }
        });
      }
    });

    const showUnmuteBar = ref(true);
    const unmute = () => {
      player.value.setVolume(100);
      player.value.unMute();
      showUnmuteBar.value = false;
    };

    const pause = () => {
      player.value.pauseVideo();
    };

    return {
      loading,
      shortUnmute,
      playerEl,
      player,
      showUnmuteBar,
      unmute,
      pause,
    };
  },
};
</script>
