import { reactive, ref } from "vue";
import api from "services/api";

const page = ref(1);
const isLoading = ref(true);
const isLastPage = ref(false);
const modalFilterOpen = ref(false);
const isFiltered = ref(false);
const auctions = ref({});
const featuredVisible = ref(0);
const modalCardIsOpen = ref(false);
const openPreRegModal = ref(false);

var urlPage = "";
var urlFilter = "";

const filter = reactive({
  breed: [],
  category: [],
  state: [],
  date: "",
  shipping: [],
});

export const useCrawledAuctions = () => {
  const getCrawledAuctions = () => {
    if (!isLastPage.value) {
      mountUrl();
      isLoading.value = true;

      api.get(urlFilter).then((response) => {
        if (response.data && Object.keys(response.data).length > 0) {
          for (let [key, value] of Object.entries(response.data)) {
            if (auctions.value[key] == undefined) {
              auctions.value[key] = value;
            } else {
              auctions.value[key] = auctions.value[key].concat(value);
            }
          }
        } else {
          isLastPage.value = true;
        }
        isLoading.value = false;

        sendFilterMixpanelTrack(filter);
      });
    }
  };

  const mountUrl = () => {
    urlPage = window.location.pathname;
    urlFilter = `/agenda-eventos/filtrar?&page=${page.value}`;
    urlPage += "?";

    if (filter.category.length == 4) {
      filter.category = [];
    }

    if (filter.breed.length) {
      filter.breed.forEach((item) => {
        urlFilter += `&breeds[]=${item}`;
      });
      urlPage += `&Raças=${filter.breed.toString()}`;
    }

    if (filter.category.length) {
      let categoryObj = {
        bovine: "Bovinos",
        equidae: "Equídeos",
        ovine: "Ovinos",
        caprine: "Caprinos",
      };

      urlPage += "&Categoria=";
      let categoriesPt = [];

      filter.category.forEach((item) => {
        urlFilter += `&categories[]=${item}`;
        categoriesPt.push(categoryObj[item]);
      });

      urlPage += categoriesPt.toString();
    }

    if (filter.state.length) {
      filter.state.forEach((item) => {
        urlFilter += `&state[]=${item}`;
      });
      urlPage += `&Estado=${filter.state.toString()}`;
    }

    if (filter.date.length) {
      urlFilter += `&date=${filter.date}`;
      urlPage += `&Data=${filter.date.toString()}`;
    }

    if (filter.shipping.length) {
      let shippingObj = {
        no_informations: "Sem_informações",
        free_shipping: "Frete_grátis",
        facilitated_shipping: "Frete_facilitado",
        shipping_by_buyer: "Frete_por_conta_do_comprador",
      };

      urlPage += "&Frete=";
      let shippingPt = [];

      filter.shipping.forEach((item) => {
        urlFilter += `&shipping[]=${item}`;
        shippingPt.push(shippingObj[item]);
      });

      urlPage += shippingPt.toString();
    }

    window.history.pushState("", "", urlPage);
  };

  const cleanFilters = () => {
    filter.date = "";
    filter.state = [];
    filter.category = [];
    filter.breed = [];
    filter.shipping = [];

    applyFilters();
  };

  const applyFilters = () => {
    isLastPage.value = false;
    page.value = 1;

    auctions.value = {};

    getCrawledAuctions();
  };

  const sendEventMixpanelTrack = (event_name, auction, extras = {}) => {
    const categoryObj = {
      bovine: "Bovinos",
      equidae: "Equídeos",
      ovine: "Ovinos",
      caprine: "Caprinos",
    };

    mixpanel.track(event_name, {
      "Event Name": auction.title,
      "Event Date": auction.date,
      "Event Breed": auction.breed,
      "Event Category": categoryObj[auction.category],
      "Event State": auction.state,
      "Event Auctioneer": auction.auctioneer,
      "Event External Link": auction.link,
      "Event Channel": auction.channel,
      "Event Featured": auction.featured || auction.isFeatured,
      "Event Virtual": auction.is_virtual || auction.isvirtual,
      "Event Location": auction.location,
      "Event Time Period": auction.time_period,
      "Event Banner": auction.card_banner || auction.banner,
      "Event Description": auction.description,
      ...extras,
    });
  };

  const sendFilterMixpanelTrack = (filter) => {
    const categoryObj = {
      bovine: "Bovinos",
      equidae: "Equídeos",
      ovine: "Ovinos",
      caprine: "Caprinos",
    };

    let presentFilters = [];
    if (filter.category.length > 0) presentFilters.push("Category");

    if (filter.breed.length > 0) presentFilters.push("Breed");

    if (filter.state.length > 0) presentFilters.push("State");

    if (filter.shipping.length > 0) presentFilters.push("Shipping");

    if (filter.date != "") presentFilters.push("Date");

    mixpanel.track("Scheduler - Crawled Events Filtered", {
      "Filtered Breeds": filter.breed,
      "Filtered Categories": filter.category,
      "Filtered State": filter.state,
      "Filtered Date": filter.date,
      "Filtered Shipping": filter.shipping,
      "Filtered Options": presentFilters,
    });
  };

  return {
    getCrawledAuctions,
    page,
    isLoading,
    isLastPage,
    modalFilterOpen,
    modalCardIsOpen,
    auctions,
    featuredVisible,
    cleanFilters,
    filter,
    openPreRegModal,
    applyFilters,
    isFiltered,
    sendEventMixpanelTrack,
  };
};
