import { reactive } from "vue";

const state = reactive({
  conversionData: {},
  registered: false,
  activeTab: "",
  isSubmitting: false,
  isBottomSheetOpen: false,
  userPhone: "",
  userEmail: "",
  userName: "",
  title: "Alerta de ofertas",
  modal: null,
  origin: "",
});

const checkPhone = () => {
  if (!state.userPhone) return false;

  if (state.userPhone.includes("(") && state.userPhone.length >= 14)
    return true;

  return (
    !state.userPhone.includes("(") &&
    (state.userPhone.length === 10 || state.userPhone.length === 11)
  );
};

const checkEmail = () => {
  return /^[^@]+@\w+(\.\w+)+\w$/.test(state.userEmail);
};

const removeAnimation = () => {
  state.modal.classList.remove("fade-in-up");
};

const addAnimation = () => {
  state.modal.classList.add("fade-in-up");
};

export { state, checkPhone, checkEmail, removeAnimation, addAnimation };
