<template>
  <div class="card-size">
    <div class="recommendation-card">
      <YoutubePlayer
        v-if="animal.video"
        :videoId="animal.video"
        :previewUrl="animal.video_cover"
        class="is-relative"
        :customClasses="['video-card']"
        :customPreviewClasses="['video-card']"
        autoplay
        preview
        loop
      />

      <img v-else class="video-card" :src="noVideoImage" alt="Vídeo em breve" />

      <div class="card-content">
        <span class="batch-number"> Lote {{ animal.batch_number }} </span>

        <h2 class="name">{{ animal.batch_name }}</h2>

        <p v-if="animal.animals_quantity > 1" class="average-title">
          Média dos animais:
        </p>

        <div class="is-flex">
          <div v-if="animal.zebu_index" class="highlight-infos">
            <span>iABCZ:</span>
            <b>{{ animal.zebu_index }}</b>
          </div>
          <div v-if="animal.scrotal_circumference" class="highlight-infos">
            <span>CE:</span>
            <b>{{ animal.scrotal_circumference }}cm</b>
          </div>
          <div v-if="animal.weight" class="highlight-infos">
            <span>Peso:</span>
            <b>{{ animal.batch_weight }}kg</b>
          </div>
        </div>

        <table class="table-infos">
          <tr v-if="animal.trials['PD-ED']">
            <td class="title-info">Ganho de peso</td>
            <td class="skill-info">PD-ED</td>
            <td class="deca-info">{{ animal.trials["PD-ED"] }}</td>
          </tr>
          <tr v-if="animal.trials['AOL']">
            <td class="title-info">Carcaça</td>
            <td class="skill-info">AOL</td>
            <td class="deca-info">{{ animal.trials["AOL"] }}</td>
          </tr>
          <tr v-if="animal.trials['IPP']">
            <td class="title-info">Precocidade</td>
            <td class="skill-info">IPP</td>
            <td class="deca-info">{{ animal.trials["IPP"] }}</td>
          </tr>
          <tr v-if="animal.trials['PM-EM']">
            <td class="title-info">Habilidade materna</td>
            <td class="skill-info">PM-EM</td>
            <td class="deca-info">{{ animal.trials["PM-EM"] }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import YoutubePlayer from "components/ui/YoutubePlayer.vue";
import noVideoImage from "images/no-image-thumb-large.png";

export default {
  components: {
    YoutubePlayer,
  },
  props: {
    animal: Object,
  },
  setup(props) {
    return {
      noVideoImage,
    };
  },
};
</script>
