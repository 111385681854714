<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <AppInput
          placeholder="Digite seu nome"
          label="Nome"
          name="nome"
          :value="userName"
          @input="(v) => (userName = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          ref="emailInput"
          name="email"
          type="email"
          placeholder="Digite seu email"
          label="E-mail"
          :value="email"
          :showMessage="emailInputError"
          :error="emailInputError"
          @input="(v) => (userEmail = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          v-mask="['(##) ####-####', '(##) # ####-####']"
          placeholder="Digite seu telefone"
          type="tel"
          message="Validate"
          label="Telefone"
          name="telefone"
          :value="phone"
          :showMessage="false"
          @input="(v) => (userPhone = v)"
        />
      </div>
      <div v-show="showCallbackMessage" class="column is-12">
        <div class="notification is-light" :class="notificationClassStatus">
          {{ callbackMessage }}
        </div>
      </div>
    </div>
    <div
      v-if="likeModal"
      class="has-text-centered is-flex is-flex-direction-column"
    >
      <button
        class="button is-primary mb-2 is-fullwidth"
        :disabled="isDisabled"
        :class="{
          'is-loading': submitting,
        }"
        @click="conversionSubmit"
      >
        Continuar busca
      </button>
    </div>
    <div v-else class="has-text-centered">
      <button
        class="button is-primary mb-2 is-fullwidth"
        :disabled="isDisabled"
        :class="{
          'is-loading': submitting,
        }"
        @click="talkToSpecialist"
      >
        <WhatsappIcon class="favorite-icon mr-3" />
        Falar com um especialista
      </button>
      <button
        class="button is-fullwidth is-warning is-outlined"
        :disabled="isDisabled"
        :class="{
          'is-loading': submitting,
        }"
        @click="backToBegin"
      >
        <Replay class="replay-icon mr-3" />
        Voltar para o início
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { mask } from "vue-the-mask";
import AppInput from "components/ui/AppInput.vue";

import api from "services/api";

import WhatsappIcon from "icons/cached/whatsapp.svg";
import Replay from "icons/cached/replay.svg";

import {
  currentAnimal,
  favoriteAnimals,
  markAsFavorite,
  sendWhatsappNotification,
} from "./recommendationState";

export default {
  directives: {
    mask,
  },
  components: {
    AppInput,
    WhatsappIcon,
    Replay,
  },
  props: {
    animals: Array,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    mixpanelData: Object,
    likeModal: Boolean,
  },
  emits: ["converted", "initial"],
  setup(props, { emit }) {
    const userName = ref(props.lpConversion.lp_conversion.name);
    const userEmail = ref(props.lpConversion.lp_conversion.email);
    const userPhone = ref(props.lpConversion.lp_conversion.phone);
    const currentFormDisplay = ref(props.formDisplay);

    const registered = ref(false);
    const submitting = ref(false);
    const isDisabled = computed(() => {
      if (submitting.value || registered.value) {
        return true;
      }

      return !(
        !!checkEmail(userEmail.value) &&
        !!userName.value &&
        !!checkPhone(userPhone.value)
      );
    });

    const checkPhone = (value) => {
      if (!value) return false;

      if (value.includes("(") && value.length >= 14) return true;

      return (
        !value.includes("(") && (value.length === 10 || value.length === 11)
      );
    };

    const emailInput = ref(null);
    const emailInputError = ref(false);
    const emailInputErrorMessage = "E-mail inválido! Tente novamente";

    watch(userEmail, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    });

    const checkEmail = (value) => {
      if (value) {
        return !emailInputError.value;
      } else {
        emailInputError.value = false;

        return false;
      }
    };

    const showCallbackMessage = ref(false);
    const callbackMessage = ref("");
    const notificationClassStatus = ref("");

    const conversionSubmit = () => {
      mixpanel.track("Recommendation - Continue search", props.trackData);
      markAsFavorite(props.animals[currentAnimal.value]);
      const currentConversion = props.lpConversion;

      const favoriteAsAnswers = favoriteAnimals.value.map((el, index) => {
        return {
          question: `Animal curtido ${index + 1}`,
          answer: `Lote ${el.batch_number} - ${el.auction_title}`,
        };
      });

      currentConversion.additional_infos = [].concat(
        currentConversion.additional_infos,
        favoriteAsAnswers,
      );

      showCallbackMessage.value = false;
      currentConversion.lp_conversion.name = userName.value;
      currentConversion.lp_conversion.email = userEmail.value;

      const phone = userPhone.value.replace(/\(|\)|\-|\s/g, "");

      currentConversion.lp_conversion.phone =
        phone.slice(0, 2) + " " + phone.slice(2);

      currentConversion.batch_id = favoriteAnimals.value.at().batch_id;

      sendConversion(currentConversion, "converted");

      const parsedPhone = userPhone.value.replace(/[()-\s]/g, "");

      sendWhatsappNotification(userName.value, parsedPhone);
    };

    let conversionSend = false;
    const sendConversion = (currentConversion, emitName) => {
      if (conversionSend) {
        emit(emitName);
        return;
      }

      submitting.value = true;
      api
        .post(currentConversion.action, currentConversion)
        .then(() => {
          const track = ref(props.trackData);

          registered.value = false;
          submitting.value = false;
          conversionSend = true;
          track.value.email = currentConversion.lp_conversion.email;

          const cvData = {
            "Conversion Name": userName.value,
            "Conversion Email": userEmail.value,
            "Conversion Phone": userPhone.value,
            "Form Display": currentFormDisplay.value,
            Page: "Recomendação",
            Section: props.section,
            ...props.mixpanelData,
          };

          if (props.section == "CTA-Button") {
            cvData["Section"] = document
              .getElementById("ctaButton")
              .getAttribute("data-track");
          }

          mixpanel.track(`Recommendation - User Converted`, cvData);
          emit(emitName);
        })
        .catch(() => {
          submitting.value = false;
          callbackMessage.value =
            "Ocorreu um erro inesperado! Tente novamente.";
          showCallbackMessage.value = true;
          notificationClassStatus.value = "is-danger";
        });
    };

    const talkToSpecialist = () => {
      mixpanel.track("Recommendation - Talk to specialist", props.trackData);
      const currentConversion = props.lpConversion;

      showCallbackMessage.value = false;
      currentConversion.lp_conversion.name = userName.value;
      currentConversion.lp_conversion.email = userEmail.value;

      const phone = userPhone.value.replace(/\(|\)|\-|\s/g, "");

      currentConversion.lp_conversion.phone =
        phone.slice(0, 2) + " " + phone.slice(2);

      sendConversion(currentConversion, "converted");
      window.open("https://www.erural.net/wpp/atendimentomeulote", "_blank");
    };

    const backToBegin = () => {
      mixpanel.track("Recommendation - Back to beginning", props.trackData);
      const currentConversion = props.lpConversion;

      showCallbackMessage.value = false;
      currentConversion.lp_conversion.name = userName.value;
      currentConversion.lp_conversion.email = userEmail.value;

      const phone = userPhone.value.replace(/\(|\)|\-|\s/g, "");

      currentConversion.lp_conversion.phone =
        phone.slice(0, 2) + " " + phone.slice(2);

      sendConversion(currentConversion, "initial");
    };

    return {
      userName,
      userEmail,
      userPhone,
      isDisabled,
      emailInput,
      emailInputError,
      emailInputErrorMessage,
      conversionSubmit,
      talkToSpecialist,
      submitting,
      showCallbackMessage,
      notificationClassStatus,
      callbackMessage,
      registered,
      backToBegin,
    };
  },
};
</script>
