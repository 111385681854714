import api from "services/api";
import { reactive } from "vue";
import { useToast } from "vue-toastification";

import {
  animal_ai_descriptions_describe_animal_url,
  animal_ai_descriptions_save_description_url,
} from "utils/routes";

const state = reactive({
  animalId: null,
  animalDescriptionId: null,
  description: "",
  isModalOpen: false,
  isLoading: false
});

const toast = useToast();

const closeModal = () => {
  state.animalDescriptionId = null;
  state.description = "";
  state.isModalOpen = false;
};

const generateDescription = () => {
  state.isLoading = true;
  state.isModalOpen = true;

  api
    .post(animal_ai_descriptions_describe_animal_url(state.animalId))
    .then((res) => {
      state.animalDescriptionId = res.data.animal_description_id;
      state.description = res.data.ai_description;
      state.isLoading = false;
    })
    .catch((error) => {
      toast.error("Erro ao gerar descrição do animal");
    });
};

const publishDescription = () => {
  api
    .post(
      animal_ai_descriptions_save_description_url(
        state.animalId,
        state.animalDescriptionId,
      ),
      {
        description: state.description,
      },
    )
    .then((res) => {
      closeModal();
      location.reload();
      toast.success("Descrição atualizada com sucesso!");
    })
    .catch((error) => {
      toast.error("Erro ao salvar descrição do animal");
    });
};

export { state, closeModal, generateDescription, publishDescription };
