<template>
  <div
    id="mobileFilter"
    class="modal modal-filter is-hidden-desktop"
    :class="{ 'is-active': modalFilterOpen }"
  >
    <div class="modal-background" @click="modalFilterOpen = false" />
    <div
      class="m-0 px-0 py-4 modal-filter-content is-flex is-flex-direction-column"
    >
      <div>
        <div class="is-flex is-justify-content-flex-end px-4">
          <CloseIcon class="close-icon" @click="modalFilterOpen = false" />
        </div>
        <div class="has-text-weight-semibold has-text-centered modal-title">
          Filtre o Evento ideal para você!
        </div>
      </div>

      <div class="filters is-flex-grow-1">
        <div class="columns is-mobile is-multiline is-relative">
          <div
            class="column filter-section is-6 is-flex is-align-items-center py-4 my-1 pl-5"
          >
            <div class="filter-icon">
              <DataPickIcon />
            </div>

            <span class="ml-1">Data do Evento</span>
          </div>
          <div
            class="column is-6 has-text-black-ter has-text-weight-medium is-flex is-align-items-center py-4 pr-5 my-1 is-justify-content-flex-end"
          >
            {{ date.toString() }}
            <ExpandMoreIcon class="expand-icon" />
          </div>

          <input v-model="date" type="date" />
        </div>

        <div class="columns is-mobile">
          <div
            class="column filter-section is-7 is-flex is-align-items-center py-4 my-1 pl-5"
          >
            <div class="filter-icon">
              <LocalShippingIcon />
            </div>

            <span class="ml-1">Tipo de Frete</span>
          </div>

          <div
            class="column is-5 has-text-black-ter has-text-weight-medium is-flex is-align-items-center pr-5 is-justify-content-flex-end"
          >
            <div class="is-flex">
              <label class="switch">
                <input
                  v-model="shipping"
                  type="checkbox"
                  value="free_shipping"
                />
                <span class="slider round" />
              </label>
              <span
                v-if="shipping.indexOf('free_shipping') != -1"
                class="is-primary has-text-semibold"
              >
                Grátis
              </span>
              <span v-else class="has-text-semibold">Todos</span>
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-multiline is-relative">
          <div
            class="column filter-section is-5 is-flex is-align-items-center py-4 my-1 pl-5"
          >
            <div class="filter-icon">
              <LocationOnIcon />
            </div>

            <span class="ml-1">Estados</span>
          </div>

          <div
            class="column is-7 has-text-black-ter has-text-weight-medium is-flex is-align-items-center pr-5 is-justify-content-flex-end"
          >
            {{ stateText }}
            <ExpandMoreIcon class="expand-icon" />
          </div>

          <select v-model="state" class="is-borderless" multiple>
            <option
              v-for="(inputState, index) in inputStates"
              :key="`state-chip-${index}`"
              :value="index"
            >
              {{ inputState }}
            </option>
          </select>
        </div>

        <div
          class="columns is-mobile is-multiline"
          :class="{ open: openCategoriesContent }"
        >
          <div
            class="column filter-section is-narrow is-flex is-align-items-center py-4 my-1 pl-5"
            @click="openCategoriesContent = !openCategoriesContent"
          >
            <div class="filter-icon">
             <LocalOfferIcon />
            </div>

            <span class="ml-1">Categorias</span>
          </div>
          <div
            class="column has-text-black-ter has-text-weight-medium is-flex is-align-items-center pr-5 is-justify-content-flex-end"
            @click="openCategoriesContent = !openCategoriesContent"
          >
            <span class="is-clipped">{{ categoryText }}</span>
            <ExpandMoreIcon class="expand-icon" />
          </div>

          <div class="column is-12 category-content">
            <div
              class="columns is-mobile px-4 py-2 is-flex is-align-items-center"
            >
              <div class="column is-3 px-1 py-0">
                <div
                  class="category-button bovine is-flex is-align-items-center is-justify-content-center"
                  :class="category.indexOf('bovine') != -1 ? 'active' : ''"
                >
                  <label
                    for="bovine"
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    Bovinos
                  </label>
                  <input
                    id="bovine"
                    v-model="category"
                    type="checkbox"
                    value="bovine"
                  />
                </div>
              </div>
              <div class="column is-3 px-1 py-0">
                <div
                  class="category-button equidae"
                  :class="category.indexOf('equidae') != -1 ? 'active' : ''"
                >
                  <label
                    for="equidae"
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    Equídeos
                  </label>
                  <input
                    id="equidae"
                    v-model="category"
                    type="checkbox"
                    value="equidae"
                  />
                </div>
              </div>
              <div class="column is-3 px-1 py-0">
                <div
                  class="category-button ovine"
                  :class="category.indexOf('ovine') != -1 ? 'active' : ''"
                >
                  <label
                    for="ovine"
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    Ovino
                  </label>
                  <input
                    id="ovine"
                    v-model="category"
                    type="checkbox"
                    value="ovine"
                  />
                </div>
              </div>
              <div class="column is-3 px-1 py-0">
                <div
                  class="category-button caprine"
                  :class="category.indexOf('caprine') != -1 ? 'active' : ''"
                >
                  <label
                    for="caprine"
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    Caprino
                  </label>
                  <input
                    id="caprine"
                    v-model="category"
                    type="checkbox"
                    value="caprine"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-multiline is-relative">
          <div
            class="column filter-section is-narrow is-flex is-align-items-center py-4 my-1 pl-5"
          >
            <div class="filter-icon">
              <DnaIcon />
            </div>

            <span class="ml-1">Raças</span>
          </div>
          <div
            class="column has-text-black-ter has-text-weight-medium is-flex is-align-items-center is-justify-content-flex-end pr-5"
          >
            {{ breedText }}
            <ExpandMoreIcon class="expand-icon" />
          </div>

          <select v-model="breed" class="is-borderless" multiple>
            <option
              v-for="curBreed in selectedBreeds"
              :key="curBreed"
              :value="curBreed"
            >
              {{ curBreed }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="buttons-content pt-4 px-2 is-flex is-justify-content-space-between"
      >
        <button
          class="button"
          @click.prevent="cleanFilters(), (modalFilterOpen = false)"
        >
          Limpar
        </button>
        <button
          class="button"
          :class="{ 'is-primary': isFiltered }"
          @click.prevent="applyFilters(), (modalFilterOpen = false)"
        >
          Filtrar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useCrawledAuctions } from "hooks/useCrawledAuctions";
import { ref, toRefs, computed } from "vue";
import breedsList from "utils/breedsList";

// ICONS
import ExpandMoreIcon from "icons/cached/expand_more.svg";
import CloseIcon from "icons/cached/close.svg";
import LocationOnIcon from "icons/cached/location_on.svg";
import LocalOfferIcon from "icons/cached/local_offer.svg";
import BovineIcon from "icons/cached/bovine.svg";
import DnaIcon from "icons/cached/dna.svg";
import DataPickIcon from "icons/cached/event.svg";
import LocalShippingIcon from "icons/cached/local_shipping.svg";

export default {
  components: {
    ExpandMoreIcon,
    CloseIcon,
    LocationOnIcon,
    LocalOfferIcon,
    BovineIcon,
    DnaIcon,
    DataPickIcon,
    LocalShippingIcon,
  },
  props: {
    chipCategories: Array,
    shippingType: Object,
    inputStates: Object,
  },
  setup() {
    const {
      modalFilterOpen,
      filter,
      cleanFilters,
      applyFilters,
      isFiltered,
    } = useCrawledAuctions();
    const openShippingContent = ref(false);
    const openCategoriesContent = ref(false);
    let categoryObj = {
      bovine: "Bovinos",
      equidae: "Equídeos",
      ovine: "Ovinos",
      caprine: "Caprinos",
    };

    const selectedBreeds = computed(() => {
      if (categoryText.value == "") {
        return [
          breedsList.Bovinos,
          breedsList.Equídeos,
          breedsList.Ovinos,
          breedsList.Caprinos,
        ].flat();
      }

      let list = [];

      filter.category.forEach((item) => {
        list.push.apply(list, breedsList[categoryObj[item]]);
      });

      return list;
    });

    const shippingText = computed(() => {
      let shippingObj = {
        no_informations: "Sem informações",
        free_shipping: "Frete grátis*",
        facilitated_shipping: "Frete facilitado",
        shipping_by_buyer: "Por conta do comprador",
      };

      let text = [];
      filter.shipping.forEach((item) => {
        text.push(shippingObj[item]);
      });

      if (!!filter.shipping.length) {
        if (filter.shipping.length > 1) {
          return (
            text.slice(0, 1).toString() + " + " + (filter.shipping.length - 1)
          );
        }
      }

      return text.toString();
    });

    const categoryText = computed(() => {
      let text = [];
      filter.category.forEach((item) => {
        text.push(categoryObj[item]);
      });

      if (!!filter.category.length) {
        if (filter.category.length > 2) {
          return (
            text.slice(0, 2).toString() + " + " + (filter.category.length - 2)
          );
        }
      }

      return text.toString();
    });

    const breedText = computed(() => {
      let text = filter.breed;

      if (!!filter.breed.length) {
        if (filter.breed.length > 2) {
          return (
            text.slice(0, 2).toString() + " + " + (filter.breed.length - 2)
          );
        }
      }

      return text.toString();
    });

    const stateText = computed(() => {
      let text = filter.state;

      if (!!filter.state.length) {
        if (filter.state.length > 5) {
          return (
            text.slice(0, 5).toString() + " + " + (filter.state.length - 5)
          );
        }
      }

      return text.toString();
    });

    return {
      modalFilterOpen,
      cleanFilters,
      shippingText,
      categoryText,
      breedText,
      stateText,
      selectedBreeds,
      openShippingContent,
      openCategoriesContent,
      isFiltered,
      applyFilters,
      ...toRefs(filter),
    };
  },
};
</script>
