import api from "services/api";
import { recommendations_send_notification_path } from "utils/routes";
import { ref } from "vue";

const currentAnimal = ref(0);

const favoriteAnimals = ref([]);

const markAsFavorite = (currentAnimal) => {
  if (
    !favoriteAnimals.value.find(
      (animal) => animal.batch_id === currentAnimal.batch_id,
    )
  ) {
    favoriteAnimals.value.push(currentAnimal);
  }
};

const buildWhatsappMessage = (name) => {
  let message =
    `Olá, ${name}.\n` +
    "Aqui está sua lista de favoritos criada a partir das recomendações da erural:\n\n";

  message += favoriteAnimals.value
    .map(
      (batch) =>
        `LOTE: ${batch.batch_number}\nEvento: ${batch.auction_title}${
          batch.batch_register ? "\nRegistro: " + batch.batch_register : ""
        }\n${batch.link}\n`,
    )
    .join("\n");

  return message;
};

const sendWhatsappNotification = (name, phone) => {
  const message = buildWhatsappMessage(name);

  api.post(recommendations_send_notification_path(), {
    name: name,
    phone: phone,
    message: message,
  });
};

const transformLikedAnimalProperties = (props) => {
  const desiredProps = {
    animals_quantity: "Animais_Quantidade",
    trials: "Animais_Características",
    auction_title: "Leilão_Título",
    batch_id: "Lote_ID",
    batch_name: "Lote_Nome",
    batch_number: "Lote_Número",
    batch_register: "Lote_Registro",
    batch_weight: "Lote_Peso",
    email: "Email",
    link: "Link_Lote",
    origin: "Origem",
    scrotal_circumference: "Circunferência_Escrotal",
    token: "Questionário_Token",
    utm_campaign: "UTM_Campaign",
    utm_content: "UTM_Content",
    utm_medium: "UTM_Medium",
    utm_source: "UTM_Source",
    utm_term: "UTM_Term",
    utm_term_id: "UTM_Term_ID",
    utm_content_id: "UTM_Content_ID",
    utm_campaign_id: "UTM_Campaign_ID",
    video: "Link_Vídeo",
    zebu_index: "Índice_ABCZ",
  };

  return Object.keys(props).reduce((acc, curKey) => {
    const transformedKey = desiredProps[curKey];
    acc[transformedKey] = props[curKey];
    acc["Link_Vídeo"] = props["video"][0];
    return acc;
  }, {});
};

export {
  currentAnimal,
  favoriteAnimals,
  markAsFavorite,
  buildWhatsappMessage,
  sendWhatsappNotification,
  transformLikedAnimalProperties,
};
