<template>
  <div class="modal auction-info-modal" :class="{ 'is-active': open }">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content">
      <div class="modal-header">
        <button class="delete" @click="$emit('close')" />
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="columns">
            <div class="column is-offset-3 is-6">
              <span class="title">Mais informações</span>
              <div class="header">
                <img :src="auction.cover" :alt="'Banner - ' + auction.title" />
                <div class="has-text-left">
                  <span class="title">
                    {{ auction.title }}
                  </span>
                  <span class="date">
                    {{ auction.day }}/{{ auction.month }} - {{ auction.time }}
                  </span>
                </div>
              </div>
              <p class="description">
                <span v-html="auction.rich_description" />
              </p>

              <div class="auction-info">
                <div class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <DataPickIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title">
                        {{ auction.day }} de {{ auction.month }}, às
                        {{ auction.time }} (BRT)
                      </span>
                    </div>
                  </div>
                </div>

                <div class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <PlaceIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title is-capitalized">
                        {{ auction.city }} {{ "- " + auction.state }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="auction.shipping_description" class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <LocalShippingIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title">
                        {{ auction.shipping_name }}
                      </span>
                      <p class="pre-wrap">
                        {{ auction.shipping_description }}
                      </p>
                    </div>
                  </div>
                </div>

                <div v-if="auction.payment_summary" class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <MonetizationIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title">
                        {{ auction.payment_summary }}:
                      </span>
                      <p>
                        {{ auction.payment_explanation }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <GavelIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title">
                        {{ providerText }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="auction.channel" class="block">
                  <div class="columns">
                    <div class="column is-1">
                      <PlayArrowIcon />
                    </div>
                    <div class="column is-11">
                      <span class="title">
                        Transmissão {{ auction.channel }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="auction-documents">
                <div v-if="auction.catalog" class="block">
                  <a
                    id="downloadCatalogModal"
                    :href="auction.catalog"
                    download
                    target="_blank"
                  >
                    <div class="document">
                      <div class="circle">
                        <DownloadIcon />
                      </div>
                      <div>
                        <span class="title">
                          {{ auction.catalog_file_name }}
                        </span>
                        <span class="description">
                          Baixar agora
                          <span class="has-text-weight-normal">
                            - {{ formatBytes(auction.catalog_file_size) }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>

                <div v-for="file in auctionFiles" :key="file.id" class="block">
                  <a :href="file.file_link" download target="_blank">
                    <div class="document">
                      <div class="circle">
                        <DownloadIcon />
                      </div>
                      <div>
                        <span class="title">
                          {{ file.file_file_name }}
                        </span>
                        <span class="description">
                          Baixar agora
                          <span class="has-text-weight-normal">
                            - {{ formatBytes(file.file_file_size) }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="has-text-centered">
                <button class="button is-success" @click="$emit('close')">
                  Voltar ao Evento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import MonetizationIcon from "icons/cached/monetization_on.svg";
import DownloadIcon from "icons/cached/download.svg";
import DataPickIcon from "icons/cached/event.svg";
import PlaceIcon from "icons/cached/place.svg";
import LocalShippingIcon from "icons/cached/local_shipping.svg";
import GavelIcon from "icons/cached/gavel.svg";
import PlayArrowIcon from "icons/cached/play_arrow.svg";

export default {
  components: {
    MonetizationIcon,
    DownloadIcon,
    DataPickIcon,
    PlaceIcon,
    LocalShippingIcon,
    GavelIcon,
    PlayArrowIcon,
  },
  props: {
    open: Boolean,
    auction: Object,
    auctionFiles: Object,
    mixpanelData: Object,
  },
  emits: ["close"],
  setup(props) {
    onMounted(() => {
      mixpanel.track_links("#downloadCatalogModal", "Event - Catalog Clicked", {
        ...props.mixpanelData,
        Section: "Infos Modal",
      });
    });

    const formatBytes = (a, b = 2) => {
      if (0 === a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
      return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      );
    };

    const providerText = computed(() => {
      if (props.auction.providers.length == 1)
        return props.auction.providers[0];

      return "Diversos fornecedores";
    });

    return {
      formatBytes,
      providerText,
    };
  },
};
</script>
