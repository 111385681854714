<template>
  <div
    class="is-flex is-align-items-center is-flex-direction-column is-justify-content-space-between"
  >
    <img :src="ChicoImg" alt="Imagem do Chico" class="my-5" />

    <p class="is-size-6 has-text-centered has-text-weight-normal mb-5">
      Este alerta já está ativado.
    </p>

    <button
      class="button is-warning is-fullwidth"
      @click="state.isBottomSheetOpen = false"
    >
      Fechar
    </button>
  </div>
</template>

<script>
import ChicoImg from "images/chico/chico_small.png";

import { state } from "./alertState";

export default {
  components: {},
  props: {},
  setup() {
    return { state, ChicoImg };
  },
};
</script>
