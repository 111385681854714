<template>
  <div class="modal update-password is-active">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content p-4">
      <div class="modal-header mb-2">
        <div class="has-text-centered has-text-weight-semibold">
          Atualize sua senha
        </div>
        <button class="delete" @click="$emit('close')" />
      </div>

      <FlashMessage
        :is-open="!!flash"
        :message="flash"
        is-syncronized
      />

      <PasswordInput
        name="current_password"
        label="Senha atual"
        :value="currentPassword"
        is-synchronized
        @input="(value) => (currentPassword = value)"
      />

      <PasswordInput
        name="new_password"
        show-message
        message="Pelo menos 6 caracteres."
        label="Nova senha"
        :value="newPassword"
        is-synchronized
        class="my-5"
        @input="(value) => (newPassword = value)"
      />
      <PasswordInput
        :showMessage="differentPasswords"
        name="password_confirmation"
        message="As senhas devem ser iguais."
        label="Confirmar senha"
        :value="passwordConfirm"
        is-synchronized
        :error="differentPasswords"
        @input="(value) => (passwordConfirm = value)"
      />

      <div class="is-flex is-justify-content-space-between mt-5">
        <button class="button" @click.prevent.stop="$emit('close')">
          Cancelar
        </button>
        <button
          class="button is-primary"
          :disabled="isDisabled"
          @click.prevent="onSubmit"
        >
          Atualizar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watchEffect, computed } from "vue";
import { acc_update_password_url } from "utils/routes";
import api from "services/api";

import PasswordInput from "components/ui/PasswordInput.vue";
import FlashMessage from "components/ui/FlashMessage.vue";

export default {
  components: {
    FlashMessage,
    PasswordInput,
  },
  emits: ["close", "update"],
  setup(_props, { emit }) {
    const isDisabled = ref(false);
    const currentPassword = ref("");
    const newPassword = ref("");
    const passwordConfirm = ref("");
    const differentPasswords = computed(
      () =>
        newPassword.value.length > 5 &&
        newPassword.value != passwordConfirm.value,
    );

    watchEffect(() => {
      if (
        newPassword.value.length > 5 &&
        passwordConfirm.value.length > 5 &&
        newPassword.value == passwordConfirm.value &&
        currentPassword.value.length > 0
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });

    const flash = ref(null);
    const onSubmit = () => {
      api
        .put(acc_update_password_url(), {
          current_password: currentPassword.value,
          new_password: newPassword.value,
          password_confirmation: passwordConfirm.value,
        })
        .then(() => {
          emit("update", "Senha atualizada com sucesso!");
          emit("close");
        })
        .catch((error) => {
          flash.value = error.response.data.error;
        });
    };

    return {
      isDisabled,
      currentPassword,
      newPassword,
      passwordConfirm,
      differentPasswords,
      onSubmit,
      flash,
    };
  },
};
</script>
