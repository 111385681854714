<template>
  <div class="bid">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
    </div>

    <div class="modal-body">
      <div class="content top">
        <AuctionRegistrationModalBatchCard />

        <div class="mt-6">
          <h3 class="title">
            Escolha um valor para tentar arrematar este lote
          </h3>
        </div>
      </div>

      <div class="content middle">
        <div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
          <button
            class="button circle-btn is-outlined is-primary"
            :disabled="state.bidValue <= minimumBid()"
            @click="onDecrement()"
          >
            <RemoveIcon />
          </button>
          <div class="is-flex-shrink-0 mx-4">
            <span class="title is-size-7-mobile is-size-6-tablet">
              {{ state.batch.installments }}x
            </span>
            <span class="title has-text-weight-bold is-size-4-mobile is-size-3-tablet">
              {{ numberToCurrency(state.bidValue).split(",")[0] }}
            </span>
          </div>
          <button
            class="button circle-btn is-outlined is-primary"
            @click="onIncrement()"
          >
            <AddIcon />
          </button>
        </div>
        <div class="description has-text-centered mt-4">
          <span>
            Valor total:
            {{ numberToCurrency(state.bidValue * state.batch.installments * state.batch.animals_count) }}
          </span>
        </div>

        <div class="description has-text-centered mt-5">
          <span v-if="state.batch.price > state.batch.initial_price">
            Último pré-lance registrado:
          </span>
          <span v-else>Lance inicial </span>
          <span>
            {{ state.batch.installments }}x&nbsp;
          </span>
          <span v-if="state.batch.price > state.batch.initial_price">
            {{ numberToCurrency(state.batch.price / state.batch.installments / state.batch.animals_count) }}
          </span>
          <span v-else>
            {{
              numberToCurrency(
                state.batch.initial_price / state.batch.installments / state.batch.animals_count,
              )
            }}
          </span>
        </div>
      </div>

      <div class="content bottom">
        <div class="block">
          <button
            class="button is-primary gavel-bt"
            :class="{ 'is-loading': state.submitting }"
            @click="setActiveTab('identification')"
          >
            <GavelIcon class="mr-2" />
            Registrar pré-lance
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chicoImg from "images/chico/chico.png";
import GavelIcon from "icons/cached/gavel.svg";
import AddIcon from "icons/cached/add.svg";
import RemoveIcon from "icons/cached/remove.svg";

import AuctionRegistrationModalBatchCard from "./AuctionRegistrationModalBatchCard.vue";

import {
  state,
  setActiveTab,
  closeModal,
} from "./auctionRegistrationModalContext";

import { numberToCurrency } from "utils/currencyConverter";

import { onMounted, computed } from "vue";

export default {
  components: {
    GavelIcon,
    AddIcon,
    RemoveIcon,
    AuctionRegistrationModalBatchCard,
  },
  setup() {
    onMounted(() => {
      state.previuosTab = "bid";
      state.currentFlow = "bid";
      state.bidValue = minimumBid();
    });

    const onIncrement = () => {
      state.bidValue += state.batch.price_increment;
    };

    const onDecrement = () => {
      state.bidValue -= state.batch.price_increment;
    };

    const minimumBid = () => {

      if(state.batch.price  > state.batch.initial_price / state.batch.installments) {
         return (state.batch.price / state.batch.installments / state.batch.animals_count)  + state.batch.price_increment
      } else {
        return (state.batch.initial_price / state.batch.installments / state.batch.animals_count);
      }
    };

    return {
      chicoImg,
      state,
      setActiveTab,
      closeModal,
      AddIcon,
      RemoveIcon,
      onIncrement,
      onDecrement,
      minimumBid,
      numberToCurrency,
    };
  },
};
</script>
