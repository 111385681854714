export default {
  Bovinos: [
    "Nelore",
    "Brahman",
    "Senepol",
    "Tabapuã",
    "Angus",
    "Brangus",
    "Braford",
    "Guzerá",
  ],
  Equídeos: [
    "Mangalarga Marchador",
    "Quarto de Milha",
    "Campolina",
    "Crioulo",
    "Pampa",
  ],
  Ovinos: ["Dorper", "Santa Inês"],
  Caprinos: ["Boer", "Anglonubiana"],
};
