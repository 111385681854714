<template>
  <div class="modal alert-bottom-sheet is-active">
    <div class="modal-background" @click="state.isBottomSheetOpen = false" />
    <div ref="modal" class="modal-content fade-in-up">
      <CloseIcon class="close-icon" @click="state.isBottomSheetOpen = false" />
      <KeyboardArrowLeft
        v-if="state.activeTab == 'form'"
        class="chevron-left"
        @click="state.activeTab = 'phone'"
      />

      <div class="top-section">
        <h3 class="is-size-5 has-text-weight-bold">{{ state.title }}</h3>
        <div
          v-if="state.activeTab != 'finish' && state.activeTab != 'registered'"
          class="is-flex is-justify-content-center"
        >
          <div class="first-step mr-2 bg-orange" />
          <div
            class="second-step"
            :class="{
              'bg-orange': state.activeTab == 'form',
            }"
          />
        </div>
      </div>

      <component :is="steps[state.activeTab]" />
    </div>
  </div>
</template>

<script>
import AlertPhoneForm from "./AlertPhoneForm.vue";
import AlertForm from "./AlertForm.vue";
import AlertConfirmation from "./AlertConfirmation.vue";
import AlertRegistered from "./AlertRegistered.vue";

import CloseIcon from "icons/cached/close.svg";
import KeyboardArrowLeft from "icons/cached/keyboard_arrow_left.svg";

import { state } from "./alertState";
import { ref, onMounted } from "vue";

export default {
  components: {
    AlertPhoneForm,
    AlertForm,
    AlertConfirmation,
    AlertRegistered,
    CloseIcon,
    KeyboardArrowLeft,
  },
  props: {},
  setup() {
    const modal = ref(null);
    const steps = {
      phone: "AlertPhoneForm",
      form: "AlertForm",
      finish: "AlertConfirmation",
      registered: "AlertRegistered",
    };

    onMounted(() => {
      state.modal = modal;
    });

    return { state, modal, steps };
  },
};
</script>
