export default {
  Aspiração: "Aspirações",
  Bezerro: "Bezerros",
  Bezerra: "Bezerras",
  Garrote: "Garrotes",
  Novilha: "Novilhas",
  Touro: "Touros",
  Matriz: "Matrizes",
  Sêmen: "Sêmens",
  Embrião: "Embriões",
  Cobertura: "Coberturas",
  Prenhez: "Prenhezes",
};
