<template>
  <div class="reels-card">
    <figure class="image erural-header is-hidden-tablet">
      <a :href="root_path()">
        <img :src="eruralColorImg" alt="Logo erural colorido" />
      </a>
    </figure>

    <div class="columns header">
      <div class="column is-9 auction-info">
        <figure class="image">
          <a :href="auctions_show_path(batch.auction.url_friendly)">
            <img :src="batch.auction.banner" :alt="batch.auction.title" />
          </a>
        </figure>

        <div class="info">
          <h3 class="auction-title">
            <a :href="auctions_show_path(batch.auction.url_friendly)">
              {{ batch.auction.title }}
            </a>
          </h3>

          <p v-if="batch.auction.in_progress" class="date">Disponível agora</p>
          <p v-else class="date">{{ batch.auction.start_date }}</p>

          <p v-if="batch.auction.free_shipping" class="green-info">
            <LocalShippingIcon />
            Frete gratuito*
          </p>
          <p v-else class="green-info">
            <LocationIcon />
            {{ batch.auction.city }} - {{ batch.auction.state }}
          </p>
        </div>
      </div>
    </div>

    <div class="media">
      <a
        :href="reel.prevReelUrl"
        class="button nav-button prev-button is-hidden-touch"
        :class="{ disabled: !reel.prevReelUrl }"
        @click.prevent="$emit('previous')"
        ><ArrowBackIcon
      /></a>

      <figure class="image is-5by3">
        <span v-if="batch.is_sold" class="sold-bar">Vendido</span>

        <YoutubePlayer
          v-if="batch.video"
          :key="batch.id"
          :videoId="batch.video"
          :customClasses="['has-ratio']"
          :customPreviewClasses="['has-ratio']"
          autoplay
          preview
          loop
        />
        <img v-else :src="batch.cover_url" :alt="batch.title" />
      </figure>

      <a
        :href="reel.nextReelUrl"
        class="button nav-button next-button is-hidden-touch"
        @click.prevent="$emit('next')"
        ><ArrowForwardIcon
      /></a>
    </div>

    <div class="columns is-desktop">
      <div class="column navigation">
        <a
          :href="reel.prevReelUrl"
          class="button nav-button is-hidden-desktop"
          :class="{ disabled: !reel.prevReelUrl }"
          @click.prevent="$emit('previous')"
          ><ArrowBackIcon
        /></a>

        <div>
          <h1 class="batch-number">Lote {{ batch.number }}</h1>
          <p class="nav-info">{{ reel.index + 1 }} de {{ totalReels }}</p>
        </div>

        <a
          :href="reel.nextReelUrl"
          class="button nav-button is-hidden-desktop"
          @click.prevent="$emit('next')"
          ><ArrowForwardIcon
        /></a>
      </div>

      <div class="column is-12-touch is-three-fifths-desktop buttons">
        <button
          v-if="!batch.is_sold"
          class="button is-primary"
          @click.prevent="$emit('interest')"
        >
          Ver informações do lote
        </button>
        <button v-else class="button is-small" disabled>Lote vendido</button>
      </div>
    </div>
  </div>
</template>

<script>
import YoutubePlayer from "components/ui/YoutubePlayer.vue";

import LocalShippingIcon from "icons/cached/local_shipping.svg";
import LocationIcon from "icons/cached/location_on.svg";
import ArrowForwardIcon from "icons/cached/arrow_forward.svg";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import eruralColorImg from "images/logos/erural_color.png";

import {
  root_path,
  auctions_show_path,
  crawled_auctions_index_path,
  batches_show_path,
} from "utils/routes";

export default {
  components: {
    YoutubePlayer,
    LocalShippingIcon,
    LocationIcon,
    ArrowForwardIcon,
    ArrowBackIcon,
  },
  props: {
    reel: Object,
    totalReels: Number,
    mixpanelData: Object,
  },
  emits: ["previous", "next", "interest"],
  setup(props) {
    const batch = props.reel.batch;

    return {
      batch,
      root_path,
      auctions_show_path,
      crawled_auctions_index_path,
      batches_show_path,
      eruralColorImg,
    };
  },
};
</script>
