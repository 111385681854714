<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon class="arrow-back" @click="updateActiveAuthStep('cpf')" />
      <span class="title">4/6</span>
      <p class="description">Seu telefone com DDD</p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <div class="columns is-variable is-2 is-mobile">
            <div class="column is-3">
              <div class="select select-input select-phone is-fullwidth">
                <select id="select" v-model="phone_ddi">
                  <option value="" disabled selected>DDI</option>
                  <option
                    v-for="(value, key) in countryDdi"
                    :key="key"
                    :value="key"
                  >
                    <span v-if="value == '+55'"> &#x1F1E7;&#x1F1F7;</span>
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="column is-9">
              <AppInput
                ref="phoneInput"
                v-mask="
                  phone_ddi == '55'
                    ? ['(##) ####-#####', '(##) # ####-####']
                    : '###############'
                "
                type="tel"
                placeholder="Digite"
                :value="phone"
                required
                @input="(v) => (phone = v)"
                @enterPressed="$refs.confirmBt.click()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          @click="updateActiveAuthStep('new_password')"
        >
          Próximo
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('login_review')"
        >
          Já tenho uma conta
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import { ref, computed } from "vue";
import { mask } from "vue-the-mask";
import { useAuthInfos } from "components/user/useAuthInfos.js";

export default {
  directives: {
    mask,
  },
  components: {
    ArrowBackIcon,
    AppInput,
    FacebookAuthButton,
  },
  setup() {
    const {
      updateActiveAuthStep,
      phone,
      phone_ddi,
      countryDdi,
    } = useAuthInfos();

    const phoneInput = ref(null);

    const isDisabled = computed(() => {
      let unmaskedPhone = phone.value.replace(/\D/g, "");

      return (
        !phoneInput.value?.inputEl.checkValidity() ||
        (phone_ddi.value == "55" && unmaskedPhone.length < 10)
      );
    });

    return {
      updateActiveAuthStep,
      isDisabled,
      phone_ddi,
      phone,
      phoneInput,
      countryDdi,
    };
  },
};
</script>
