import { ref, onMounted, onUnmounted } from "vue";

const useScrollPosition = () => {
  const x = ref(0);
  const y = ref(0);

  const handleScroll = (e) => {
    x.value = window.scrollX;
    y.value = window.scrollY;
  }

  onMounted(() => window.addEventListener('scroll', handleScroll));

  onUnmounted(() => window.removeEventListener('scroll', handleScroll));

  return {
    x, y
  }
};

export default useScrollPosition;
