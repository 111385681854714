<template>
  <div
    class="crawled-auction-filters is-hidden-touch"
    :class="{ float: isFloat }"
  >
    <div class="crawled-auction-filters-content pb-5">
      <div
        class="columns m-0 is-hidden-mobile is-flex is-align-items-center is-justify-content-space-between"
      >
        <app-input
          v-model="date"
          :showMessage="true"
          placeholder="DD/MM/YYYY"
          :hasIcon="false"
          label="Data"
          type="date"
          :error="false"
          :value="date"
          :isSynchronized="true"
          @input="handleDate"
        />

        <div class="input-component field">
          <label class="label" for="shippingFilter">Tipo de Frete</label>
          <div class="is-flex">
            <label class="switch">
              <input v-model="shipping" type="checkbox" value="free_shipping" />
              <span class="slider round" />
            </label>
            <span
              v-if="shipping.indexOf('free_shipping') != -1"
              class="is-primary has-text-semibold"
            >
              Grátis
            </span>
            <span v-else class="has-text-semibold">Todos</span>
          </div>
        </div>

        <div class="input-component field breed-legend ml-4">
          <label class="label">
            Categorias //
            <span class="has-text-grey-light">{{ filterText }}</span>
          </label>
          <div class="is-flex">
            <div
              class="dropdown bovine mr-2"
              :class="{ 'is-active': bovineDropdownIsOpen }"
              @mouseleave="bovineDropdownIsOpen = false"
            >
              <div
                class="dropdown-trigger px-3 py-2"
                @mouseenter="bovineDropdownIsOpen = true"
              >
                <span
                  class="is-flex is-justify-content-space-between is-align-items-center is-flex-grow-1"
                  aria-haspopup="true"
                  aria-controls="bovino-dropdown-menu"
                >
                  <span class="has-text-weight-semibold">Bovino</span>

                  <DropdownIcon />
                </span>
              </div>
              <div id="bovine-dropdown-menu" class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div
                    v-for="(bovineBreed, index) in bovineBreeds"
                    :key="`bovine-${index}`"
                    :class="breed.indexOf(bovineBreed) != -1 ? 'is-active' : ''"
                    class="dropdown-item is-flex is-align-items-center is-paddingless checkbox"
                  >
                    <input
                      :id="`bovine-${index}`"
                      v-model="breed"
                      type="checkbox"
                      class="mx-3"
                      :value="bovineBreed"
                    />
                    <label class="px-0" :for="`bovine-${index}`">{{
                      bovineBreed
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="dropdown equidae mr-2"
              :class="{ 'is-active': equidaeDropdownIsOpen }"
              @mouseleave="equidaeDropdownIsOpen = false"
            >
              <div
                class="dropdown-trigger px-3 py-2"
                @mouseenter="equidaeDropdownIsOpen = true"
              >
                <span
                  class="is-flex is-justify-content-space-between is-align-items-center is-flex-grow-1"
                  aria-haspopup="true"
                  aria-controls="equidae-dropdown-menu"
                >
                  <span class="has-text-weight-semibold">Equino</span>

                  <DropdownIcon />
                </span>
              </div>
              <div id="equidae-dropdown-menu" class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div
                    v-for="(equidaeBreed, index) in equidaeBreeds"
                    :key="`equidae-${index}`"
                    :class="
                      breed.indexOf(equidaeBreed) != -1 ? 'is-active' : ''
                    "
                    class="dropdown-item is-flex is-align-items-center is-paddingless checkbox"
                  >
                    <input
                      :id="`equidae-${index}`"
                      v-model="breed"
                      type="checkbox"
                      class="mx-3"
                      :value="equidaeBreed"
                    />
                    <label class="px-0" :for="`equidae-${index}`">{{
                      equidaeBreed
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="dropdown ovine mr-2"
              :class="{ 'is-active': ovineDropdownIsOpen }"
              @mouseleave="ovineDropdownIsOpen = false"
            >
              <div
                class="dropdown-trigger px-3 py-2"
                @mouseenter="ovineDropdownIsOpen = true"
              >
                <span
                  class="is-flex is-justify-content-space-between is-align-items-center is-flex-grow-1"
                  aria-haspopup="true"
                  aria-controls="ovine-dropdown-menu"
                >
                  <span class="has-text-weight-semibold">Outros</span>

                  <DropdownIcon />
                </span>
              </div>
              <div id="dropdown-menu" class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div
                    v-for="(ovineBreed, index) in ovineBreeds"
                    :key="`ovine-${index}`"
                    :class="breed.indexOf(ovineBreed) != -1 ? 'is-active' : ''"
                    class="dropdown-item is-flex is-align-items-center is-paddingless checkbox"
                  >
                    <input
                      :id="`ovine-${index}`"
                      v-model="breed"
                      type="checkbox"
                      class="mx-3"
                      :value="ovineBreed"
                    />
                    <label class="px-0" :for="`ovine-${index}`">{{
                      ovineBreed
                    }}</label>
                  </div>
                  <div
                    v-for="(caprineBreed, index) in caprineBreeds"
                    :key="`caprine-${index}`"
                    :class="
                      breed.indexOf(caprineBreed) != -1 ? 'is-active' : ''
                    "
                    class="dropdown-item is-flex is-align-items-center is-paddingless checkbox"
                  >
                    <input
                      :id="`caprine-${index}`"
                      v-model="breed"
                      type="checkbox"
                      class="mx-3"
                      :value="caprineBreed"
                    />
                    <label class="px-0" :for="`caprine-${index}`">{{
                      caprineBreed
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="input-component field state select-input">
          <label class="label" for="stateFilter">Estado:</label>
          <select id="stateFilter" v-model="state" class="select state">
            <option :value="[]" selected>Nenhum</option>
            <option
              v-for="(inputState, index) in inputStates"
              :key="`state-${index}`"
              :value="[index]"
            >
              {{ inputState }}
            </option>
          </select>
        </div>
        <button
          class="button outline is-borderless mt-2 ml-2 is-primary"
          :disabled="!isFiltered"
          @click.prevent="applyFilters"
        >
          Filtrar
        </button>
        <button
          class="button outline is-borderless mt-2 ml-2"
          :disabled="!isFiltered"
          @click.prevent="cleanFilters"
        >
          Limpar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AppInput from "components/ui/AppInput.vue";
import { useCrawledAuctions } from "hooks/useCrawledAuctions";
import useScrollPosition from "hooks/useScrollPosition";
import { ref, watch, toRefs, computed } from "vue";

// ICONS
import DropdownIcon from "icons/cached/arrow_drop_down.svg";

export default {
  components: {
    AppInput,
    DropdownIcon,
  },
  props: {
    bovineBreeds: Array,
    caprineBreeds: Array,
    equidaeBreeds: Array,
    ovineBreeds: Array,
    categories: Array,
    inputStates: Object,
    shippingType: Object,
  },
  setup() {
    const {
      page,
      auctions,
      filter,
      cleanFilters,
      isFiltered,
      applyFilters,
    } = useCrawledAuctions();

    const bovineDropdownIsOpen = ref(false);
    const caprineDropdownIsOpen = ref(false);
    const equidaeDropdownIsOpen = ref(false);
    const ovineDropdownIsOpen = ref(false);

    const { x, y } = useScrollPosition();

    const isFloat = ref(false);

    watch(y, () => {
      if (y.value > 464) {
        isFloat.value = true;
      } else {
        isFloat.value = false;
      }
    });

    const filterText = computed(() => {
      if (filter.breed.length && filter.breed.length > 4) {
        return (
          filter.breed.slice(0, 4).join(", ") +
          " e + " +
          (filter.breed.length - 4)
        );
      } else if (filter.breed.length) {
        return filter.breed.join(", ");
      }

      return "mostrando todas";
    });

    const handleDate = (value) => {
      filter.date = value;
    };

    watch(filter, () => {
      if (
        filter.date ||
        filter.state.length ||
        filter.breed.length ||
        filter.category.length ||
        filter.shipping.length
      ) {
        isFiltered.value = true;
      }
    });

    return {
      page,
      auctions,
      cleanFilters,
      handleDate,
      filterText,
      isFloat,
      bovineDropdownIsOpen,
      caprineDropdownIsOpen,
      equidaeDropdownIsOpen,
      ovineDropdownIsOpen,
      ...toRefs(filter),
      isFiltered,
      applyFilters,
    };
  },
};
</script>
