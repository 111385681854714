<template>
  <div class="filters">
    <div class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <ClassificationsIcon />
        <span class="has-text-weight-semibold">Classificação</span>
      </div>
      <div class="filter-body">
        <div class="checkbox">
          <input
            id="Reposição"
            v-model="classifications"
            type="checkbox"
            value="Reposição"
          />
          <label for="Reposição">Reposição</label>
        </div>
        <div class="checkbox">
          <input
            id="Genética"
            v-model="classifications"
            type="checkbox"
            value="Genética"
          />
          <label for="Genética">Genética</label>
        </div>
        <div v-if="genetic.length != 0" class="ml-4">
          <div class="checkbox">
            <input
              id="P.O."
              v-model="genetic"
              type="checkbox"
              value="P.O."
            />
            <label for="P.O.">Puro de origem - P.O.</label>
          </div>
          <div class="checkbox">
            <input
              id="P.A."
              v-model="genetic"
              type="checkbox"
              value="P.A."
            />
            <label for="P.A.">Puro por Avaliação - P.A.</label>
          </div>
          <div class="checkbox">
            <input
              id="P.C."
              v-model="genetic"
              type="checkbox"
              value="P.C."
            />
            <label for="P.C.">Puro por Cruzamento - P.C.</label>
          </div>
          <div class="checkbox">
            <input
              id="CEIP"
              v-model="genetic"
              type="checkbox"
              value="CEIP"
            />
            <label for="CEIP">Animal com CEIP</label>
          </div>
        </div>
      </div>
    </div>
    <div class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <CategoriesIcon />
        <span class="has-text-weight-semibold">Categoria</span>
      </div>
      <div class="filter-body">
        <div
          v-for="(category, index) in categoriesOptions"
          :key="index"
          class="checkbox"
        >
          <input
            :id="category"
            v-model="categories"
            type="checkbox"
            :value="category"
          />
          <label :for="category">{{ category }}</label>
        </div>
      </div>
    </div>
    <div class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <BreedsIcon />
        <span class="has-text-weight-semibold">Raças</span>
      </div>
      <div class="filter-body">
        <div
          v-for="(breed, index) in visibleBreeds"
          :key="index"
          class="checkbox"
        >
          <input :id="breed" v-model="breeds" type="checkbox" :value="breed" />
          <label :for="breed">{{ breed }}</label>
        </div>
        <button
          class="button is-outlined is-small is-primary mt-5"
          @click="expandedFilters.breeds = !expandedFilters.breeds"
        >
          {{ expandedFilters.breeds ? "Ver menos" : "Ver mais" }}
          <ChevronIcon :class="{ upside: expandedFilters.breeds }" />
        </button>
      </div>
    </div>
    <div v-if="userAdmin" class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <GeneticEvaluationIcon />
        <span class="has-text-weight-semibold">Avaliações Genéticas</span>
      </div>
      <div class="filter-body">
        <div class="has-text-weight-medium mb-2">
          <label for="has-zebu-index">iABCZ</label>
        </div>
        <FlashMessage
          message="Valor máximo do iABCZ não pode ser menor que o valor mínimo."
          :isOpen="showFlashMessage"
          :isSyncronized="true"
          @close="showFlashMessage = false"
        />
        <div class="mx-4 is-flex is-flex-direction-column is-align-content-center">
          <div class="is-flex">
            <input
              v-model="zebuIndexes.min"
              v-money="zebuIndexes.min != '' ? zebuIndexMask : ''"
              placeholder="Min."
              class="input mr-4"
            >
            <input
              v-model="zebuIndexes.max"
              v-money="zebuIndexes.max != '' ? zebuIndexMask : ''"
              placeholder="Máx."
              class="input"
            >
          </div>
          <button
            class="button is-outlined is-small is-primary mt-3"
            @click="filterOrClearZebuIndex"
          >
            {{ clearIndexes ? "Limpar Índice" : "Filtrar" }}
          </button>
        </div>
        <div class="has-text-weight-medium my-2">
          <label for="has-zebu-ranking">DECA</label>
        </div>
        <div class="filter is-flex is-flex-direction-column">
          <div v-for="(ranking, index) in visibleZebuRanking" :key="index" class="checkbox ml-4">
            <input :id="ranking" v-model="zebu_ranking" type="checkbox" :value="ranking">
            <label :for="ranking">{{ ranking }}</label>
          </div>
          <button
            class="button is-outlined is-small is-primary mt-5"
            @click="expandedFilters.zebuRanking = !expandedFilters.zebuRanking"
          >
            {{ expandedFilters.zebuRanking ? "Ver menos" : "Ver mais" }}
            <ChevronIcon :class="{ upside: expandedFilters.zebuRanking }" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="userAdmin" class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <AscendanciesIcon />
        <span class="has-text-weight-semibold">Ascendência</span>
      </div>
      <div class="filter-body is-flex is-flex-direction-column">
        <div class="filter-input mb-2">
          <input v-model="searchAscendancy" type="text" class="input" placeholder="Pesquise nos filtros">
        </div>
        <div v-for="(ascendancy, index) in visibleAscendancies" :key="index" class="checkbox">
          <input :id="ascendancy" v-model="ascendancies" type="checkbox" :value="ascendancy">
          <label :for="ascendancy">{{ ascendancy }}</label>
        </div>
        <button
          v-if="searchAscendancy == ''"
          class="button is-outlined is-small is-primary mt-5"
          @click="expandedFilters.ascendancies = !expandedFilters.ascendancies"
        >
          {{ expandedFilters.ascendancies ? "Ver menos" : "Ver mais" }}
          <ChevronIcon :class="{ upside: expandedFilters.ascendancies }" />
        </button>
      </div>
    </div>
    <div class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <AgesIcon />
        <span class="has-text-weight-semibold">Idade</span>
      </div>
      <div class="filter-body">
        <div v-for="(age, index) in visibleAges" :key="index" class="checkbox">
          <input :id="age" v-model="ages" type="checkbox" :value="age" />
          <label :for="age">{{ age }}</label>
        </div>
        <button
          class="button is-outlined is-small is-primary mt-5"
          @click="expandedFilters.ages = !expandedFilters.ages"
        >
          {{ expandedFilters.ages ? "Ver menos" : "Ver mais" }}
          <ChevronIcon :class="{ upside: expandedFilters.ages }" />
        </button>
      </div>
    </div>
    <div class="filter is-flex is-flex-direction-column">
      <div class="filter-header">
        <StatesIcon />
        <span class="has-text-weight-semibold">Estado</span>
      </div>
      <div class="filter-body">
        <div v-for="(value, key) in visibleStates" :key="key" class="checkbox">
          <input :id="key" v-model="states" type="checkbox" :value="key" />
          <label :for="key">{{ value }}</label>
        </div>
        <button
          class="button is-outlined is-small is-primary mt-5"
          @click="expandedFilters.states = !expandedFilters.states"
        >
          {{ expandedFilters.states ? "Ver menos" : "Ver mais" }}
          <ChevronIcon :class="{ upside: expandedFilters.states }" />
        </button>
      </div>
    </div>
    <button class="button is-fullwidth" @click.prevent="clearState">
      Limpar filtros
    </button>
  </div>
</template>

<script>
import BreedsIcon from "icons/cached/dna.svg";
import CategoriesIcon from "icons/cached/inventory.svg";
import AgesIcon from "icons/cached/signal_cellular_alt.svg";
import ClassificationsIcon from "icons/cached/category.svg";
import StatesIcon from "icons/cached/location_on.svg";
import ChevronIcon from "icons/cached/chevron_left.svg";
import GeneticEvaluationIcon from "icons/cached/military_tech.svg";
import AscendanciesIcon from "icons/cached/bovine.svg"

import { findMatching } from 'utils/matchingString'
import FlashMessage from "components/ui/FlashMessage.vue";

import { filtersState, clearState } from "./filtersState";
import { VMoney } from "v-money";
import { computed, reactive, ref, toRefs, watch } from "vue";

export default {
  directives: {
    money: VMoney,
  },
  components: {
    ClassificationsIcon,
    AgesIcon,
    CategoriesIcon,
    BreedsIcon,
    StatesIcon,
    ChevronIcon,
    GeneticEvaluationIcon,
    AscendanciesIcon,
    FlashMessage,
  },
  props: {
    categoriesOptions: Array,
    zebuRankingOptions: Array,
    breedsOptions: Array,
    agesOptions: Array,
    statesOptions: Object,
    ascendanciesOptions: Array,
    userAdmin: Boolean,
  },
  setup(props) {
    const { categories, breeds, ages, classifications, genetic, states, zebu_ranking,
        zebu_index_min, zebu_index_max, ascendancies, } =
      toRefs(filtersState);

    const expandedFilters = reactive({
      breeds: false,
      ages: false,
      states: false,
      zebuRanking: false,
      ascendancies: false,
    });

    watch([breeds, ages, states, zebu_ranking, ascendancies], ()=> {
      if (breeds.value.length > 4)
        expandedFilters.breeds = true;
      if (ages.value.length > 4)
        expandedFilters.ages = true;
      if (states.value.length > 4)
        expandedFilters.states = true;
      if (zebu_ranking.value.length > 4)
        expandedFilters.zebuRanking = true;
      if (ascendancies.value.length > 4)
        expandedFilters.ascendancies = true;
    })

    const visibleBreeds = computed(() =>
      expandedFilters.breeds
        ? props.breedsOptions
        : props.breedsOptions.slice(0, 4),
    );

    const visibleAges = computed(() =>
      expandedFilters.ages ? props.agesOptions : props.agesOptions.slice(0, 4),
    );

    const visibleStates = computed(() =>
      expandedFilters.states
        ? props.statesOptions
        : Object.keys(props.statesOptions)
            .slice(0, 4)
            .reduce((result, key) => {
              result[key] = props.statesOptions[key];
              return result;
            }, {}),
    );

    const visibleZebuRanking = computed(() =>
      expandedFilters.zebuRanking ? props.zebuRankingOptions : props.zebuRankingOptions.slice(0, 4),
    );

    const visibleAscendancies = computed(() =>
      searchAscendancy.value != ""
        ? findMatching(searchAscendancy.value, props.ascendanciesOptions)
        : showAscendanciesOptions.value,
    );

    const showAscendanciesOptions = computed(() =>
      expandedFilters.ascendancies ? props.ascendanciesOptions : props.ascendanciesOptions.slice(0, 4),
    );

    const showFlashMessage = ref(false);
    const zebuIndexes = ref({min: "", max: ""});
    const clearIndexes = ref(false);
    const searchAscendancy = ref("");

    const zebuIndexMask = computed(() => {
      return {
        decimal: ',',
        thousands: '',
        prefix: '',
        precision: 2,
        masked: false,
      }
    });

    const filterOrClearZebuIndex = () => {
      if (zebuIndexes.value.min != "" && zebuIndexes.value.max != "" && !clearIndexes.value) {
        if(parseFloat(zebuIndexes.value.max.replace(',', '.')) < parseFloat(zebuIndexes.value.min.replace(',', '.'))) {
          return showFlashMessage.value = true;
        }
      };

      if(clearIndexes.value) {
          zebuIndexes.value.min = "";
          zebuIndexes.value.max = "";
      };

      clearIndexes.value = !clearIndexes.value;
      showFlashMessage.value = false;
      zebu_index_min.value = zebuIndexes.value.min;
      zebu_index_max.value = zebuIndexes.value.max;
    };

    watch(classifications, ()=>{
      let search = Object.values(classifications.value)

      if (search.includes('Genética')){
        if (genetic.value.length == 0)
          genetic.value = ['P.O.', 'P.A.', 'P.C.', 'CEIP'];
      } else if (genetic.value.length != 0) {
        genetic.value = [];
      }
    });

    watch(genetic, ()=>{
      if (genetic.value.length == 0){
        let search = Object.values(classifications.value);
        search = search.filter(function(item) {
            return item !== 'Genética';
        })
        classifications.value = search;
      }
    });

    watch([zebu_index_min, zebu_index_max], () => {
      zebuIndexes.value.min = zebu_index_min.value;
      zebuIndexes.value.max = zebu_index_max.value;
    });

    watch(zebuIndexes.value, () => {
      clearIndexes.value = false;
    });

    return {
      categories,
      breeds,
      ages,
      classifications,
      genetic,
      states,
      zebuIndexes,
      zebu_ranking,
      ascendancies,
      expandedFilters,
      clearState,
      visibleBreeds,
      visibleAges,
      visibleStates,
      visibleZebuRanking,
      visibleAscendancies,
      searchAscendancy,
      zebuIndexMask,
      clearIndexes,
      showFlashMessage,
      filterOrClearZebuIndex,
    };
  },
};
</script>
