<template>
  <div
    class="ideal-bull-container"
    :style="{ backgroundImage: chooseBackground }"
  >
    <div class="ideal-bull-body" :class="{ 'start-quiz': startQuestion }">
      <transition name="start">
        <div
          v-if="showHeader"
          class="ideal-bull-header"
          :class="{ start: startQuiz && !isMobile }"
        >
          <transition name="start">
            <ArrowBackIcon
              v-if="startQuestion"
              class="arrow-back"
              @click="prevQuest()"
            />
          </transition>
          <div class="title" :class="{ start: startQuiz }">
            <div class="is-flex"><StarIcon /></div>
            <span>Touro Ideal</span>
          </div>
        </div>
      </transition>
      <transition name="start">
        <div
          v-if="showStart"
          class="ideal-bull-description has-text-weight-semibold"
        >
          <span class="is-size-5">
            Descubra qual é o touro ideal <br />
            para sua criação de bovinos
          </span>
          <button class="button is-warning ideal-bull-btn" @click="start()">
            Encontrar touro ideal
          </button>
        </div>
      </transition>
      <transition name="start">
        <figure v-if="showStart" class="image ideal-bull-image">
          <img :src="chooseMainImg" />
        </figure>
      </transition>
      <transition name="start-quiz">
        <div
          v-if="startQuestion"
          class="question-field"
          :class="{ finish: !startQuiz }"
        >
          <QuizField :name="name" :email="email" :phone="phone" />
        </div>
      </transition>
      <transition name="result">
        <div v-if="showResult" class="final-screen">
          <Result :responsible="responsible" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, toRefs, onMounted } from "vue";
import {
  quizState,
  sendChatbotAnswer,
  conversionData,
  getRandomResponsible,
} from "./questions.js";
import { lp_conversions_create_path } from "utils/routes";
import { useToast } from "vue-toastification";
import api from "services/api";
import useWindowSizes from "hooks/useWindowSizes";

import QuizField from "./QuizField.vue";
import Result from "./Result.vue";

import StarIcon from "icons/cached/star_mark.svg";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import mainImg from "images/ideal_bull/touro_ideal_main.png";
import mobileMainImg from "images/ideal_bull/touro_ideal_main_mobile.png";
import backImg from "images/ideal_bull/back_image_ideal_bull.png";
import mobileBackImg from "images/ideal_bull/back_img_ideal_bull_mobile.png";

export default {
  components: {
    StarIcon,
    ArrowBackIcon,
    QuizField,
    Result,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    lpData: Object,
  },
  setup(props) {
    const { isMobile } = useWindowSizes();
    const {
      answers,
      finishedQuiz,
      knownCustomer,
      newCustomer,
      currentQuestion,
    } = toRefs(quizState);
    const startQuiz = ref(false);
    const startQuestion = ref(false);
    const showStart = ref(true);
    const showHeader = ref(true);
    const showResult = ref(false);
    const responsible = ref({});

    const toast = useToast();

    const chooseBackground = computed(() => {
      if (isMobile.value) {
        return `url(${mobileBackImg})`;
      } else {
        return `url(${backImg})`;
      }
    });

    const chooseMainImg = computed(() => {
      if (isMobile.value) {
        return mobileMainImg;
      } else {
        return mainImg;
      }
    });

    const start = async () => {
      if (props.email) {
        newCustomer.value["name"] = props.name;
        newCustomer.value["email"] = props.email;
        newCustomer.value["phone"] = props.phone;

        api
          .post(
            lp_conversions_create_path(),
            conversionData(
              props.lpData,
              props.name,
              props.email,
              props.phone,
              true,
            ),
          )
          .then(() => {
            knownCustomer.value = true;
          });
      }
      startQuiz.value = true;
      showStart.value = false;

      window.scrollTo(0, 0);

      setTimeout(() => {
        startQuestion.value = true;
      }, 1600);
    };

    const prevQuest = () => {
      if (currentQuestion.value - 1 > 0) {
        if (!knownCustomer.value) {
          answers.value.pop();
        }
        currentQuestion.value--;
      }
    };

    const getResponsible = () => {
      getRandomResponsible(newCustomer.value["email"])
        .then((resp) => {
          responsible.value = resp.data;
          startQuiz.value = false;
          showHeader.value = false;
          setTimeout(() => {
            startQuestion.value = false;
          }, 1200);
          setTimeout(() => {
            showResult.value = true;
          }, 2200);
        })
        .catch(() => {
          toast.error("Erro ao selecionar vendedor");
        });
    };

    watch(finishedQuiz, () => {
      if (finishedQuiz.value) {
        if (!knownCustomer.value) {
          formatAnswers()
            .then(() => {
              sendChatbotAnswer(answers.value, newCustomer.value["email"]);
              getResponsible();
            })
            .catch(() => {
              toast.error("Erro ao criar conversão");
            });
        } else {
          getResponsible();
        }
      }
    });

    const formatAnswers = () => {
      let name = newCustomer.value["name"];
      let email = newCustomer.value["email"];
      let phone = newCustomer.value["phone"];

      return api.post(
        lp_conversions_create_path(),
        conversionData(props.lpData, name, email, phone, false),
      );
    };

    return {
      chooseBackground,
      chooseMainImg,
      startQuiz,
      startQuestion,
      finishedQuiz,
      showHeader,
      showResult,
      showStart,
      responsible,
      isMobile,
      start,
      prevQuest,
    };
  },
};
</script>
