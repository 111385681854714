<template>
  <section
    v-if="noRecommendations"
    class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered mt-4 no-recommendations is-relative"
  >
    <img :src="noRecommendationsImage" alt="Sem recomendações" />

    <div class="text-area">
      <h3 class="has-text-weight-semibold my-3">
        Sem recomendações no momento :(
      </h3>
      <p>Em breve novos animais com suas preferências de compra!</p>
      <p class="mb-2 mt-5">Descubra outros animais disponíveis:</p>

      <a
        :href="selections_index_url()"
        class="button is-warning is-fullwidth"
        id="seeSelections"
      >
        Ver seleções erural <VerifiedIcon class="ml-2" />
      </a>
    </div>
  </section>

  <section v-else>
    <div class="is-flex is-align-items-center title-area mt-4">
      <RecommendFilledIcon class="thumb-up-icon mr-2" />

      <div>
        <h1 class="has-text-weight-bold">RECOMENDAÇÕES ERURAL</h1>
        <h3>Separamos os melhores lotes para você</h3>
      </div>
    </div>

    <div class="selection-page pb-0" v-if="batches">
      <div class="cards-container">
        <SelectionCard
          v-for="(batch, i) in batches"
          :key="i"
          :batch="batch"
          page="Personalized Recommendations"
          section="Client Menu"
        />
      </div>
    </div>
    <div
      class="products-container columns is-1-touch is-2-desktop is-variable is-mobile is-multiline mb-6"
      :class="{ 'mt-4': !batches }"
      v-if="semenAndEmbryoBatches"
    >
      <ProductCard
        v-for="(batch, i) in semenAndEmbryoBatches"
        :key="i"
        :batch="batch"
        :classes="['is-6-mobile', 'is-4-widescreen', 'is-6-tablet']"
        page="Personalized Recommendations"
        section="Client Menu"
      />
    </div>
  </section>
</template>

<script>
import SelectionCard from "../selection/SelectionCard.vue";
import ProductCard from "components/product/ProductCard.vue";

import RecommendFilledIcon from "icons/cached/recommend_filled.svg";
import VerifiedIcon from "icons/cached/verified.svg";
import noRecommendationsImage from "images/no_recommendations.png";
import { selections_index_url } from "utils/routes";
import { onMounted } from "vue";

export default {
  components: {
    SelectionCard,
    ProductCard,
    RecommendFilledIcon,
    VerifiedIcon,
  },
  props: {
    batches: {
      type: Array,
      default: [],
    },
    semenAndEmbryoBatches: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const noRecommendations = !(
      props.batches?.length > 0 || props.semenAndEmbryoBatches?.length > 0
    );

    onMounted(() => {
      mixpanel.track_links(
        "#seeSelections",
        "Personalized Recommendations - See Selections Clicked",
      );
    });

    return { noRecommendations, noRecommendationsImage, selections_index_url };
  },
};
</script>

<style></style>
