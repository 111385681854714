<template>
  <div class="header is-relative is-flex">
    <div
      class="container is-flex is-align-items-center is-justify-content-center"
    >
      <ArrowBackIcon class="is-clickable" @click="onGoBack" />
      {{ title }}
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import { user_profile_url } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    onGoBack: {
      type: Function,
      default: () => (window.location.href = user_profile_url()),
    },
  },
  setup() {},
};
</script>

<style></style>
