<template>
  <div class="batch-card">
    <div class="banner">
      <img :src="state.batch.cover_url" :alt="state.batch.title" />
    </div>
    <div class="content">
      <span class="title">{{ state.batch.title }}</span>
    </div>
  </div>

  <div
    v-if="state.currentFlow == 'bid' && state.activeTab == 'confirm'"
    class="batch-card mt-3"
  >
    <div class="content bid-resume">
      <span class="m-3">
        <span class="bid-description">Valor do lance:</span>
        {{ state.batch.installments }}x
        {{ numberToCurrency(state.preBid.bid_price) }}
        <span
          v-if="state.batch.animals_count > 1"
          class="dose-text"
        >
          / animal
        </span>
      </span>
      <span class="mx-3">
        <span class="bid-description">Valor total:</span>
        {{ numberToCurrency(state.preBid.batch_price) }}
      </span>
      <span class="m-3">
        <span class="bid-description">CPF do lançador:</span>
        {{ state.currentTrackedUser.cpf }}
      </span>
    </div>
  </div>
</template>

<script>
import { state } from "./auctionRegistrationModalContext";
import { numberToCurrency } from "utils/currencyConverter";

export default {
  setup() {
    return {
      state,
      numberToCurrency,
    };
  },
};
</script>
