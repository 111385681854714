<template>
  <div
    class="
      columns
      is-mobile is-multiline is-centered
      crawled-featured-auction-content
    "
  >
    <FeaturedAuctionCard
      v-for="auction in visibleAuctions"
      :key="auction.id"
      :auction="auction"
      page="Home"
      section="Featured Events"
      classes="column is-narrow custom-padding custom-size-card"
    />
  </div>

  <div
    v-if="auctions.length > 4"
    class="has-text-centered mt-2 is-hidden-tablet"
  >
    <button class="button auctions-expand" @click="seeMoreAndTrack()">
      <span v-if="!open">Ver mais eventos <ExpandMoreIcon /></span>
      <span v-else>Ver menos eventos <ExpandLessIcon /></span>
    </button>
  </div>
</template>

<script>
import FeaturedAuctionCard from "components/auction/FeaturedAuctionCard.vue";

import ExpandMoreIcon from "icons/cached/expand_more.svg";
import ExpandLessIcon from "icons/cached/expand_less.svg";

import useWindowSizes from "hooks/useWindowSizes";

import { ref, computed } from "vue";

export default {
  components: {
    FeaturedAuctionCard,
    ExpandMoreIcon,
    ExpandLessIcon,
  },
  props: {
    auctions: Object,
    link: String,
  },
  setup(props) {
    const open = ref(false);

    const { isMobile } = useWindowSizes();

    const seeMoreAndTrack = () => {
      open.value = !open.value;

      if (open.value) {
        mixpanel.track("Home - See More Clicked", {
          Section: "Featured Auctions",
        });
      }
    };

    const visibleAuctions = computed(() => {
      if (isMobile.value) {
        return open.value ? props.auctions : props.auctions.slice(0, 4);
      } else {
        return props.auctions;
      }
    });

    return {
      open,
      visibleAuctions,
      seeMoreAndTrack,
    };
  },
};
</script>
