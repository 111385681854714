<template>
  <div class="search-page columns">
    <div
      v-if="mobileFiltersOpen"
      class="overlay"
      @click.prevent="mobileFiltersOpen = false"
    />
    <button
      class="open-mobile-filters button is-hidden-tablet my-4"
      @click="mobileFiltersOpen = true"
    >
      Filtre sua busca
    </button>
    <div class="filters-container column is-3 is-hidden-mobile">
      <DesktopFilters
        :categoriesOptions="categories"
        :zebuRankingOptions="zebuRanking"
        :ascendanciesOptions="ascendancies"
        :breedsOptions="breeds"
        :agesOptions="ages"
        :statesOptions="states"
        :userAdmin="userAdmin"
      />
    </div>
    <div class="items-container column">
      <div
        v-if="!loading"
        class="
          search-infos
          is-flex is-justify-content-space-between is-align-items-center
        "
      >
        <div class="is-flex is-flex-direction-column">
          <div v-if="!!query">
            <span class="is-size-6 has-text-weight-semibold">
              Buscando por "{{ query }}"
            </span>
            <span class="is-size-6 has-text-weight-semibold is-hidden-touch">
              + filtros
            </span>
          </div>

          <span class="is-size-7 has-text-weight-semibold grey-text">
            Achamos {{ totalBatches }} lotes
          </span>
        </div>
        <button
          class="button is-small is-hidden-mobile"
          @click.prevent="clearState"
        >
          Limpar filtros
        </button>
      </div>

      <div
        class="
          products-container
          columns
          is-1-touch is-2-desktop is-variable is-mobile is-multiline
          mt-4
        "
        :class="{ 'is-justify-content-center': loading }"
      >
        <div v-if="loading" class="is-loading loader" />

        <ProductCard
          v-for="(batch, i) in batches"
          v-else-if="totalBatches > 0"
          :key="i"
          :batch="batch"
          :classes="['is-6-mobile', 'is-4-widescreen', 'is-6-tablet']"
          section="Search Results"
          page="Search"
        />

        <div v-else class="column empty-results">
          <figure class="image">
            <img
              :src="chicoSadImg"
              alt="Chico triste"
            />
          </figure>

          <p class="message">
            Produtor, não encontrei nenhum lote com essas características.
            <span class="is-hidden-desktop">
              Melhore sua busca com o botão
              <b>Filtre sua busca</b> acima.
            </span>
            <span class="is-hidden-touch">
              <br />Melhore sua busca com os filtros ao lado.
            </span>
          </p>
        </div>
      </div>

      <div>
        <Pagination
          v-show="!loading && totalBatches > 0"
          :siblingCount="isTouch ? 5 : 9"
          :totalPages="totalPages"
          :currentPage="page"
          :customHandler="true"
          @changePage="(newPage) => (page = newPage)"
        />
      </div>
    </div>

    <div class="is-hidden-desktop mobile-filters-modal">
      <MobileFilters
        :isOpen="mobileFiltersOpen"
        :categoriesOptions="categories"
        :breedsOptions="breeds"
        :agesOptions="ages"
        :zebuRankingOptions="zebuRanking"
        :ascendanciesOptions="ascendancies"
        :statesOptions="states"
        :userAdmin="userAdmin"
        @close="mobileFiltersOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, toRefs } from "vue";
import useWindowSizes from "hooks/useWindowSizes";
import { batches, totalBatches, fillBatches } from "./useBatches";
import {
  filtersState,
  fillStateFromUrl,
  totalPages,
  clearState,
  loading,
} from "./filtersState";

import Pagination from "components/ui/Pagination.vue";

import DesktopFilters from "./DesktopFilters.vue";
import MobileFilters from "./MobileFilters.vue";
import ProductCard from "components/product/ProductCard.vue";

import chicoSadImg from "images/chico/chico_sad_face.png";

export default {
  components: {
    Pagination,
    DesktopFilters,
    MobileFilters,
    ProductCard,
  },
  props: {
    categories: Array,
    zebuRanking: Array,
    ascendancies: Array,
    breeds: Array,
    ages: Array,
    states: Object,
    initialBatches: Object,
    userAdmin: Boolean,
  },
  setup(props) {
    const { isTouch } = useWindowSizes();
    const mobileFiltersOpen = ref(false);
    const { query, page } = toRefs(filtersState);

    onMounted(() => {
      fillStateFromUrl();
      fillBatches(
        props.initialBatches.batches,
        props.initialBatches.total_results,
      );
      totalPages.value = props.initialBatches.total_pages;
    });

    const eventsCount = computed(() => {
      let filteredBatches = batches.value.map((b) => b.auction_title);

      return filteredBatches.filter(
        (value, index, self) => self.indexOf(value) === index,
      ).length;
    });

    return {
      mobileFiltersOpen,
      batches,
      totalBatches,
      query,
      clearState,
      eventsCount,
      page,
      totalPages,
      loading,
      isTouch,
      chicoSadImg,
    };
  },
};
</script>

<style></style>
