<template>
  <div
    class="tag choice-tag is-clickable is-rounded"
    :class="{ selected, unique }"
  >
    <div
      v-if="!selected && !unique"
      class="add-icon is-flex is-align-items-center is-justify-content-center mr-1"
    >
      <AddIcon />
    </div>
    <p>{{ text }}</p>
    <CheckIcon v-if="selected && !unique" class="check-icon ml-1" />
    <CheckCircleIcon v-if="selected && unique" class="check-icon ml-1 is-green" />
  </div>
</template>

<script>
import AddIcon from "icons/cached/add.svg";
import CheckIcon from "icons/cached/check.svg";
import CheckCircleIcon from "icons/cached/check_circle.svg";

export default {
  components: {
    AddIcon,
    CheckIcon,
    CheckCircleIcon,
  },
  props: {
    selected: Boolean,
    text: String,
    unique: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
