import { reactive, ref } from "vue";

const preRegistrationState = reactive({
  conversion: null,
  openRegistrationModal: false,
});

const updatePreRegistrationState = (userObj) => {
  for (let [key, value] of Object.entries(userObj)) {
    preRegistrationState[key] = value;
  }
};

export {
  preRegistrationState,
  updatePreRegistrationState,
};
