import { ref, computed, onMounted, onUnmounted } from "vue";

const useWindowSizes = () => {
  const width  = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  const isMobile = computed(() => width.value < 768);
  const isTablet = computed(() => width.value >= 768 && width.value < 1024);
  const isTouch = computed(() => width.value < 1024);
  const isDesktop = computed(() => width.value >= 1024);
  const isDesktopOnly = computed(() => width.value >= 1024 && width.value < 1344);
  const isWidescreen = computed(() => width.value >= 1344);

  const handleResize = () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  }

  onMounted(() => window.addEventListener('resize', handleResize));
  onUnmounted(() => window.removeEventListener('resize', handleResize));

  return {
    width,
    height,
    isMobile,
    isTablet,
    isTouch,
    isDesktop,
    isDesktopOnly,
    isWidescreen
  }
};

export default useWindowSizes;
