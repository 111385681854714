<template>
  <div class="auction-conditions-container">
    <div
      v-if="payment"
      class="auction-conditions"
      @click="openAndTrackPayment()"
    >
      <div class="block has-text-weight-medium is-flex">
        <div class="is-flex">
          <MonetizationIcon class="mr-3" />
          <span class="payment">{{ payment }}</span>
        </div>
        <ChevronRightIcon class="arrow-icon is-hidden-desktop" />
      </div>
    </div>

    <div
      v-if="shipping"
      class="auction-conditions"
      @click="openAndTrackShipping()"
    >
      <div class="block has-text-weight-medium is-flex">
        <div class="is-flex">
          <LocalShippingIcon class="mr-3" />
          <span class="shipping">
            {{ shipping }}
          </span>
        </div>
        <ChevronRightIcon class="arrow-icon is-hidden-desktop" />
      </div>
    </div>

    <AuctionPaymentModal
      :open="openPaymentModal"
      :payment="payment"
      :paymentExplanation="paymentExplanation"
      @close="() => (openPaymentModal = false)"
    />

    <AuctionShippingModal
      :open="openShippingModal"
      :shipping="shipping"
      :shippingExplanation="shippingExplanation"
      @close="() => (openShippingModal = false)"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import MonetizationIcon from "icons/cached/monetization_on.svg";
import ChevronRightIcon from "icons/cached/chevron_right.svg";
import LocalShippingIcon from "icons/cached/local_shipping.svg";
import AuctionPaymentModal from "./AuctionPaymentModal.vue";
import AuctionShippingModal from "./AuctionShippingModal.vue";

export default {
  components: {
    MonetizationIcon,
    ChevronRightIcon,
    LocalShippingIcon,
    AuctionPaymentModal,
    AuctionShippingModal,
  },
  props: {
    payment: String,
    paymentExplanation: String,
    shipping: String,
    shippingExplanation: String,
    mixpanelData: Object
  },
  setup(props) {
    const openPaymentModal = ref(false);
    const openShippingModal = ref(false);

    const openAndTrackPayment = () => {
      openPaymentModal.value = true
      mixpanel.track('Payment Info Clicked', { 'Page': 'Event', ...props.mixpanelData });
    }

    const openAndTrackShipping = () => {
      openShippingModal.value = true
      mixpanel.track('Shipping Info Clicked', { 'Page': 'Event', ...props.mixpanelData });
    }

    return {
      openPaymentModal,
      openShippingModal,
      openAndTrackPayment,
      openAndTrackShipping,
    };
  },
};
</script>
