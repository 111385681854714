<template>
  <AppInput
    label="Seu nome"
    name="contact[name]"
    placeholder="Escreva aqui"
    :required="true"
    :small="true"
    :isHorizontal="true"
  />
  <AppInput
    ref="emailInput"
    label="Seu email"
    name="contact[email]"
    type="email"
    placeholder="Escreva aqui"
    :value="contactEmail"
    :error="emailInputError"
    :required="true"
    :small="true"
    :isHorizontal="true"
    @input="(v) => (contactEmail = v)"
  />
  <AppInput
    v-mask="['(##) # ####-####', '(##) ####-####']"
    label="Seu telefone"
    name="contact[phone]"
    placeholder="Escreva aqui"
    :required="true"
    :isSynchronized="true"
    :small="true"
    :isHorizontal="true"
  />
  <div class="select-input select input-component">
    <label for="reasonSelect">Qual o motivo?</label>
    <div class="select-container">
      <select id="reasonSelect" name="contact[type_message]" required>
        <option value="" disabled selected>Selecione</option>
        <option value="Problemas com conta">Problemas com conta</option>
        <option value="Problemas com login">Problemas com acesso</option>
        <option value="Dúvida">Dúvida</option>
        <option value="Outros">Outros</option>
      </select>
    </div>
  </div>
  <div class="text-input">
    <label class="mr-2">Sua mensagem</label>
    <textarea
      class="textarea"
      name="contact[message]"
      placeholder="Escreva aqui"
      required
    />
  </div>
  <div class="mt-5 mr-4">
    <button class="button is-primary" type="submit">Enviar mensagem</button>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import AppInput from "components/ui/AppInput.vue";
import { mask } from "vue-the-mask";
export default {
  components: { AppInput },
  directives: { mask },

  setup() {
    const contactEmail = ref("");
    const emailInput = ref(null);
    const emailInputError = ref(false);

    watch(contactEmail, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    });

    return {
      contactEmail,
      emailInput,
      emailInputError,
    };
  },
};
</script>

<style></style>
