<template>
  <div class="column is-7-desktop is-12-mobile auth-container is-relative">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('policies')"
      />
      <span class="title is-size-7 mb-5">Leia nossos Termos e Políticas</span>
      <div class="select-button is-flex mb-5">
        <div class="left is-active">
          Termos de uso
        </div>
        <div class="right" @click="updateActiveAuthStep('privacy')">
          pol. de privacidade
        </div>
      </div>
      <p class="description">Termos de Uso</p>
      <div class="content terms">
        <h6>Bem-vindo ao erural !</h6>

        <p>Agradecemos por usar nossos produtos e serviços fornecidos pela E-RURAL ATIVIDADES DE INTERNET LTDA (“erural”), localizado na cidade de São Paulo, Estado de São Paulo, na Rua Manuel da Nóbrega, n.º 1.280, térreo, sala 1, Paraíso, CEP 04.001-004.</p>

        <p>Ao usar nossos Serviços, você está compreendendo e concordando com nossos termos. Leia-os com atenção. A seguir, estão os termos e condições gerais de uso do site. São cláusulas que destacam a viabilidade da contratação e as limitativas de direitos, aqui destacadas:</p>

        <h6>COMO USAR NOSSOS SERVIÇOS</h6>

        <p>1) É preciso que você siga as políticas disponibilizadas a você dentro dos Serviços.</p>

        <p>2) Não faça uso indevido de nossos Serviços. Por exemplo, não interfira com nossos Serviços nem tente acessá-los por um método diferente da interface e das instruções que fornecemos. Você pode usar nossos serviços somente conforme permitido por lei, inclusive leis e regulamentos de controle de exportação e reexportação. Podemos suspender ou deixar de fornecer nossos Serviços se você descumprir nossos termos ou políticas ou se estivermos investigando casos de suspeita de má conduta.</p>

        <p>
          3) O uso de nossos Serviços não lhe confere a propriedade sobre direitos de propriedade intelectual sobre os nossos Serviços ou sobre o conteúdo que você acessar. Você não pode usar conteúdos de nossos Serviços a menos que obtenha permissão do proprietário de tais conteúdos ou que o faça por algum meio permitido por lei. Estes termos não conferem a você o direito de usar quaisquer marcas ou logotipos utilizados em nossos Serviços. Não remova, oculte ou altere quaisquer avisos legais exibidos em ou junto a nossos Serviços.
          4) Nossos Serviços, como o classificados, exibem alguns conteúdos que não são do <span class="has-text-weight-bold"><i>erural</i></span>. Esses conteúdos são de exclusiva responsabilidade da entidade que os publica. Podemos revisar conteúdo para determinar se é ilegal ou se infringe nossas políticas, e podemos remover ou nos recusar a exibir conteúdos que razoavelmente acreditamos violar nossas políticas ou a lei. <span class="has-text-weight-bold">Mas isso não significa, necessariamente, que revisaremos todos os conteúdos, portanto, por favor, não presuma que o faremos.</span>
        </p>

        <p>5) Em relação com seu uso dos Serviços, podemos enviar-lhe anúncios de serviços, mensagens administrativas e outras informações. Você pode desativar uma parte dessas comunicações através da sessão “minha conta” ou enviando-nos um email para contato@erural.net.</p>

        <h6>SUA CONTA DO ERURAL</h6>

        <p>1) Talvez você precise criar uma conta no <span class="has-text-weight-bold"><i>erural</i></span> para utilizar alguns dos nossos Serviços.</p>

        <p>2) Para proteger sua conta, o usuário deve manter a senha em sigilo. A atividade realizada na Conta do <span class="has-text-weight-bold"><i>erural</i></span> ou por seu intermédio é de responsabilidade do usuário. Caso tome conhecimento de uso não autorizado da sua senha ou conta, o usuário deve entrar em contato com o suporte de nossa empresa, enviando um e-mail para contato@erural.net, informando o problema.</p>

        <h6>PROTEÇÃO À PRIVACIDADE E AOS DIREITOS AUTORAIS</h6>

        <p>1) As Políticas de Privacidade do <span class="has-text-weight-bold"><i>erural</i></span> explicam o modo como tratamos seus dados pessoais e protegemos sua privacidade quando você usa nossos Serviços. Ao utilizar nossos Serviços, você concorda que poderemos usar esses dados de acordo com nossas políticas de privacidade.</p>

        <h6>SEU CONTEÚDO EM NOSSOS SERVIÇOS</h6>

        <p>1) Alguns de nossos Serviços permitem que você envie conteúdo. Você mantém a propriedade de quaisquer direitos de propriedade intelectual que você detenha sobre aquele conteúdo. Em resumo, aquilo que pertence a você, permanece com você.</p>

        <p>2) Os conteúdos que permitimos você enviar são limitados e estabelecidos por nós, E-RURAL ATIVIDADES DE INTERNET LTDA (“erural”). Hoje, é permitido anúncios nas seguintes categorias: Bovinos; Equídeos; Caprinos; Ovinos e Propriedades relacionadas à pecuária. Anúncios que não se enquadrem nas categorias listadas, estão expressamente proibidos. Isso não quer dizer que anúncios dentro das categorias permitidas serão sempre possíveis. Reservamos o direito de, a qualquer tempo, sem aviso prévio, excluir qualquer tipo de anúncio de nossa plataforma, a fim de garantir a qualidade e legitimidade desejada.</p>

        <p>3) Alguns de nossos serviços são pagos, como é o exemplo dos Planos PRO e Lojas Oficiais. Só contrate nossos serviços pagos se estiver ciente e concordar com todos os nossos termos. Se algum serviço pago for contratado para anúncios fora das categorias permitidas, além de termos o direito de excluir o anúncio, sem aviso prévio, não temos a obrigação de ressarcir o valor pago. Temos custos com estes anúncios, por favor, não tente burlar nossas regras e condições.</p>

        <p>4) Quando você faz upload ou de algum modo envia conteúdo a nossos Serviços, você concede ao <span class="has-text-weight-bold"><i>erural</i></span> (e àqueles com quem trabalhamos) uma licença mundial para usar, hospedar, armazenar, reproduzir, modificar, criar obras derivadas, comunicar, publicar, executar e exibir publicamente e distribuir tal conteúdo. Os direitos que você concede nesta licença são para os fins restritos de operação, promoção e melhoria de nossos Serviços e de desenvolver novos Serviços. Essa licença perdura mesmo que você deixe de usar nossos Serviços. Certifique-se de que você tem os direitos necessários para nos conceder a licença de qualquer conteúdo que você enviar a nossos Serviços.</p>

        <p>5) Se o usuário tiver uma Conta do <span class="has-text-weight-bold"><i>erural</i></span>, o nome, bem como as ações realizadas em aplicativos, poderão aparecer em nossos Serviços, inclusive para exibição em anúncios e em outros contextos comerciais.</p>

        <p>6) Você pode encontrar mais informações sobre como a empresa <span class="has-text-weight-bold"><i>erural</i></span> usa e armazena conteúdo na política de privacidade ou termos adicionais de Serviços específicos.</p>

        <h6>SOBRE SOFTWARE EM NOSSOS SERVIÇOS</h6>

        <p>1) Quando um Serviço exige ou inclui software disponível para download, tal software poderá atualizar-se automaticamente em seu dispositivo se uma nova versão ou recurso estiver disponível. Alguns Serviços podem permitir que você ajuste suas configurações de atualizações automáticas.</p>

        <p>2) A <span class="has-text-weight-bold"><i>erural</i></span> concede a você uma licença pessoal, mundial, não exclusiva, intransferível e isenta de royalties para o uso do software fornecido pela erural como parte dos Serviços. Essa licença tem como único objetivo permitir que você use e aproveite o benefício dos Serviços da forma permitida por estes termos. Você não poderá copiar, modificar, distribuir, vender ou alugar qualquer parte de nossos Serviços ou o software incluso, nem poderá fazer engenharia reversa ou tentar extrair o código fonte desse software, exceto nos casos em que a legislação proibir tais restrições, ou quando você tiver nossa permissão por escrito.</p>

        <h6>SOBRE AS PERMISSÕES SOLICITADAS PELO APLICATIVO</h6>

        <p>
          1) Solicitamos acesso à câmera e galeria apenas para a inclusão de imagens e vídeos nos anúncios. Nenhuma imagem além das adicionadas são coletadas pelo aplicativo. A localização do usuário é solicitada para facilitar a busca por ofertas relevantes próximas da região, e não é utilizada para quaisquer outros fins.
        </p>

        <h6>COMO MODIFICAR E CANCELAR NOSSOS SERVIÇOS</h6>

        <p>1) Estamos constantemente alterando e melhorando nossos Serviços. Podemos incluir ou remover funcionalidades ou recursos e podemos também suspender ou encerrar um Serviço por completo.</p>

        <p>2) Você pode deixar de usar nossos Serviços a qualquer momento, embora isso não seja uma coisa boa para nós. O <span class="has-text-weight-bold"><i>erural</i></span> também poderá deixar de prestar os Serviços a você ou, incluir ou criar novos limites a nossos Serviços a você a qualquer momento.</p>

        <p>3) Acreditamos que você seja o proprietário dos seus dados e que é importante preservar seu acesso a esses dados. Se descontinuarmos um Serviço, quando razoavelmente possível, você será informado com antecedência razoável e terá a chance de retirar as suas informações daquele Serviço.</p>

        <p>4) Rescisão Imotivada. A Parte que desejar rescindir o Contrato de Intermediação deverá conceder à outra Parte aviso-prévio de 60 (sessenta) dias, comprometendo-se a continuar a cumprir, nesse período, suas obrigações. O aviso-prévio deverá ser manifestado por escrito, em​ ​documento​ ​e/ou​ ​e-mail​ ​a​ ​ser​ ​enviado​ ​à​ ​outra​ ​Parte.</p>

        <p>
          <ul>
            <li>
              <p>4.1) Caso haja negociação no decorrer do aviso prévio e fechamento, as obrigações das partes devem ser respeitadas até a entrega do animal objeto da negociação.</p>
            </li>
          </ul>
        </p>

        <p>
          5) Rescisão Motivada. O presente Contrato poderá ser rescindido de pleno direito, independentemente de interpelação, aviso ou notificação, judicial ou extrajudicial, sem que disso resulte para as Partes o direito a qualquer indenização, nos seguintes casos (consideradas, ​genericamente, ​de​ ​“Justa​ ​Causa”):
          <ul>
            <li>
              (a) ​Descumprimento​ de​ quaisquer das obrigações​ ​estabelecidas​ ​neste ​​Contrato;
            </li>
            <li>
              (b)​ ​Prática​ ​de​ ​qualquer​ ​ato​ ​vedado​ ​por​ ​este​ ​contrato;
            </li>
          </ul>
        </p>

        <p>
          6) A rescisão por Justa Causa produzirá efeitos imediatamente, sendo dispensado o período​ ​de​ ​aviso-prévio​ ​do​ ​Item 4.
        </p>

        <p>
          7) Eventual dano apurado na rescisão por justa causa ou na vigência do aviso prévio deverá​ ​ser​ ​compensado​ ​pela​ ​parte​ ​que​ ​causou.
        </p>

        <h6>NOSSAS GARANTIAS E ISENÇÕES DE RESPONSABILIDADE</h6>

        <p>1) Fornecemos nossos Serviços usando um nível comercialmente razoável de capacidade e cuidado e esperamos que você aproveite seu uso deles. Mas existem algumas coisas que não prometemos sobre nossos Serviços. Por exemplo, não nos responsabilizamos pelos conteúdos nos anúncios não validados, por funcionalidades específicas do serviço, disponibilidade ou capacidade de atender suas necessidades. Fornecemos os serviços “na forma em que estão”.</p>

        <h6>RESPONSABILIDADE PELOS NOSSOS SERVIÇOS</h6>

        <p>1) Quando permitido por lei, o <span class="has-text-weight-bold"><i>erural</i></span> e seus fornecedores ou colaboradores não serão responsáveis por perda de lucros, perda de receita, perda de dados, perdas financeiras ou danos indiretos, especiais, consequenciais, exemplares ou punitivos.</p>

        <p>2) Na medida permitida por lei, a responsabilidade total do <span class="has-text-weight-bold"><i>erural</i></span> e de seus fornecedores e colaboradores, para qualquer reclamação sob estes termos, incluindo quaisquer garantias implícitas, limita-se ao valor que você pagou para usar os serviços.</p>

        <p>3) Em todos os casos, a empresa <span class="has-text-weight-bold"><i>erural</i></span> e seus fornecedores e colaboradores não serão responsáveis por qualquer perda ou dano que não seja razoavelmente previsível.</p>

        <h6>USOS COMERCIAIS DE NOSSOS SERVIÇOS</h6>

        <p>1) Se você estiver usando nossos Serviços em nome de uma empresa, tal empresa aceita estes termos. Ela isentará de responsabilidade e indenizará o <span class="has-text-weight-bold"><i>erural</i></span>  e suas afiliadas, executivos e colaboradores de qualquer reivindicação, processo ou ação judicial proveniente de ou relacionado ao uso dos Serviços ou à violação destes termos, incluindo qualquer responsabilidade ou despesa resultante de reivindicações, perdas, danos, processos, julgamentos, custos de litígio e honorários advocatícios.</p>

        <h6>SOBRE ESTES TERMOS</h6>

        <p>1) Podemos modificar estes termos ou quaisquer termos adicionais que sejam aplicáveis a um Serviço para, por exemplo, refletir alterações da lei ou mudanças em nossos Serviços. Você deve consultar os termos regularmente. Postaremos avisos sobre modificações nesses termos nesta página. Publicaremos um aviso de alteração sobre os termos adicionais dentro do Serviço aplicável. As alterações não serão aplicadas retroativamente e entrarão em vigor pelo menos quatorze dias após sua publicação. Entretanto, alterações a respeito de novas funcionalidades de um Serviço ou alterações feitas por razões legais entrarão em vigor imediatamente. Se você não concordar com os termos alterados de um Serviço, deve descontinuar o uso desse Serviço.</p>

        <p>2) Estes termos regem a relação entre a empresa <span class="has-text-weight-bold"><i>erural</i></span> e você. Eles não criam quaisquer direitos para terceiros.</p>

        <p>Caso você não cumpra estes termos e nós não tomemos providências imediatas, isso <u>não</u> significa que estamos renunciando a quaisquer direitos que possamos ter (como tomar providências futuras).</p>

        <p>4) Caso uma condição específica destes termos não seja executável, isso não prejudicará quaisquer outros termos.</p>
      </div>
      <div class="columns is-hidden-mobile">
        <div class="column">
          <button class="button is-primary is-fullwidth" @click="acceptTerm">Li tudo e concordo</button>
        </div>
      </div>
    </div>
  </div>
  <div class="accept-bt-container is-hidden-desktop">
    <button class="button is-primary is-fullwidth" @click="acceptTerm">Li tudo e concordo</button>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import { useAuthInfos } from "components/user/useAuthInfos.js";

export default {
  components: {
    ArrowBackIcon,
  },
  setup() {
    const {
      updateActiveAuthStep,
      updateAuthUser,
    } = useAuthInfos();

    const acceptTerm = () => {
      updateAuthUser({
        terms: true,
      });

      updateActiveAuthStep("policies");
    };

    return {
      updateActiveAuthStep,
      acceptTerm
    };
  },
};
</script>
