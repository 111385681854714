<template>
  <div class="form">
    <div class="form-header">
      <img
        :src="chicoSmallImg"
      />
      <div class="title">
        Estamos quase lá!
      </div>
      <div class="subtitle">
        Só precisamos do seu contato para enviar as informações do seu touro ideal
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12 form-input">
        <AppInput
          placeholder="Digite"
          label="Seu nome"
          :error="false"
          :value="userName"
          @input="(v) => (userName = v)"
        />
      </div>
      <div class="column is-12 form-input">
        <AppInput
          ref="emailInput"
          name="email"
          type="email"
          placeholder="Digite"
          label="E-mail"
          :value="email"
          :showMessage="emailInputError"
          :error="emailInputError"
          @input="(v) => (userEmail = v)"
        />
      </div>
      <div class="column is-12 form-input">
        <AppInput
          v-mask="['(##) ####-####', '(##) # ####-####']"
          placeholder="(00) 0 0000 - 0000"
          type="tel"
          message="Validate"
          label="Telefone com DDD"
          :value="phone"
          :showMessage="false"
          :error="false"
          @input="(v) => (userPhone = v)"
        />
      </div>
      <div class="column is-12 has-text-centered form-input">
        <button
          class="button is-primary"
          :disabled="isDisabled"
          @click="register()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "components/ui/AppInput.vue";

import chicoSmallImg from "images/chico/chico_small.png";

import { mask } from "vue-the-mask";
import { quizState } from './questions.js'
import { ref, computed, watch, toRefs } from "vue";

export default {
  directives: {
    mask,
  },
  components: {
    AppInput,
  },
  props: {
    name: String,
    email: String,
    phone: String
  },
  setup(props) {
    const userName = ref(props.name);
    const userEmail = ref(props.email);
    const userPhone = ref(props.phone);

    const { finishedQuiz, newCustomer } = toRefs(quizState);

    const register = () => {
      newCustomer.value["name"] = userName.value;
      newCustomer.value["email"] = userEmail.value;
      newCustomer.value["phone"] = userPhone.value.replace(
        /(\s[^\s]*)\s/g,
        "$1",
      );

      mixpanel.track('Ideal Bull - User Converted', {
        'Conversion Name': newCustomer.value["name"],
        'Conversion Email': newCustomer.value["email"],
        'Conversion Phone': newCustomer.value["phone"]
      });

      finishedQuiz.value = true;
    }

    const isDisabled = computed(() => {
      return !(
        !!checkEmail(userEmail.value) &&
        !!userName.value &&
        !!checkPhone(userPhone.value)
      );
    });

    const checkPhone = (value) => {
      return value.length >= 14;
    };

    const emailInput = ref(null);
    const emailInputError = ref(false);
    const emailInputErrorMessage = "E-mail inválido! Tente novamente";

    watch(userEmail, () => {
      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
      } else {
        emailInputError.value = false;
      }
    })

    const checkEmail = (value) => {
      if (value) {
        return !emailInputError.value
      } else {
        emailInputError.value = false;

        return false;
      }
    };

    return {
      isDisabled,
      userName,
      userEmail,
      userPhone,
      checkPhone,
      emailInput,
      emailInputError,
      emailInputErrorMessage,
      checkEmail,
      register,
      chicoSmallImg,
    }
  }
}
</script>
