<template>
  <div class="columns is-marginless is-mobile is-centered">
    <component :is="authSteps[activeAuthStep]" />
  </div>
</template>

<script>
import UserAuthLogin from "components/user/UserAuthLogin.vue";
import UserAuthLoginReview from "components/user/UserAuthLoginReview.vue";
import UserAuthPassword from "components/user/UserAuthPassword.vue";
import UserAuthNewPassword from "components/user/UserAuthNewPassword.vue";
import UserAuthName from "components/user/UserAuthName.vue";
import UserAuthEmail from "components/user/UserAuthEmail.vue";
import UserAuthCpf from "components/user/UserAuthCpf.vue";
import UserAuthPhone from "components/user/UserAuthPhone.vue";
import UserAuthPoliciesAcceptance from "components/user/UserAuthPoliciesAcceptance.vue";
import UserAuthTerm from "components/user/UserAuthTerm.vue";
import UserAuthPrivacy from "components/user/UserAuthPrivacy.vue";
import UserAuthPasswordRecovery from "components/user/UserAuthPasswordRecovery.vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { ref } from "vue";

export default {
  components: {
    UserAuthLogin,
    UserAuthLoginReview,
    UserAuthPassword,
    UserAuthNewPassword,
    UserAuthName,
    UserAuthEmail,
    UserAuthCpf,
    UserAuthPhone,
    UserAuthPoliciesAcceptance,
    UserAuthTerm,
    UserAuthPrivacy,
    UserAuthPasswordRecovery,
  },
  props: {
    userEmail: String,
    userName: String,
    userPhone: String,
    countryDdi: Object,
  },
  setup(props) {
    const { activeAuthStep, updateAuthUser } = useAuthInfos();

    updateAuthUser({
      login: ref(props.userEmail),
      email: ref(props.userEmail),
      name: ref(props.userName),
      phone: ref(props.userPhone),
      countryDdi: ref(props.countryDdi),
    });

    const authSteps = {
      login: "UserAuthLogin",
      login_review: "UserAuthLoginReview",
      password: "UserAuthPassword",
      new_password: "UserAuthNewPassword",
      name: "UserAuthName",
      email: "UserAuthEmail",
      cpf: "UserAuthCpf",
      phone: "UserAuthPhone",
      policies: "UserAuthPoliciesAcceptance",
      term: "UserAuthTerm",
      privacy: "UserAuthPrivacy",
      password_recovery: "UserAuthPasswordRecovery",
    };

    return {
      activeAuthStep,
      authSteps,
    };
  },
};
</script>
