<template>
  <div
    v-if="isCancelled"
    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center py-6"
  >
    <h1 class="is-size-5 has-text-weight-bold has-text-centered m-4">
      Cadastro cancelado com sucesso!
    </h1>
    <a href="https://www.erural.net/" class="button is-primary is-pulled-right">
      Visualize outras ofertas
    </a>
  </div>
  <div
    v-else
    class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center py-6"
  >
    <p class="is-size-5 has-text-weight-bold">Cancelando inscrição</p>
    <img :src="chicoSad" alt="Chico triste" class="chico-icon" />
    <p class="is-size-6 has-text-weight-normal has-text-centered mb-5">
      Você está cancelando sua inscrição na seleção de
      {{ selectionTitle }}. Deseja realmente fazer isso?
    </p>
    <button
      class="button is-primary is-pulled-right"
      :class="{ 'is-loading': isLoading }"
      @click="unsubscribe()"
    >
      Confirmar
    </button>
  </div>
</template>

<script>
import chicoSad from "images/chico/chico_sad_face.png";

import { alert_subscriptions_unsubscribe_path } from "utils/routes";
import api from "services/api";
import { ref } from "vue";

export default {
  components: {},
  props: { selectionTitle: String, alertToken: String },
  setup(props) {
    const isLoading = ref(false);
    const isCancelled = ref(false);
    const token = ref(props.alertToken);

    const unsubscribe = () => {
      isLoading.value = true;

      api.post(alert_subscriptions_unsubscribe_path(token.value)).then(() => {
        isLoading.value = false;
        isCancelled.value = true;
      });
    };

    return { chicoSad, isCancelled, isLoading, unsubscribe };
  },
};
</script>
