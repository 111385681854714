<template>
  <section class="profile-header has-text-centered py-4">
    <h1>Qual seu perfil de compra?</h1>
    <h3>Selecione os tipos de produto você quer ver na erural</h3>
  </section>

  <section
    class="is-flex is-justify-content-center circles-container is-relative is-align-items-center pb-6"
  >
    <div class="is-flex is-flex-direction-column">
      <div
        class="circle"
        @click="toggleActive('females')"
        :class="{ active: selectedTypes.includes('females') }"
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <FemaleIcon class="icon is-pink mr-1" />
          <p>Matrizes</p>
        </div>
      </div>

      <div
        class="circle"
        @click="toggleActive('semen')"
        :class="{ active: selectedTypes.includes('semen') }"
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <p>Sêmen</p>
        </div>
      </div>
    </div>

    <div class="is-flex is-flex-direction-column is-align-items-center">
      <div
        class="circle"
        @click="toggleActive('males')"
        :class="{ active: selectedTypes.includes('males') }"
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <MaleIcon class="icon is-blue mr-1" />
          <p>Touros</p>
        </div>
      </div>

      <div
        class="circle smaller"
        @click="toggleActive('embryos')"
        :class="{ active: selectedTypes.includes('embryos') }"
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <p>Embrião</p>
        </div>
      </div>

      <div
        class="circle"
        @click="toggleActive('others')"
        :class="{ active: selectedTypes.includes('others') }"
      >
        <div class="is-flex is-align-items-center is-justify-content-center">
          <p>Outros</p>
        </div>
      </div>
    </div>

    <NavigationFooter
      :disable-continue="selectedTypes.length == 0"
      :on-confirm="onConfirm"
    />
  </section>
</template>

<script>
import NavigationFooter from "./NavigationFooter.vue";

import MaleIcon from "icons/cached/male.svg";
import FemaleIcon from "icons/cached/female.svg";

import { selectedTypes } from "./purchasePreferencesContext";
import api from "services/api";
import {
  purchase_preferences_preference_choices_url,
  purchase_preferences_save_purchase_profiles_url,
} from "utils/routes";
import { onBeforeMount } from "vue";

export default {
  components: {
    MaleIcon,
    FemaleIcon,
    NavigationFooter,
  },
  props: {
    selectedTypes: Array,
  },
  setup(props) {
    onBeforeMount(() => {
      selectedTypes.value = props.selectedTypes;
    });

    const toggleActive = (type) => {
      if (selectedTypes.value.includes(type)) {
        selectedTypes.value = selectedTypes.value.filter(
          (selected) => selected !== type,
        );
      } else {
        selectedTypes.value = [...selectedTypes.value, type];
      }
    };

    const onConfirm = () => {
      api
        .post(purchase_preferences_save_purchase_profiles_url(), {
          purchase_types: selectedTypes.value,
        })
        .then(() => {
          mixpanel.track(
            "Buy Profile",
            {
              Preferences: JSON.parse(JSON.stringify(selectedTypes.value)),
            },
            () =>
              (window.location.href =
                purchase_preferences_preference_choices_url()),
          );
        });
    };

    return {
      selectedTypes,
      toggleActive,
      onConfirm,
    };
  },
};
</script>

<style></style>
