<template>
  <div
    class="modal document-modal create-document"
    :class="{ 'is-active': openCreateDocumentModal }"
  >
    <div class="modal-background" @click="cleanModal" />
    <div class="modal-content is-flex is-flex-direction-column">
      <div class="modal-header mb-6">
        <div class="has-text-centered has-text-weight-semibold px-6 mt-4">
          Tire uma foto do {{ documetTitle }}
        </div>
        <ArrowBackIcon class="is-hidden-tablet" @click="cleanModal" />
        <button class="delete is-hidden-mobile" @click="cleanModal" />
      </div>

      <div
        class="is-flex-grow-1 px-2 py-4 is-flex is-align-items-center is-justify-content-center"
      >
        <figure
          v-if="!hasThumb"
          class="image is-clipped"
          @click.prevent="$refs.fileInputDocument.click()"
        >
          <AddPhotoIcon />
        </figure>

        <figure
          v-else
          class="image thumb is-clipped"
          @click.prevent="$refs.fileInputDocument.click()"
        >
          <img :src="thumbImage" />
        </figure>
      </div>

      <div class="is-flex is-align-items-center is-flex-direction-column pb-4">
        <button
          v-if="!thumbImage"
          class="button is-primary is-fullwidth is-hidden-tablet"
          @click.prevent="$refs.inputCamera.click()"
        >
          Usar câmera
        </button>

        <button
          v-if="!thumbImage"
          class="button is-primary is-outlined is-fullwidth mt-2 mb-6"
          @click.prevent="$refs.fileInputDocument.click()"
        >
          Abrir Galeria
        </button>

        <button
          v-if="thumbImage"
          class="button is-primary mb-4"
          :class="{ 'is-loadihng': isLoading }"
          @click.stop="sendDocument()"
        >
          Salvar
        </button>

        <button
          class="button is-danger is-outlined mb-4"
          @click.prevent="cleanModal"
        >
          Cancelar
        </button>
      </div>

      <AppInput
        type="hidden"
        name="document_type"
        :value="document_type"
        :isSynchronized="true"
        @input="(value) => (document_type = value)"
      />

      <input
        ref="inputCamera"
        type="file"
        accept="image/*"
        name="document"
        capture
        @change.prevent="handleFileUpload"
      />

      <input
        ref="fileInputDocument"
        type="file"
        name="document"
        accept="image/*"
        @change.prevent="handleFileUpload"
      />
    </div>
  </div>
</template>
<script>
import { computed, ref, toRefs } from "vue";
import { openCreateDocumentModal, userDocuments } from "../userContext";
import api from "services/api";

import AppInput from "components/ui/AppInput.vue";

import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AddPhotoIcon from "icons/cached/add_a_photo.svg";

export default {
  components: {
    ArrowBackIcon,
    AddPhotoIcon,
    AppInput,
  },
  setup() {
    const hasThumb = ref(false);
    const thumbImage = ref(null);
    const documentFile = ref(null);
    const fileInputDocument = ref(null);
    const inputCamera = ref(null);
    const isLoading = ref(false);

    const translate = {
      "rg-front": "Identidade (Frente)",
      "rg-back": "Identidade (Verso)",
      itr: "ITR",
      income: "Comprovante de renda",
      por: "Comprov. de residência",
      ri: "Inscrição rural",
    };

    const { document_type } = toRefs(userDocuments);

    const documetTitle = computed(() => {
      return translate[userDocuments.document_type];
    });

    const handleFileUpload = (e) => {
      let reader = new FileReader();
      let fileName = e.target.files[0].name;
      let triggedInput = e.target;

      reader.onload = (e) => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const elem = document.createElement("canvas");
          const ctx = elem.getContext("2d");

          if (img.height > img.width) {
            let height = 1024;

            let scaleFactor = height / img.height;

            elem.width = img.width * scaleFactor;
            elem.height = height;

            ctx.drawImage(img, 0, 0, img.width * scaleFactor, height);
          } else {
            let width = 1024;

            let scaleFactor = width / img.width;

            elem.height = img.height * scaleFactor;
            elem.width = width;

            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          }

          console.log(e);
          ctx.canvas.toBlob(
            (blob) => {
              const file = new File([blob], fileName, {
                type: "image/*",
                lastModified: Date.now(),
              });

              if (file.size < triggedInput.files[0].size) {
                documentFile.value = file;
              } else {
                documentFile.value = e.target.result;
              }

              thumbImage.value = e.target.result;
              hasThumb.value = true;
            },
            "image/jpeg",
            1,
          );
        };
      };

      reader.readAsDataURL(e.target.files[0]);
    };

    const cleanModal = () => {
      documentFile.value = null;
      thumbImage.value = null;
      hasThumb.value = false;
      openCreateDocumentModal.value = false;
    };

    const sendDocument = () => {
      isLoading.value = true;

      let formData = new FormData();
      formData.append("document_type", document_type.value);
      formData.append("document", documentFile.value);

      api.post("users/enviar_documento", formData).then(() => {
        window.location.reload();
      });
    };

    return {
      hasThumb,
      thumbImage,
      handleFileUpload,
      openCreateDocumentModal,
      document_type,
      documetTitle,
      inputCamera,
      fileInputDocument,
      cleanModal,
      sendDocument,
      isLoading,
    };
  },
};
</script>
