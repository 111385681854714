<template>
  <div
    class="columns is-mobile links is-hidden-desktop has-text-centered is-variable is-1 mt-2 is-centered"
  >
    <div class="column is-3-mobile" @click="openAndTrack($event)">
      <p class="circle is-inline-flex">
        <ZoomInIcon />
      </p>
      <p class="title has-text-weight-medium">Informações</p>
    </div>
    <div
      class="column is-3-mobile"
      @click="
        $refs.infoEnd.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      "
    >
      <p class="circle is-inline-flex">
        <AnimalDataIcon />
      </p>
      <p class="title has-text-weight-medium">Ver lotes</p>
    </div>

    <div v-if="auction.catalog" class="column is-3-mobile">
      <a id="eventCatalogLink" download target="_blank" :href="auction.catalog">
        <p class="circle is-inline-flex">
          <AssignmentIcon />
        </p>
        <p class="title has-text-weight-medium">Catálogo</p>
      </a>
    </div>
    <div class="column is-3-mobile">
      <AuctionShareMobile
        :auctionTitle="auction.title"
        :auctionUrl="auction.url"
      />
    </div>
  </div>

  <div v-show="open" class="columns is-touch is-marginless is-hidden-desktop">
    <div class="column is-paddingless">
      <div class="description">
        <span v-html="auction.rich_description" />
      </div>

      <div class="auction-info">
        <div class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <DataPickIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title">
                {{ auction.day }} de {{ auction.month }}, às
                {{ auction.time }} (BRT)
              </span>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <PlaceIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title is-capitalized">
                {{ auction.city }} {{ "- " + auction.state }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="auction.shipping_description" class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <LocalShippingIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title">
                {{ auction.shipping_name }}
              </span>
              <p class="pre-wrap">
                {{ auction.shipping_description }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="auction.payment_summary" class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <MonetizationIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title"> {{ auction.payment_summary }}: </span>
              <p>
                {{ auction.payment_explanation }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="auction.provider" class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <GavelIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title">
                {{ auction.provider }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="auction.channel" class="block">
          <div class="columns is-mobile">
            <div class="column is-1-mobile">
              <PlayArrowIcon />
            </div>
            <div class="column is-11-mobile">
              <span class="title"> Transmissão {{ auction.channel }} </span>
            </div>
          </div>
        </div>
      </div>

      <div class="auction-documents">
        <div v-if="auction.catalog" class="block">
          <a id="eventCatalogLinkInfoContainer" :href="auction.catalog" download target="_blank">
            <div class="document">
              <div class="circle">
                <DownloadIcon />
              </div>
              <div>
                <span class="title">{{ auction.catalog_file_name }}</span>
                <span class="description">
                  Baixar agora
                  <span class="has-text-weight-normal">
                    - {{ formatBytes(auction.catalog_file_size) }}
                  </span>
                </span>
              </div>
            </div>
          </a>
        </div>

        <div v-for="file in auctionFiles" :key="file.id" class="block">
          <a :href="file.file_link" download target="_blank">
            <div class="document">
              <div class="circle">
                <DownloadIcon />
              </div>
              <div>
                <span class="title">
                  {{ file.file_file_name }}
                </span>
                <span class="description">
                  Baixar agora
                  <span class="has-text-weight-normal">
                    - {{ formatBytes(file.file_file_size) }}
                  </span>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div ref="infoEnd" class="is-hidden-desktop" @click="() => (open = !open)">
    <div v-if="open" class="is-flex details-info mt-5">
      <span>Ver menos detalhes</span>
      <div class="icon">
        <ArrowDropDownIcon class="rotate" />
      </div>
    </div>
    <div v-else class="is-flex details-info">
      <span>Ver mais detalhes</span>
      <div class="icon">
        <ArrowDropDownIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import MonetizationIcon from "icons/cached/monetization_on.svg";
import DownloadIcon from "icons/cached/download.svg";
import DataPickIcon from "icons/cached/event.svg";
import PlaceIcon from "icons/cached/place.svg";
import LocalShippingIcon from "icons/cached/local_shipping.svg";
import GavelIcon from "icons/cached/gavel.svg";
import PlayArrowIcon from "icons/cached/play_arrow.svg";
import ArrowDropDownIcon from "icons/cached/expand_more.svg";
import ZoomInIcon from "icons/cached/zoom_in.svg";
import AnimalDataIcon from "icons/cached/animal_data.svg";
import AssignmentIcon from "icons/cached/assignment.svg";
import AuctionShareMobile from "./AuctionShareMobile.vue";

export default {
  components: {
    MonetizationIcon,
    DownloadIcon,
    DataPickIcon,
    PlaceIcon,
    LocalShippingIcon,
    GavelIcon,
    PlayArrowIcon,
    ArrowDropDownIcon,
    ZoomInIcon,
    AnimalDataIcon,
    AssignmentIcon,
    AuctionShareMobile,
  },
  props: {
    auction: Object,
    auctionFiles: Object,
    mixpanelData: Object
  },
  setup(props) {
    const open = ref(false);

    onMounted(() => {
      mixpanel.track_links('#eventCatalogLink', 'Event - Catalog Clicked', { ...props.mixpanelData, 'Section': 'CTA Buttons' });
      mixpanel.track_links('#eventCatalogLinkInfoContainer', 'Event - Catalog Clicked', { ...props.mixpanelData, 'Section': 'Details' });
      mixpanel.track_links('#shareMobileLink', 'Event - Share Clicked', props.mixpanelData);
    });

    const formatBytes = (a, b = 2) => {
      if (0 === a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
      return (
        parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      );
    };

    const openAndTrack = (e) => {
      open.value = true;

      let circle = e.currentTarget.querySelector('p.circle');
      let circleColor = window.getComputedStyle(circle, null).getPropertyValue('background-color');
      circleColor = circleColor.replace(/[rgb() ]/g, '').split(',');

      const btProps = {
        'Text': e.currentTarget.querySelector('p.title').textContent,
        'Color': rgbToHex(circleColor)
      }

      mixpanel.track('More Info Clicked', {
        'Page': 'Event',
        'Section': 'Infos Section',
        ...props.mixpanelData,
        ...btProps
      });
    }

    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (array) => {
      return "#" + componentToHex(parseInt(array[0])) + componentToHex(parseInt(array[1])) + componentToHex(parseInt(array[2]));
    }

    return {
      open,
      formatBytes,
      openAndTrack
    };
  },
};
</script>
