<template>
  <div class="proposal-identification">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
    </div>

    <div class="modal-body">
      <div class="content top">
        <span class="title is-block has-text-weight-bold">
          Faça uma proposta!
        </span>
        <p class="description">
          Para avançar com esse lote, precisamos confirmar seus dados. Preencha
          o formulário abaixo para entrarmos em contato com você.
        </p>
      </div>

      <div class="content middle">
        <div class="field">
          <AppInput
            type="text"
            :value="state.currentTrackedUser.name"
            placeholder="Digite seu nome"
            label="Nome"
            is-synchronized
            required
            @input="
              (value) => {
                state.currentTrackedUser.name = value;
              }
            "
          />
        </div>
        <div class="field">
          <AppInput
            type="text"
            :value="state.currentTrackedUser.email"
            placeholder="Digite seu e-mail"
            label="E-mail"
            is-synchronized
            required
            @input="
              (value) => {
                state.currentTrackedUser.email = value;
              }
            "
          />
        </div>
        <div class="field">
          <AppInput
            v-mask="['(##) ####-####', '(##) # ####-####']"
            type="tel"
            :value="state.currentTrackedUser.phone"
            placeholder="Digite seu telefone"
            label="Telefone"
            is-synchronized
            @input="
              (value) => {
                state.currentTrackedUser.phone = value;
              }
            "
          />
        </div>
        <div class="mb-4 field">
          <AppInput
            v-mask="['###.###.###-##']"
            type="tel"
            :value="state.currentTrackedUser.cpf"
            placeholder="Digite seu CPF"
            label="CPF"
            is-synchronized
            required
            :error="errorCPF"
            message="CPF inválido"
            :show-message="errorCPF"
            @input="
              (value) => {
                state.currentTrackedUser.cpf = value;
              }
            "
          />
        </div>

        <p class="has-text-weight-semibold is-size-7 has-text-grey">
          *Os eventos exigem verificação de documentos devido ao parcelamento
          oferecido. Mas não se preocupe, seus dados estão seguros e serão
          utilizados apenas para fins relacionados aos eventos.
        </p>

        <button
          class="button is-primary mt-6 is-fullwidth"
          :disabled="
            state.isSubmitting || !isValidCPF() || !completedIdentification()
          "
          :class="{ 'is-loading': state.isSubmitting }"
          @click="
            () => {
              createConversion();
              setActiveTab('proposal');
            }
          "
        >
          Registrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "components/ui/AppInput.vue";
import eruralLogo from "images/logos/erural_beige_dark.png";

import { root_path } from "utils/routes";

import {
  state,
  setActiveTab,
  closeModal,
  createConversion,
  completedIdentification,
  isValidCPF,
  errorCPF,
} from "./auctionRegistrationModalContext";
import { mask } from "vue-the-mask";
import { onMounted, computed } from "vue";

export default {
  components: {
    AppInput,
  },
  directives: {
    mask,
  },
  setup() {
    onMounted(() => {
      state.previuosTab = "proposalIdentification";
    });

    return {
      state,
      createConversion,
      completedIdentification,
      setActiveTab,
      closeModal,
      eruralLogo,
      root_path,
      isValidCPF,
      errorCPF,
    };
  },
};
</script>
