<template>
  <div class="auction-card">
    <div class="banner">
      <img :src="state.auction.cover_url" :alt="state.auction.title" />
    </div>
    <div class="content">
      <span class="auction-title">
        {{ state.auction.title }}
      </span>
      <div v-if="state.auction.start_day" class="auction-date">
        <TodayIcon />
        <div class="ml-1">
          <span>
            {{ state.auction.start_day }}
            <span v-if="startMonthAbbr != endMonthAbbr">
              {{ startMonthAbbr }}
            </span>
          </span>
          <span v-if="state.auction.end_day">
            a {{ state.auction.end_day }}
          </span>
          {{ endMonthAbbr }} | {{ state.auction.start_hour }}
        </div>
      </div>
      <div v-else class="auction-date">Lotes disponíveis agora</div>
    </div>
  </div>
</template>

<script>
import TodayIcon from "icons/cached/today.svg";

import { state } from "./auctionRegistrationModalContext";

export default {
  components: {
    TodayIcon,
  },
  setup() {
    const startMonthAbbr = state.auction.month_name.slice(0, 3);
    const endMonthAbbr = state.auction.end_month_name.slice(0, 3);

    return {
      startMonthAbbr,
      endMonthAbbr,
      state,
    };
  },
};
</script>
