<template>
  <div class="modal confirm-exit is-active">
    <div class="modal-background" @click.prevent="() => $emit('close')" />
    <div class="modal-content">
      <div class="modal-top">
        <CloseIcon class="close-modal" @click.prevent="() => $emit('close')" />
        <b class="modal-title">Confirmar navegação</b>
      </div>
      <div class="modal-info">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <div class="modal-icon">
            <ReplayIcon />
          </div>
          <p class="modal-text">
            Ao clicar em <b>Confirmar</b> você será redirecionado para o início
            do formulário.
          </p>
        </div>
        <div class="modal-buttons">
          <a
            class="button is-warning is-fullwidth mb-2"
            :href="hfLink ? 'https://' + hfLink : 'https://meulote.erural.net'"
          >
            Confirmar
          </a>
          <button
            class="button is-outlined is-warning is-fullwidth"
            @click.prevent="() => $emit('close')"
          >
            Voltar á página anterior
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReplayIcon from "icons/cached/replay.svg";
import CloseIcon from "icons/cached/close.svg";

export default {
  components: {
    CloseIcon,
    ReplayIcon,
  },
  props: {
    hfLink: String,
  },
  emits: ["close"],
  setup() {
    return {};
  },
};
</script>
