<template>
  <section
    class="is-flex is-justify-content-space-around is-align-items-center navigation-footer"
  >
    <div class="is-flex is-align-items-center is-clickable" @click="onGoBack()">
      <ArrowBackIcon class="icon mr-2" />
      <p class="has-text-weight-semibold">Voltar</p>
    </div>

    <button
      class="button is-secondary is-flex is-justify-content-space-around is-align-items-center"
      :disabled="disableContinue"
      @click="!disableContinue && onConfirm()"
    >
      <p>{{ confirmText }}</p>

      <CheckIcon class="icon" v-if="confirmText == 'Concluir'" />
      <ArrowFowardIcon class="icon" v-else />
    </button>
  </section>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import ArrowFowardIcon from "icons/cached/arrow_forward.svg";
import CheckIcon from "icons/cached/check.svg";

export default {
  components: {
    ArrowBackIcon,
    ArrowFowardIcon,
    CheckIcon,
  },
  props: {
    disableContinue: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    onGoBack: {
      type: Function,
      default: () => window.history.back(),
    },
    confirmText: {
      type: String,
      default: "Seguir",
    },
    redirectTo: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style></style>
