<template>
  <div class="modal auction-conditions-modal" :class="{ 'is-active': open }">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content">
      <div class="modal-header">
        <button class="delete" @click="$emit('close')" />
      </div>
      <div class="modal-body">
        <div class="content">
          <div class="has-text-centered">
            <MonetizationIcon />
          </div>
          <span class="title">{{ payment }} </span>
          <p class="description">
            {{ paymentExplanation }}
          </p>
          <div class="has-text-centered">
            <button class="button is-success" @click="$emit('close')">
              Entendi!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonetizationIcon from "icons/cached/monetization_on.svg";

export default {
  components: {
    MonetizationIcon,
  },
  props: {
    open: Boolean,
    payment: String,
    paymentExplanation: String,
  },
  emits: ["close"],
};
</script>
