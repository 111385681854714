<template>
  <div>
    <a
      :href="whatsappShareLink"
      target="_blank"
      class="whatsapp-link-button button"
      @click="trackMixpanel"
    >
      <WhatsappIcon />
      <span>COMPARTILHAR AGORA</span>
    </a>
  </div>
</template>

<script>
import WhatsappIcon from "icons/cached/whatsapp.svg";

export default {
  components: {
    WhatsappIcon,
  },
  props: {
    customerId: Number,
    section: String,
  },
  setup(props) {
    const url = new URL("https://agronegocio.erural.net/boasvindas-erural");
    url.searchParams.append("indicator_id", props.customerId);

    const shareUrl = url.toString();

    const whatsappShareParam = encodeURIComponent(
      "Olá! Quero compartilhar a erural, uma plataforma digital confiável e eficiente para o comércio de animais. Minha experiência foi excepcional, " +
        "tenho certeza que você também vai gostar.\n\nAcesse meu link para uma consultoria gratuita!\n" +
        shareUrl,
    );
    const whatsappShareLink = `https://api.whatsapp.com/send?text=${whatsappShareParam}`;

    let mixpaneldata = {
      Section: props.section,
    };

    const trackMixpanel = () => {
      mixpanel.track("Referral Whatsapp Share Click", mixpaneldata);
    };

    return {
      whatsappShareLink,
      trackMixpanel,
    };
  },
};
</script>
