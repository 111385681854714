<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('phone')"
      />
      <span class="title">Crie uma senha</span>
      <p class="description is-hidden-touch">Digite uma senha</p>
      <p class="description is-hidden-desktop">
        Dicas para criar senhas fortes
      </p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <PasswordInput
            ref="passwordInput"
            class="password-input"
            placeholder="Digite"
            message="Pelo menos 6 caracteres"
            @input="(v) => (password = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          @click="createPassword"
        >
          Próximo
        </button>
      </div>

      <div class="columns is-marginless is-hidden-desktop">
        <div class="column">
          <div class="pass-tips">
            <span class="title">Como criar uma senha forte:</span>
            <div class="block">
              <ul>
                <li>
                  Use letras maiúsculas, minúsculas, números e símbolos como $ %
                  @ &.
                </li>
              </ul>
            </div>
            <div class="block">
              <ul>
                <li>
                  Não use datas de aniversários ou coisas que outras pessoas
                  tenham acesso. Não compartilhe com ninguém.
                </li>
              </ul>
            </div>
            <div class="block">
              <ul>
                <li>Evite repetir senhas para contas diferentes.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookIcon from "icons/cached/facebook.svg";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import PasswordInput from "components/ui/PasswordInput.vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { ref, watch } from "vue";

export default {
  components: {
    FacebookIcon,
    ArrowBackIcon,
    PasswordInput,
  },
  setup() {
    const { updateActiveAuthStep, updateAuthUser } = useAuthInfos();

    const passwordInput = ref(null);

    const password = ref("");

    const isDisabled = ref(true);
    watch(password, () => {
      isDisabled.value = !(
        passwordInput.value.inputEl.checkValidity() && password.value.length >= 6
      );
    });

    const createPassword = () => {
      updateAuthUser({
        password: password.value,
      });

      updateActiveAuthStep("policies");
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      password,
      createPassword,
      passwordInput,
    };
  },
};
</script>
