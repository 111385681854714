<template>
  <div
    class="registration-flow shopping-registration is-flex is-flex-direction-column is-justify-content-between"
  >
    <div
      class="registration-header is-flex is-justify-content-center is-flex-direction-column is-align-items-center"
    >
      <img :src="eruralBeigeDarkImg" alt="logo erural" class="logo mb-5" />
    </div>
    <div
      class="registration-body is-flex-grow-1 is-flex is-flex-direction-column is-flex-justify-content-space-evenly"
    >
      <div class="is-flex is-justify-content-center">
        <img :src="chicoImg" alt="chico" class="chico mb-4" />
      </div>
      <div class="is-flex is-flex-direction-column initial-step">
        <div
          class="has-text-primary has-text-weight-medium mb-5 has-text-centered"
        >
          {{ userName }}, já recebi seu cadastro e estou <br />
          analisando seu perfil!
        </div>
        <div class="mb-4 has-text-centered">
          Em breve, nossa equipe entrará em contato com você, via e-mail ou
          telefone, para te informar se você está apto ou não a negociar neste
          evento.
        </div>
        <div class="mb-4 warning-message">
          <AlertIcon />
          <span>
            <strong>Atenção</strong> nenhum funcionário da erural vai te pedir
            <br />
            códigos. Tenha cuidado com os golpes.
          </span>
        </div>
        <div class="is-flex is-justify-content-center">
          <button
            class="shopping-button button is-primary"
            @click="openRegistrationModal = false"
          >
            Concluir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, onUpdated, watch } from "vue";
import { preRegistrationState } from "./preRegistrationContext";
import AlertIcon from "icons/cached/warning_amber.svg";

import chicoImg from "images/chico/chico.png";
import eruralBeigeDarkImg from "images/logos/erural_beige_dark.png";

export default {
  components: {
    AlertIcon,
  },
  setup() {
    const { conversion, openRegistrationModal } = toRefs(preRegistrationState);

    const userName = ref("");

    watch(conversion, () => {
      if (conversion.value.name) {
        userName.value = conversion.value.name;
      }
    });

    return {
      userName,
      openRegistrationModal,
      chicoImg,
      eruralBeigeDarkImg,
    };
  },
};
</script>
