const cpfValidate = (cpfNumber) => {
  let cpfSum = 0;
  let i = 0;
  let j = 0;

  for (i = 10, j = 0; i >= 2; i--, j++) {
    cpfSum += cpfNumber[j] * i;
  }

  let sumMod = (cpfSum * 10) % 11;
  if (sumMod == 10) sumMod = 0;

  if (sumMod != cpfNumber[9]) return false;

  let cpfSum2 = 0;

  for (i = 11, j = 0; i >= 2; i--, j++) {
    cpfSum2 += cpfNumber[j] * i;
  }

  let sumMod2 = (cpfSum2 * 10) % 11;
  if (sumMod2 == 10) sumMod2 = 0;

  if (sumMod2 != cpfNumber[10]) return false;

  var valid = false;
  for (i = 0; i < 10; i++) {
    if (cpfNumber[i] != cpfNumber[i + 1]) {
      valid = true;
      break;
    }
  }
  return valid;
};

const cnpjValidate = (cnpjNumber) => {
  let weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];

  let cnpjSum = 0;
  let i = 0;
  let j = 0;

  for (i = 11, j = 0; i >= 0; i--, j++) {
    cnpjSum += cnpjNumber[i] * weights[j];
  }

  let digit = cnpjSum % 11;
  if (digit == 0 || digit == 1) {
    digit = 0;
  } else {
    digit = 11 - digit;
  }

  if (digit != cnpjNumber[12]) {
    return false;
  }

  weights.push(6);
  let cnpjSum2 = 0;
  for (i = 12, j = 0; i >= 0; i--, j++) {
    cnpjSum2 += cnpjNumber[i] * weights[j];
  }

  let digit2 = cnpjSum2 % 11;
  if (digit2 == 0 || digit2 == 1) {
    digit2 = 0;
  } else {
    digit2 = 11 - digit2;
  }

  if (digit2 != cnpjNumber[13]) {
    return false;
  }

  var valid = false;
  for (i = 0; i < 13; i++) {
    if (cnpjNumber[i] != cnpjNumber[i + 1]) {
      valid = true;
      break;
    }
  }
  return valid;
};

export { cpfValidate, cnpjValidate };
