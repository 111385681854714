<template>
  <div class="modal pre-registration-modal" :class="{ 'is-active': open }">
    <div class="modal-background" @click="$emit('close'), reset()" />
    <div class="modal-content">
      <div class="modal-header">
        <button class="delete" @click="$emit('close'), reset()" />
      </div>

      <div class="columns is-centered is-multiline is-mobile">
        <div class="column is-12 is-flex is-justify-content-center">
          <img :src="auction.card_banner" :alt="auction.title" class="banner"/>
        </div>
      </div>
      <div
        class="columns is-centered is-multiline is-mobile is-flex-grow-1"
      >
        <div v-if="!registered" class="column is-12 pt-0 has-text-centered">
          <span class="has-text-weight-semibold"> Ative o lembrete para ser <br> avisado um dia antes do evento ! </span>
          <div class="is-size-7 mt-2">
            ( Este evento não é promovido pela erural )
          </div>
        </div>
        <div v-else class="column is-12 has-text-centered">
          <div class="has-text-primary has-text-weight-semibold">
            Lembrete anotado, {{ name }}!
          </div>
          Vou te enviar um e-mail quando este e outros leilões parecidos
          acontecerem.
        </div>
        <div v-if="!registered" class="column is-12 form">
          <div class="columns is-multiline">
            <div class="column is-12 pt-0 pb-2">
              <app-input
                :error="false"
                :value="userName"
                :small="true"
                placeholder="Digite"
                label="Seu nome"
                @input="(v) => (userName = v)"
              />
            </div>
            <div class="column is-12 pt-0 pb-2">
              <app-input
                :value="email"
                :showMessage="emailInputError"
                :small="true"
                placeholder="Digite"
                :message="emailInputErrorMessage"
                label="E-mail"
                :error="emailInputError"
                @input="(v) => (userEmail = v)"
              />
            </div>
            <div class="column is-12 pt-0 pb-2">
              <app-input
                v-mask="['(##) ####-####', '(##) # ####-####']"
                :value="phone"
                :small="true"
                type="tel"
                :showMessage="false"
                placeholder="(00) 0 0000 - 0000"
                message="Validate"
                label="Telefone com DDD"
                :error="false"
                @input="(v) => (userPhone = v)"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!registered" class="columns is-mobile">
        <div class="column is-12 is-flex is-justify-content-center">
          <button
            class="button is-primary column is-half"
            :disabled="isDisabled"
            :class="{
              'is-loading': submitting,
            }"
            @click="preRegistrationFormSubmit"
          >
            Ativar Lembrete
          </button>
        </div>
      </div>

      <div v-else class="columns is-mobile m-0 is-centered">
        <div class="column is-narrow">
          <button class="button is-primary" @click="$emit('close'), reset()">
            Voltar a Agenda
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, watch } from "vue";
import { mask } from "vue-the-mask";
import AppInput from "components/ui/AppInput.vue";

import {
  updatePreRegistrationState,
  preRegistrationState,
} from "../registration_flow/preRegistrationContext";
import api from "services/api";
import { useCrawledAuctions } from "hooks/useCrawledAuctions";

export default {
  directives: {
    mask,
  },
  components: {
    AppInput,
  },
  props: {
    open: Boolean,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auction: Object,
  },
  emits: ["close"],
  setup(props) {
    const { sendEventMixpanelTrack } = useCrawledAuctions();
    const { conversion } = toRefs(preRegistrationState);
    const userName = ref(props.name);
    const userEmail = ref(props.email);
    const userPhone = ref(props.phone);
    const registered = ref(false);
    const submitting = ref(false);

    const isDisabled = computed(() => {
      if (submitting.value || registered.value) {
        return true;
      }

      return !(
        !!checkEmail(userEmail.value) &&
        !!userName.value &&
        !!checkPhone(userPhone.value)
      );
    });

    const checkPhone = (value) => {
      return value.length >= 14;
    };

    const emailInputError = ref(false);
    const emailInputErrorMessage = "E-mail inválido! Tente novamente";

    const checkEmail = (value) => {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      if (value) {
        if (emailRegex.test(value)) {
          emailInputError.value = false;

          return true;
        } else {
          emailInputError.value = true;

          return false;
        }
      } else {
        emailInputError.value = false;

        return false;
      }
    };

    const preRegistrationFormSubmit = () => {
      const currentConversion = props.lpConversion;

      submitting.value = true;
      currentConversion.lp_conversion.name = userName.value;
      currentConversion.lp_conversion.email = userEmail.value;
      currentConversion.lp_conversion.phone = userPhone.value.replace(
        /(\s[^\s]*)\s/g,
        "$1",
      );

      api
        .post(currentConversion.action, currentConversion)
        .then((response) => {
          registered.value = true;
          submitting.value = false;

          api.post(currentConversion.reminder, {
            user_conversion_id: response.data.id,
            crawled_auction_id: props.auction.id,
          });

          sendEventMixpanelTrack('Scheduler - User Converted', props.auction, {
            'Conversion Name': userName.value,
            'Conversion Email': userEmail.value,
            'Conversion Phone': userPhone.value
          });

          updatePreRegistrationState({
            conversion: response.data,
            openRegistrationModal: true,
          });
        })
        .catch((err) => {
          submitting.value = false;
        });
    };

    watch(conversion, () => {
      if (conversion.value) {
        registered.value = true;
      }
    });

    const reset = () => {
      registered.value = false;
      submitting.value = false;
    };

    return {
      userName,
      userEmail,
      userPhone,
      isDisabled,
      emailInputError,
      emailInputErrorMessage,
      preRegistrationFormSubmit,
      submitting,
      registered,
      conversion,
      reset,
    };
  },
};
</script>
