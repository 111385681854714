<template>
  <div class="modal reels-end-modal is-active">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-content">
      <a class="close" @click.prevent="$emit('close')">
        <CloseIcon />
      </a>

      <p>
        Você visualizou todos os lotes disponíveis para este evento.
        <br />
        O que deseja fazer?
      </p>

      <a
        ref="otherEventsBt"
        :href="crawled_auctions_index_path()"
        class="button is-warning"
        >Ver outros eventos</a
      >
      <button class="button is-outlined is-white" @click.prevent="restart">
        Voltar do início
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import CloseIcon from "icons/cached/close.svg";
import { crawled_auctions_index_path } from "utils/routes";

export default {
  components: {
    CloseIcon,
  },
  props: {
    mixpanelData: Object,
  },
  emits: ["restart", "close"],
  setup(props, { emit }) {
    const restart = () => {
      emit("restart");
      emit("close");
    };

    onMounted(() => {
      mixpanel.track("Reels - Reels End Modal Opened", props.mixpanelData);
    });

    const otherEventsBt = ref(null);
    onMounted(() => {
      mixpanel.track_links(
        otherEventsBt.value,
        "Reels - Other Events Clicked",
        props.mixpanelData,
      );
    });

    return {
      restart,
      otherEventsBt,
      crawled_auctions_index_path,
    };
  },
};
</script>
