<template>
  <div class="pre-registration-form">
    <span
      v-if="page == 'Reels'"
      class="title is-block has-text-centered has-text-weight-semibold"
      :class="{ reels: page == 'Reels' }"
    >
      Estamos quase lá
    </span>
    <span v-else class="title is-block has-text-centered has-text-weight-bold">
      Ficou interessado?
    </span>

    <span
      v-if="page == 'Reels'"
      class="description is-block has-text-centered"
      :class="{ reels: page == 'Reels' }"
    >
      <span class="has-text-weight-medium">
        Preencha os dados abaixo para acessar as informações deste lote
      </span>
    </span>
    <span v-else class="description is-block has-text-centered">
      <span class="has-text-weight-medium">Registre-se</span> para receber
      atualizações deste evento e semelhantes!
    </span>

    <AuctionPreRegistrationForm
      :formDisplay="formDisplay"
      :cta="cta"
      :name="name"
      :email="email"
      :phone="phone"
      :lpRedirectLink="lpRedirectLink"
      :lpConversion="lpConversion"
      :trackData="trackData"
      :page="page"
      :section="section"
      :mixpanelData="mixpanelData"
    />
  </div>
</template>

<script>
import AuctionPreRegistrationForm from "./AuctionPreRegistrationForm.vue";

export default {
  components: {
    AuctionPreRegistrationForm,
  },
  props: {
    cta: String,
    name: String,
    email: String,
    phone: String,
    lpRedirectLink: String,
    lpConversion: Object,
    trackData: Object,
    page: String,
    section: String,
    formDisplay: String,
    mixpanelData: Object,
  },
};
</script>
