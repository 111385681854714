<template>
  <div class="question-body">
    <div v-if="questionObj.icon" class="question-icon">
      <component :is="questionObj.icon" />
    </div>
    <div class="question-title">
      {{ questionObj.title }}
    </div>
    <div class="answers" :class="questionObj.type">
      <div v-for="(answer,index) in questionObj.answers" :key="index" class="answer" @click="select(index+1)"
      :class="[questionObj.type, { 'selected' : selected && (selectedIndex==index+1), 'not-selected' : selected && (selectedIndex!=index+1)}]">
        <div v-if="questionObj.type.includes('text')" class="text" :class="[questionObj.type, { 'only-text' : questionObj.type.length == 1}]">
          {{ answer["text"] }}
        </div>
        <div v-if="questionObj.type.includes('img')" class="img" :class="{ 'image is-3by2' : questionObj.type.length == 1 }">
          <img :src="answer.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BovineIcon from "icons/cached/bovine.svg";
import BarsIcon from "icons/cached/signal_cellular_alt.svg";
import DnaIcon from "icons/cached/dna_2.svg";
import MoneyIcon from "icons/cached/attach_money.svg";

import api from "services/api";
import { ideal_bull_suggestions_path } from "utils/routes";

import { questions, quizState, sendChatbotAnswer } from './questions.js';
import { onMounted, ref, toRefs } from "vue";

export default {
  components : {
    BovineIcon,
    BarsIcon,
    DnaIcon,
    MoneyIcon,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    question: Number,
  },
  setup(props) {
    const { answers, currentQuestion, knownCustomer, suggestedBatches, breedFilter } = toRefs(quizState);
    const selectedIndex = ref(0);
    const selected = ref(false);
    var disable = true;

    const questionId = () => {
      if (currentQuestion.value == 6) {
        return `6-${breedFilter.value}`;
      } else {
        return currentQuestion.value;
      }
    };

    const questionObj = ref(questions[questionId()]);


    const select = (i) => {
      if (disable)
        return

      disable = true;

      selected.value = true;
      selectedIndex.value = i;
      let questionTitle = questionObj.value["title"];
      let questionAnswer = questionObj.value["answers"][i-1]["text"];
      let attributeName = questionObj.value["attribute"];

      mixpanel.track('Ideal Bull - Answer Clicked', { 'Page': questionTitle, 'Answer': questionAnswer });

      if(questionId() == 1){
        breedFilter.value = questionAnswer;
      }

      if (questionId() == 3) {
        api.post(ideal_bull_suggestions_path({ 'breed' : breedFilter.value , 'age' : questionAnswer}))
           .then((response)=>{suggestedBatches.value = response.data;});
      }

      if (knownCustomer.value) {
        let answer = {
          question: questionObj.value["title"],
          answer: questionObj.value["answers"][selectedIndex.value-1]["text"],
          attribute_name: questionObj.value["attribute"],
        };
        sendChatbotAnswer([answer],props.email);
      } else {
        let answer = {
          question: questionTitle,
          answer: questionAnswer,
          attribute_name: attributeName,
        };

        answers.value.push(answer);
      }
      currentQuestion.value++;
    }

    onMounted(()=>{
      setTimeout(() => {
        disable = false;
      }, 500);
    })

    return {
      questionObj,
      selected,
      selectedIndex,
      select
    }
  }
}
</script>
