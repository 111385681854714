<template>
  <div class="mb-5 mt-5 is-flex is-justify-content-center">
    <img :src="ConfirmationGif" alt="Imagem de confirmação" />
  </div>

  <div
    class="has-text-centered is-flex is-align-items-center is-flex-direction-column"
  >
    <span class="mb-2 is-size-5 has-text-weight-bold">Parabéns!</span>
    <p class="is-size-6 has-text-weight-normal mb-6">
      O Alerta de Ofertas está ativo. <br />
      Você receberá notificações em breve.
    </p>

    <button
      class="button is-warning is-fullwidth"
      @click="state.isBottomSheetOpen = false"
    >
      Fechar
    </button>
  </div>
</template>

<script>
import ConfirmationGif from "images/alert_subscription/confirmation.png";

import { state } from "./alertState";

export default {
  components: {},
  props: {},
  setup() {
    return { state, ConfirmationGif };
  },
};
</script>
