<template>
  <div v-if="state.isRegisteringBid" :class="{ 'is-loading': state.isLoading }"
    class="confirm is-flex is-justify-content-center is-align-items-center">
    <div class="loader" />
  </div>
  <div v-else class="confirm">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
    </div>

    <div class="modal-body">
      <div class="content top">
        <div class="is-flex is-justify-content-center">
          <img :src="chicoImg" alt="chico" class="chico mb-4" />
        </div>
        <p v-if="state.currentFlow == 'register'" class="title">
          Cadastro confirmado!
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'approved'"
          class="title"
        >
          Lance enviado!
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'awaiting'"
          class="title"
        >
          Pré-Lance em análise!
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'conflict'"
          class="title"
        >
          Opa, não foi possível efetuar o seu pré-lance!
        </p>
        <p v-else-if="state.currentFlow == 'proposal'" class="title">
          Proposta enviada!
        </p>

        <p v-if="state.currentFlow == 'register'" class="subtitle">
          Você deu o primeiro passo para lançar neste evento:
        </p>
        <p v-else-if="state.currentFlow == 'proposal'" class="subtitle">
          Você deu o primeiro passo para ter este lote na sua fazenda:
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'approved'"
          class="subtitle"
        >
          Agora você está concorrendo ao lote. Boa sorte!
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'awaiting'"
          class="subtitle"
        >
          Seus dados cadastrais e a sua documentação estão em análise. Em breve
          nossa equipe entrará em contato!
        </p>
        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState == 'conflict'"
          class="subtitle"
        >
          No momento, outro pré-lance está em análise por parte da nossa equipe.
          Aguarde uns instantes e tente novamente.
        </p>
      </div>

      <div class="content middle">
        <AuctionRegistrationModalAuctionCard
          v-if="state.auction && state.currentFlow == 'register'"
        />
        <AuctionRegistrationModalBatchCard
          v-if="
            state.batch &&
            (state.currentFlow == 'proposal' || state.currentFlow == 'bid')
          "
        />

        <p v-if="state.currentFlow == 'register'" class="description mt-6">
          Em breve, um dos nossos consultores entrará em contato para
          assessorá-lo com os lotes deste evento.
        </p>

        <p
          v-else-if="state.currentFlow == 'bid' && state.bidState != 'conflict'"
          class="description mt-6"
        >
          O lance poderá ser revogado caso sejam detectadas inconsistências no
          cadastro.
        </p>

        <p v-else-if="state.currentFlow == 'proposal'" class="description mt-6">
          Em breve, um dos nossos consultores entrará em contato para darmos
          continuidade à sua proposta
        </p>
      </div>

      <div class="content bottom">
        <a
          v-if="state.currentFlow == 'bid'"
          class="button is-primary is-outlined is-fullwidth"
          @click="closeModal"
        >
          Voltar para o lote
        </a>
        <a
          v-else
          :href="state.redirectButtonLink"
          class="button is-primary is-fullwidth"
        >
          Ver outros lotes
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import chicoImg from "images/chico/chico.png";

import AuctionRegistrationModalAuctionCard from "./AuctionRegistrationModalAuctionCard.vue";
import AuctionRegistrationModalBatchCard from "./AuctionRegistrationModalBatchCard.vue";

import { state, closeModal } from "./auctionRegistrationModalContext";

import { onUnmounted } from "vue";

export default {
  components: {
    AuctionRegistrationModalAuctionCard,
    AuctionRegistrationModalBatchCard,
  },
  setup() {
    onUnmounted(() => window.location.reload());

    return {
      chicoImg,
      state,
      closeModal,
    };
  },
};
</script>
