<template>
  <div
    class="card-background is-shadowless is-relative"
    :class="[category, { featured: !!link }, { open: isOpen }]"
    :href="link"
    @click="handleClick"
  >
    <div class="auction-card" :class="{'verified' : link }">
      <div class="crawled-auction" :class="link ? 'verified' : category">
        <img v-if="link" :src="banner" :alt="title" />
        <div v-else>
          <div class="crawled-info day">
            {{ day }}
          </div>
          <div class="crawled-info is-uppercase">
            {{ month }}
          </div>
          <div class="crawled-info hour">
            {{ hour }}
          </div>
          <div v-if="category === 'bovine'" class="crawled-info">
          <BovineIcon />
          </div>
          <div v-else-if="category === 'equidae'" class="crawled-info">
            <EquineIcon />
          </div>
          <div v-else-if="category === 'ovine'" class="crawled-info">
            <OvineIcon class="crawled-info"/>
          </div>
          <div v-else-if="category === 'caprine'" class="crawled-info">
            <CaprineIcon class="crawled-info"/>
          </div>
        </div>
      </div>
      <div class="auction-content">
          <div class="auction-title-container" :class="link ? 'featured' : ''">
            <span class="auction-title">
              {{ title }}
            </span>
          </div>
          <div v-if="channel || breed || age" class="auction-infos has-text-weight-medium" :class="link ? 'featured' : ''">
            <DnaIcon/>
            <span class="ml-1">{{ breed ? breed : 'Raça não especificada' }}</span>
          </div>
          <div v-if="channel || breed || age" class="auction-infos has-text-weight-medium" :class="link ? 'featured' : ''">
            <SignalIcon/>
            <span class="ml-1">{{ age ? age : 'Idade não especificada' }}</span>
          </div>
          <div v-if="link" class="auction-date is-uppercase" :class="link ? 'featured' : ''">
            <TodayIcon/>
              <div class="ml-1">
              <span>{{ day }} <span v-if="month!=end_month">{{ month }}</span></span>
              <span v-if="end_day"> a {{ end_day }}</span>
              {{ end_month }} | {{ hour }}
            </div>
          </div>
          <div v-else-if="channel || breed || age" class="auction-channel is-size-custom">
            {{ channel ? channel : 'Canal desconhecido' }}
          </div>
          <div v-if="!channel && !breed && !link" class="auction-infos no-infos has-text-weight-medium">
            Nenhuma informação disponível
            sobre as ofertas desse evento
          </div>
      </div>
      <div v-if="link" class="auction-featured">
          <VerifiedIcon/>
        </div>
    </div>
  </div>
</template>

<script>

import BovineIcon from "icons/cached/bovine.svg";
import EquineIcon from "icons/cached/equine.svg";
import OvineIcon from "icons/cached/ovine.svg";
import CaprineIcon from "icons/cached/caprine.svg";
import DnaIcon from "icons/cached/dna.svg";
import TodayIcon from "icons/cached/today.svg";
import SignalIcon from "icons/cached/signal_cellular_alt.svg";
import VerifiedIcon from "icons/cached/verified.svg";
import { ref } from "vue";
import { useCrawledAuctions } from "hooks/useCrawledAuctions";

export default {
  components: {
    BovineIcon,
    EquineIcon,
    OvineIcon,
    CaprineIcon,
    DnaIcon,
    TodayIcon,
    SignalIcon,
    VerifiedIcon,
  },

  props: {
    title: String,
    auctioneer: String,
    channel: String,
    breed: String,
    location: String,
    day: String,
    end_day: String,
    month: String,
    end_month: String,
    hour: String,
    category: String,
    date: String,
    banner: String,
    age: String,
    featured: Boolean,
    isvirtual: Boolean,
    link: String,
  },

  setup(props) {
    const {
      openPreRegModal,
    } = useCrawledAuctions();
    const isOpen = ref(false);

    const handleClick = (e) => {
      if (!props.link) {
        openPreRegModal.value = true;
      }
    };

    return {
      isOpen,
      openPreRegModal,
      handleClick,
    };
  },
};
</script>
