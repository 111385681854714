<template>
  <div class="alert-form-warning">
    <div class="warning-icon">
      <NotificationsActiveIcon />
    </div>

    <div class="warning-text has-text-centered">
      <h3 v-if="hasResults">Não encontrou o lote que procurava?</h3>
      <h3 v-else>Sem lotes disponíveis no momento</h3>
      <p>
        Ative um alerta e seja o primeiro a conferir novos lotes com
        características similares.
      </p>
    </div>

    <div class="warning-input">
      <AppInput
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="Digite seu telefone"
        type="tel"
        :required="true"
        :isSynchronized="true"
        :showMessage="false"
        :error="false"
        :value="state.userPhone"
        @input="(v) => (state.userPhone = v)"
      />
    </div>

    <button
      class="button is-warning is-fullwidth mt-5"
      :disabled="isDisabled"
      @click="startFlow"
    >
      Confirmar
    </button>
  </div>
</template>

<script>
import NotificationsActiveIcon from "icons/cached/notifications_active.svg";

import AppInput from "components/ui/AppInput.vue";
import { state, checkPhone } from "./alertState";

import { mask } from "vue-the-mask";
import { computed } from "vue";

export default {
  directives: {
    mask,
  },
  components: { NotificationsActiveIcon, AppInput },
  props: { hasResults: Boolean },
  setup() {
    const isDisabled = computed(() => {
      return !checkPhone();
    });

    const startFlow = () => {
      state.activeTab =
        state.userPhone == state.conversionData.lp_conversion.phone
          ? "registered"
          : "form";
      state.isBottomSheetOpen = true;
      state.origin = "Alert Pre Form";
      mixpanel.track("Radar - Pop Up Viewed", { Origin: state.origin });
    };

    return { state, isDisabled, startFlow };
  },
};
</script>
