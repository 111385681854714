<template>
  <div v-if="state.isModalOpen" class="modal is-active">
    <div class="modal-background" @click="closeModal" />
    <div
      class="modal-content ai-describe-animal is-flex is-justify-content-center is-align-items-center"
    >
      <div v-if="state.isLoading" :class="{ loader: state.isLoading }" />
      <div
        v-else
        class="content-container is-flex is-justify-content-space-around is-flex-direction-column is-align-items-center"
      >
        <div class="modal-header mb-4">
          <h1 class="has-text-weight-semibold">
            Esse texto foi gerado por IA. Confira e, se necessário, edite antes
            de publicar.
          </h1>
        </div>
        <div class="description mt-5 mb-4">
          <textarea
            class="is-borderless"
            placeholder="Descreva o animal"
            name="description"
            is-synchronized
            :required="true"
            v-model="state.description"
          />
        </div>
        <div
          class="buttons is-flex is-justify-content-space-evenly is-fullwidth"
        >
          <button
            class="button cancel is-outlined"
            @click="closeModal"
          >
            Cancelar
          </button>
          <button
            class="button is-warning"
            @click="publishDescription"
          >
            Publicar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  closeModal,
  publishDescription,
  state,
} from "./describeAnimalContext";

export default {
  setup() {
    return {
      closeModal,
      publishDescription,
      state,
    };
  },
};
</script>
