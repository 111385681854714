const removeAccents = (str) => {
  return str
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

const normalizedContains = (item, stringSearch) => {
  const regExp = new RegExp(removeAccents(stringSearch), "gi");
  return regExp.test(removeAccents(item));
};

const findMatching = (stringSearch, collection) => {
  return collection.filter((item) => normalizedContains(item, stringSearch));
};

export { findMatching };
