import submitChatbotAnswer from "services/submitChatbotAnswer";
import { reactive } from "vue";
import { ideal_bull_random_responsible_path } from 'utils/routes'
import api from "services/api";

import neloreBreedImg from "images/ideal_bull/answer_1.png";
import senepolBreedImg from "images/ideal_bull/answer_2.png";
import nelore1Img from "images/ideal_bull/nelore_answers/answer_1.png";
import nelore2Img from "images/ideal_bull/nelore_answers/answer_2.png";
import nelore3Img from "images/ideal_bull/nelore_answers/answer_3.png";
import nelore4Img from "images/ideal_bull/nelore_answers/answer_4.png";
import senepol1Img from "images/ideal_bull/senepol_answers/answer_1.png";
import senepol2Img from "images/ideal_bull/senepol_answers/answer_2.png";
import senepol3Img from "images/ideal_bull/senepol_answers/answer_3.png";
import senepol4Img from "images/ideal_bull/senepol_answers/answer_4.png";

const quizState = reactive({
  answers : [],
  breedFilter: '',
  currentQuestion : 1,
  prevQuestion : false,
  finishedQuiz: false,
  knownCustomer: false,
  newCustomer: {},
  suggestedBatches: [],
});

const questions = {
  "1" : {
    "type" : ['img', 'text'],
    "icon" : "",
    "attribute" : "[TOURO IDEAL] Raça",
    "title" : "Qual a raça desejada?",
    "answers" : [
      {
        "path" : neloreBreedImg,
        "text" : "Nelore"
      },
      {
        "path" : senepolBreedImg,
        "text" : "Senepol"
      }
    ]
  },
  "2": {
    "type" : ['text'],
    "icon" : "BovineIcon",
    "attribute" : "[TOURO IDEAL] Característica preconizada",
    "title" : "Como maior prioridade, o seu touro deve produzir:",
    "answers" : [
      {
        "text" : "Bezerros mais pesados"
      },
      {
        "text" : "Animais que acabam mais cedo"
      },
      {
        "text" : "Fêmeas melhores"
      },
      {
        "text" : "Animais com melhor acabamento de carcaça"
      }
    ]
  },
  "3" : {
    "type" : ['text'],
    "icon" : "BarsIcon",
    "attribute" : "[TOURO IDEAL] Idade das matrizes",
    "title" : "Qual a idade das matrizes que serão montadas pelo touro?",
    "answers" : [
      {
        "text" : "Novilha"
      },
      {
        "text" : "Vaca"
      }
    ]
  },
  "4" : {
    "type" : ['text'],
    "icon" : "DnaIcon",
    "attribute" : "[TOURO IDEAL] Sistema de reprodução",
    "title" : "Qual é o seu principal sistema de reprodução?",
    "answers" : [
      {
        "text" : "Inseminação"
      },
      {
        "text" : "Monta Natural"
      }
    ]
  },
  "5" : {
    "type" : ['text'],
    "icon" : "MoneyIcon",
    "attribute" : "[TOURO IDEAL] Valor de investimento",
    "title" : "Quanto você pretende investir neste touro?",
    "answers" : [
      {
        "text" : "12  a  15 mil reais"
      },
      {
        "text" : "18  a  21 mil reais"
      },
      {
        "text" : "24  a  27 mil reais"
      },
      {
        "text" : "Acima de 30 mil reais"
      }
    ]
  },
  "6-Nelore" : {
    "type" : ['img'],
    "icon" : "",
    "attribute" : "",
    "title" : "Selecione o touro que você escolheria apenas pela foto",
    "answers" : [
      {
        "path" : nelore1Img,
        "text" : nelore1Img,
      },
      {
        "path" : nelore2Img,
        "text" : nelore2Img,
      },
      {
        "path" : nelore3Img,
        "text" : nelore3Img,
      },
      {
        "path" : nelore4Img,
        "text" : nelore4Img,
      }
    ]
  },
  "6-Senepol" : {
    "type" : ['img'],
    "icon" : "",
    "attribute" : "",
    "title" : "Selecione o touro que você escolheria apenas pela foto",
    "answers" : [
      {
        "path" : senepol1Img,
        "text" : senepol1Img,
      },
      {
        "path" : senepol2Img,
        "text" : senepol2Img,
      },
      {
        "path" : senepol3Img,
        "text" : senepol3Img,
      },
      {
        "path" : senepol4Img,
        "text" : senepol4Img,
      }
    ]
  },
}

const sendChatbotAnswer = (answers, email) => {
  submitChatbotAnswer({
    user_conversion_email: email,
    landing_page_title: 'Touro Ideal',
    answers: JSON.stringify(answers),
  });
};

const conversionData = (data, name, email, phone, complete) => {
  return {
    lp_conversion: {
      landing_page_title: "Touro Ideal",
      name,
      email,
      phone,
      ...data.lp_conversion
    },
    additional_attributes: {
      "[TOURO IDEAL] Incompletos" : complete,
    },
  };
}

const getRandomResponsible = (email) => {
  return api.get(ideal_bull_random_responsible_path({ email }));
}

export { questions, quizState, sendChatbotAnswer, conversionData, getRandomResponsible }
