<template>
  <div class="bid">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
      <ArrowBackIcon
        v-if="state.previuosTab"
        class="arrow-back"
        @click="setActiveTab(state.previuosTab)"
      />
    </div>

    <div class="modal-body">
      <div class="content top">
        <AuctionRegistrationModalBatchCard v-if="state.batch" />
      </div>

      <div class="content middle">
        <div class="mb-5">
          <p class="title has-text-left mb-2">
            Faça uma proposta e aumente suas chances!
          </p>
        </div>

        <div class="field">
          <AppInput
            v-money="state.proposalValue != '' ? money : null"
            type="tel"
            :value="state.proposalValue"
            placeholder="Digite o valor"
            label="Valor da proposta"
            :isSynchronized="true"
            @input="(value) => (state.proposalValue = value)"
            @change="(value) => unMaskMoney(value)"
          />
        </div>

        <div class="field">
          <label class="message-label">Condições de negociação</label>
          <textarea
            :value="state.proposalConditions"
            class="textarea"
            placeholder="Informe alguma condição que ajude na negociação deste lote. Ex: Pagamento à vista"
            @input="state.proposalConditions = $event.target.value"
          />
        </div>
      </div>

      <div class="content bottom">
        <button
          class="button is-primary gavel-bt"
          :disabled="
            state.proposalValue == '' || state.proposalConditions == ''
          "
          @click="createConversion()"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import chicoImg from "images/chico/chico.png";

import AppInput from "components/ui/AppInput.vue";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AuctionRegistrationModalBatchCard from "./AuctionRegistrationModalBatchCard.vue";

import { VMoney } from "v-money";

import {
  state,
  setActiveTab,
  closeModal,
  createConversion,
} from "./auctionRegistrationModalContext";

import { mask } from "vue-the-mask";

export default {
  components: {
    AppInput,
    ArrowBackIcon,
    AuctionRegistrationModalBatchCard,
  },
  directives: {
    money: VMoney,
    mask,
  },
  setup() {
    const money = {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    };

    const unMaskMoney = (value) => {
      if (value.target.value == "R$ 0,00") {
        value.target.value = "";
      }
    };

    return {
      chicoImg,
      state,
      setActiveTab,
      closeModal,
      money,
      unMaskMoney,
      createConversion,
    };
  },
};
</script>
