<template>
  <div v-if="slides.length > 1" class="swiper-button-content">
    <div class="swiper-button-next">
      <ChevronRight />
    </div>
    <div class="swiper-button-prev">
      <ChevronLeft />
    </div>
  </div>

  <swiper
    v-if="slides.length > 0"
    ref="main"
    :pagination="slides.length > 1 ? { clickable: true } : undefined"
    :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }"
    @swiper="(s) => (swiperEl = s)"
    @slideChange="(e) => (pauseVideos(e) && updateThumb(e))"
  >
    <swiper-slide
      v-for="(slide, index) in slides"
      :key="index"
      class="slide"
    >
      <figure class="image is-5by3">
        <YoutubePlayer
          v-if="slide.type == 'video'"
          :ref="slidePlayersWrapper.slidePlayers"
          :videoId="slide.video_id"
          :previewUrl="slide.cover_url"
          :customClasses="['has-ratio']"
          :customPreviewClasses="['has-ratio']"
          :autoplay="hasVideoAutoplay && index == 0"
          preview
          loop
        />

        <img v-else class="slide" :src="slide.cover_url" />
      </figure>
    </swiper-slide>
  </swiper>

  <figure v-else class="image is-5by3">
    <img class="slide" :src="noVideoImage" />
  </figure>

  <div v-if="slides.length > 1" class="thumbnails">
    <div
      v-for="(slide, index) in slides"
      :key="index"
      :class="{ current: index == current }"
      @click="current = index"
    >
      <img
        :alt="`Miniatura mídia ${index + 1}`"
        :src="slide.thumb_url"
        :class="`slide-thumbnail slide--${index}`"
        @click="swiperEl.slideTo(index)"
      />
    </div>
  </div>
</template>
<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import noVideoImage from "images/no-image-thumb-large.png";

import YoutubePlayer from "components/ui/YoutubePlayer.vue";

import ChevronLeft from "icons/cached/chevron_left.svg";
import ChevronRight from "icons/cached/chevron_right.svg";
import VolumeIcon from "icons/cached/volume_up.svg";
import VolumeOffIcon from "icons/cached/volume_off.svg";
import { onMounted, ref } from "@vue/runtime-core";

SwiperCore.use([Pagination, Navigation]);

export default {
  components: {
    VolumeIcon,
    VolumeOffIcon,
    ChevronLeft,
    ChevronRight,
    SwiperSlide,
    YoutubePlayer,
    swiper: Swiper,
  },

  props: {
    slides: Array,
    hasVideoAutoplay: { type: Boolean, default: true },
  },

  setup() {
    const main = ref(null);
    const current = ref(0);
    const swiperEl = ref(null);

    const slidePlayers = ref([]);
    const slidePlayersWrapper = { slidePlayers };

    const updateThumb = (e) => {
      current.value = e.snapIndex;
    };

    const pauseVideos = (e) => {
      slidePlayers.value.forEach((player) => player.pause());
    };

    return {
      updateThumb,
      pauseVideos,
      current,
      main,
      swiperEl,
      slidePlayersWrapper,
      noVideoImage,
    };
  },
};
</script>
