<template>
  <button
    :class="{ 'hunter-share-bt': true, active: openModal }"
    @click="openModal = !openModal"
  >
    <ShareIcon />
  </button>

  <div
    v-if="openModal"
    class="is-overlay hunter-share-overlay"
    @click="openModal = !openModal"
  />

  <div v-if="openModal" class="hunter-share-modal">
    <p>
      Aqui está o link para compartilhar com os possíveis clientes. Os
      pré-cadastros feitos por usuários que acessarem a erural através deste
      link virão com a informação de que foram convidados por você.
    </p>
    <textarea
      ref="linkTextarea"
      v-model="shareUrl"
      class="textarea is-small mb-4"
      rows="2"
      readonly
    />

    <div class="button-section">
      <button
        class="button is-small is-primary is-outlined mb-2"
        @click="copyLink"
      >
        {{ copyText }}
      </button>

      <a
        class="button is-small is-primary"
        :href="whatsappShareLink"
        target="_blank"
      >
        <WhatsappIcon />
        Compartilhar no Whatsapp
      </a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import ShareIcon from "icons/cached/share.svg";
import WhatsappIcon from "icons/cached/whatsapp.svg";

export default {
  components: {
    ShareIcon,
    WhatsappIcon,
  },
  props: {
    hunterId: Number,
  },
  setup(props) {
    const openModal = ref(false);

    const linkTextarea = ref(null);

    const url = new URL(window.location.href);
    url.searchParams.append("hunter_id", props.hunterId);
    const shareUrl = url.toString();

    const whatsappShareParam = encodeURIComponent(
      'Fala meu amigo, como vai?\n' +
      'Tenho uma oportunidade na erural que acredito que pode te interessar:\n' +
      shareUrl + '\n' +
      'Cadastre-se através do meu link acima que, além de uma assessoria gratuita, você terá um atendimento preferencial.\n' +
      'Qualquer dúvida, pode falar comigo!',
    );
    const whatsappShareLink = `https://api.whatsapp.com/send?text=${whatsappShareParam}`;

    const copyText = ref("Copiar link");
    const copyLink = () => {
      linkTextarea.value.select();
      document.execCommand("copy");

      copyText.value = "Link copiado!";
      setTimeout(() => (copyText.value = "Copiar link"), 2000);
    };

    return {
      openModal,
      linkTextarea,
      shareUrl,
      whatsappShareLink,
      copyText,
      copyLink,
    };
  },
};
</script>
