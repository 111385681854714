<template>
  <component :is="page" />
</template>

<script>
import { onMounted } from "vue";
import { page, changePage } from "./currentPage";

import GeneralPage from "./GeneralPage.vue";
import MessagePage from "./MessagePage.vue";
import AddressPage from "./AddressPage.vue";

export default {
  components: {
    GeneralPage,
    MessagePage,
    AddressPage,
  },
  setup() {
    onMounted(() => {
      changePage("GeneralPage");
    });
    return {
      page,
    };
  },
};
</script>

<style></style>
