<template>
  <a
    v-for="card in CARDS"
    class="redirect-card is-flex is-align-items-center is-justify-content-space-between mb-4 p-3"
    :href="card.redirect"
    :id="card.id"
  >
    <div class="is-flex">
      <component :is="card.icon" class="mr-2" />
      <div class="content-area">
        <h3>{{ card.title }}</h3>
        <p>{{ card.description }}</p>
      </div>
    </div>

    <ChevronRightIcon class="chevron" />
  </a>
</template>

<script>
import ChevronRightIcon from "icons/cached/chevron_right.svg";
import RecommendFilledIcon from "icons/cached/recommend_filled.svg";
import ManageSearchIcon from "icons/cached/manage_search.svg";

import {
  purchase_preferences_index_url,
  my_shoppings_url,
  purchase_preferences_purchase_profiles_url,
} from "utils/routes";
import { onMounted } from "vue";

export default {
  components: {
    ChevronRightIcon,
    RecommendFilledIcon,
    ManageSearchIcon,
  },
  props: {
    hasPreferences: Boolean,
  },
  setup(props) {
    const CARDS = [
      {
        title: "Minhas recomendações",
        description:
          "Recomendamos lotes baseados em suas preferências de compras",
        icon: "RecommendFilledIcon",
        redirect: props.hasPreferences
          ? purchase_preferences_index_url()
          : purchase_preferences_purchase_profiles_url(),
        mixpanel_section: "Personalized Recommendations",
        id: "personalizedRecommendationsCard",
      },
      {
        title: "Histórico de compras",
        description:
          "Veja suas compras anteriores e o que está em andamento com a erural",
        icon: "ManageSearchIcon",
        redirect: my_shoppings_url(),
        mixpanel_section: "Purchase History",
        id: "purchaseHistoryCard",
      },
    ];

    onMounted(() => {
      CARDS.forEach((card) => {
        mixpanel.track_links(`#${card.id}`, "Client Menu Clicked", {
          Page: "Client Menu",
          Section: card.mixpanel_section,
        });
      });
    });

    return {
      CARDS,
    };
  },
};
</script>
