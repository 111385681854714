<template>
  <div v-if="infos.length > 0" class="recommendation-page">
    <div class="recommendation-header">
      <div v-if="!batchesLoading && !lastPage" class="header-content">
        <div class="is-flex is-align-items-center">
          <Verified class="verified-icon" />
          <div class="header-text">
            <span class="header-subtitle">Recomendado para você:</span>
            <b class="header-title">
              {{ infos[currentAnimal].auction_title }}
            </b>
          </div>
        </div>
        <ReplayIcon
          v-if="hfLink"
          class="replay-icon"
          @click="showRecommendationModal = true"
        />
      </div>

      <div
        v-if="lastPage && favoriteAnimals.length > 0"
        class="is-flex is-align-items-center py-5"
      >
        <Verified class="verified-icon mr-2" />
        <b class="header-title"> Recomendações finalizadas </b>
      </div>

      <div v-if="batchesLoading" class="py-5">
        <span class="header-title">Buscando outro lote...</span>
      </div>
    </div>

    <div class="container">
      <RecommendationCard
        v-if="!batchesLoading && !lastPage"
        :animal="infos[currentAnimal]"
      />

      <div
        v-if="lastPage && favoriteAnimals.length > 0"
        class="recommendation-card"
      >
        <div class="card-content-last">
          <img :src="chicoImg" alt="Imagem Chico" class="card-img" />

          <b>
            Parece que você explorou todas as nossas recomendações. Você se
            interessou por {{ favoriteAnimals.length }}
            {{ favoriteAnimals.length > 1 ? "lotes" : "lote" }}!
          </b>

          <p>
            Um consultor irá entrar em contato para te ajudar a adquirir estes
            animais.
          </p>
        </div>
      </div>

      <div v-if="batchesLoading" class="loading-product">
        <img class="loading-product-img" :src="LoadingProduct" alt="Loading" />
      </div>

      <div
        v-if="!resultLoading && !batchesLoading && !lastPage"
        class="recommendation-buttons"
      >
        <button
          class="button is-warning is-fullwidth mb-2"
          :class="{ 'is-loading': secondConversionLoading }"
          :disabled="isDisabled"
          @click="likeAnimal"
        >
          <Favorite class="favorite-icon mr-4" />
          <span v-if="isDisabled">Lote salvo na lista</span>
          <span v-else>Favoritar este lote</span>
        </button>
        <button
          class="button is-outlined is-warning is-fullwidth"
          @click="nextAnimal"
        >
          <Search class="replay-icon mr-4" />
          <span>Próxima recomendação</span>
        </button>
      </div>

      <div
        v-if="lastPage && favoriteAnimals.length > 0"
        class="recommendation-buttons"
      >
        <a
          href="https://www.erural.net/wpp/atendimentomeulote"
          target="_blank"
          class="button is-primary mb-2 is-fullwidth"
        >
          <WhatsappIcon class="favorite-icon mr-3" />
          Falar com um especialista
        </a>
        <a
          class="button is-fullwidth is-warning is-outlined"
          @click="backInitial"
        >
          <ReplayIcon class="replay-icon mr-3" />
          Voltar para o início
        </a>
      </div>
    </div>

    <div v-if="resultLoading" class="loading-result">
      <div
        v-if="textLoading === 'Concluído!'"
        class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
      >
        <img :src="Done" class="mb-6" alt="Loading" />
        <span class="done">{{ textLoading }}</span>
      </div>
      <div
        v-else
        class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
      >
        <img :src="LoadingResult" alt="Loading" />
        <span>{{ textLoading }}</span>
      </div>
    </div>

    <div
      class="modal modal-recommendation"
      :class="{ 'is-active': isFormsLike }"
    >
      <div class="modal-background" @click="isFormsLike = !isFormsLike" />
      <div class="modal-content">
        <div class="modal-header">
          <ArrowBackIcon @click="isFormsLike = !isFormsLike" />
        </div>
        <div class="modal-top">
          <div class="modal-icon">
            <Favorite />
          </div>
          <b>Ótimo que gostou!</b>
          <p>
            Deixe o seu contato e um de nossos consultores te enviará uma lista
            com todos os lotes que você se interessar.
          </p>
        </div>
        <RecommendationForm
          :name="lpConversion.lp_conversion.name"
          :email="lpConversion.lp_conversion.email"
          :phone="lpConversion.lp_conversion.phone"
          :animals="infos"
          page="Recommendation"
          :likeModal="true"
          :lpConversion="lpConversion"
          :trackData="trackData"
          @converted="firstUserConversion"
        />
      </div>
    </div>

    <div
      class="modal modal-recommendation"
      :class="{ 'is-active': lastPage && favoriteAnimals.length == 0 }"
    >
      <div class="modal-background" />
      <div class="modal-content">
        <div class="modal-top">
          <img :src="chicoSad" alt="Chico triste" class="chico-icon" />
          <b>Nenhuma outra opção no momento</b>
          <p>
            Mas nossa equipe de especialistas está pronta para oferecer um
            atendimento personalizado!
          </p>
        </div>
        <RecommendationForm
          :name="lpConversion.lp_conversion.name"
          :email="lpConversion.lp_conversion.email"
          :phone="lpConversion.lp_conversion.phone"
          :animals="infos"
          page="Recommendation"
          :likeModal="false"
          :lpConversion="lpConversion"
          :trackData="trackData"
          @initial="backInitial"
        />
      </div>
    </div>

    <div class="modal pop-up" :class="{ 'is-active': popUp }">
      <div class="modal-background" @click="popUp = !popUp" />
      <div class="modal-content">
        <CloseIcon class="close-modal" @click="popUp = !popUp" />
        <Favorite class="favorite-icon" />
        <b>Salvo em sua lista!</b>
        <p>Em breve te enviaremos sua lista de favoritos por Whatsapp.</p>
      </div>
    </div>

    <RecommendationModal
      v-if="showRecommendationModal"
      :hfLink="hfLink"
      @close="showRecommendationModal = false"
    />
  </div>

  <div v-else class="recommendation-page">
    <div class="recommendation-header">
      <div class="is-flex is-align-items-center py-5">
        <Verified class="verified-icon mr-2" />
        <b class="header-title"> Recomendações finalizadas </b>
      </div>
    </div>
    <div class="container">
      <div class="recommendation-card">
        <div class="card-content-last">
          <img :src="chicoSad" alt="Imagem Chico" class="card-img" />

          <b>Nenhuma opção encontrada no momento </b>

          <p>
            Que tal reavaliar algumas de suas respostas? (Uma sugestão: leve em
            consideração idade e pretensão de investimento.)
          </p>
        </div>
      </div>
      <div class="recommendation-buttons">
        <a
          class="button is-fullwidth is-warning is-outlined"
          :href="hfLink ? 'https://' + hfLink : 'https://meulote.erural.net'"
        >
          <ReplayIcon class="replay-icon mr-3" />
          Refazer questionário
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import chicoImg from "images/chico/chico.png";
import chicoSad from "images/chico/chico_sad_face.png";
import Done from "images/recommendation/loading_done.gif";
import LoadingResult from "images/recommendation/loading_recommendation_result.gif";
import LoadingProduct from "images/recommendation/loading_recommendation_product.gif";

import Search from "icons/cached/search.svg";
import Verified from "icons/cached/verified.svg";
import Favorite from "icons/cached/favorite.svg";
import WhatsappIcon from "icons/cached/whatsapp.svg";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import CloseIcon from "icons/cached/close.svg";
import ReplayIcon from "icons/cached/replay.svg";

import RecommendationForm from "./RecommendationForm.vue";
import RecommendationCard from "./RecommendationCard.vue";
import RecommendationModal from "./RecommendationModal.vue";

import {
  currentAnimal,
  favoriteAnimals,
  markAsFavorite,
  sendWhatsappNotification,
  transformLikedAnimalProperties,
} from "./recommendationState";

import { ref, computed } from "vue";

import api from "services/api";
import { chatbot_answers_create_from_chatbot_url } from "utils/routes";

export default {
  components: {
    Search,
    Verified,
    Favorite,
    WhatsappIcon,
    ArrowBackIcon,
    CloseIcon,
    ReplayIcon,
    RecommendationCard,
    RecommendationForm,
    RecommendationModal,
  },
  props: {
    infos: Array,
    lpConversion: Object,
    trackData: Object,
    startConverted: {
      type: Boolean,
      default: false,
    },
    hfLink: String,
  },
  setup(props) {
    const popUp = ref(false);
    const showRecommendationModal = ref(false);
    const lastPage = ref(false);
    const isDisabled = computed(() => {
      return (
        isConverted.value &&
        favoriteAnimals.value.find(
          (animal) =>
            animal.batch_id === props.infos[currentAnimal.value].batch_id,
        )
      );
    });
    const isFormsLike = ref(false);
    const isConverted = ref(false);
    const resultLoading = ref(true);
    const batchesLoading = ref(false);
    const textLoading = ref("Procurando animais...");

    const firstUserConversion = () => {
      isFormsLike.value = false;
      isConverted.value = true;
    };

    const secondConversionLoading = ref(false);

    const sendConversionToCRM = () => {
      secondConversionLoading.value = true;

      let favoriteAsAnswers = favoriteAnimals.value.map((el, index) => {
        return {
          question: `Animal curtido ${index + 1}`,
          answer: `Lote ${el.batch_number} - ${el.auction_title}${
            el.batch_register ? " - " + el.batch_register : ""
          }`,
        };
      });

      const phone = props.lpConversion.lp_conversion.phone.replace(
        /[()-\s]/g,
        "",
      );

      sendWhatsappNotification(props.lpConversion.lp_conversion.name, phone);

      const conversionData = {
        user_conversion_email: props.lpConversion.lp_conversion.email,
        landing_page_title: props.lpConversion.lp_conversion.landing_page_title,
        answers: JSON.stringify(favoriteAsAnswers),
        batch_id: favoriteAnimals.value.at(-1).batch_id,
      };

      api
        .post(chatbot_answers_create_from_chatbot_url(), conversionData)
        .finally(() => {
          popUp.value = true;
          secondConversionLoading.value = false;
        });
    };

    const likeAnimal = () => {
      mixpanel.track("Recommendation - Like animal", props.trackData);
      analytics.track(
        "Favoritou MeuLote",
        transformLikedAnimalProperties({
          ...props.infos[currentAnimal.value],
          ...props.trackData,
        }),
      );

      if (isConverted.value) {
        popUp.value = true;
        markAsFavorite(props.infos[currentAnimal.value]);
        sendConversionToCRM();
      } else {
        isFormsLike.value = true;
      }
    };

    const nextAnimal = () => {
      batchesLoading.value = true;
      currentAnimal.value += 1;
      mixpanel.track("Recommendation - Next animal", props.trackData);

      if (currentAnimal.value >= props.infos.length) {
        currentAnimal.value -= 1;
        lastPage.value = true;
        batchesLoading.value = false;
      } else {
        setTimeout(() => {
          batchesLoading.value = false;
        }, 500);
      }
    };

    if (props.startConverted) {
      firstUserConversion();
    }

    const backInitial = () => {
      batchesLoading.value = true;
      lastPage.value = false;
      currentAnimal.value = 0;
      isConverted.value = true;

      setTimeout(() => {
        batchesLoading.value = false;
      }, 500);
    };

    setTimeout(() => {
      textLoading.value = "Calculando recomendações...";
    }, 2000);

    setTimeout(() => {
      textLoading.value = "Concluído!";
    }, 4000);

    setTimeout(() => {
      resultLoading.value = false;
    }, 6000);

    return {
      chicoImg,
      chicoSad,
      Done,
      LoadingResult,
      LoadingProduct,
      secondConversionLoading,
      popUp,
      showRecommendationModal,
      lastPage,
      isDisabled,
      isFormsLike,
      isConverted,
      resultLoading,
      batchesLoading,
      currentAnimal,
      favoriteAnimals,
      textLoading,
      firstUserConversion,
      likeAnimal,
      nextAnimal,
      backInitial,
    };
  },
};
</script>
