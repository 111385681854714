import api from "services/api";
import { app_send_facebook_conversion_path } from "utils/routes";
import { generateUUID } from "utils/uuidGenerator";

const trackFacebookBrowser = (uuid, event_name) => {
  if (!window.fbq) return;

  return window.fbq("trackCustom", event_name, {}, { eventID: uuid });
};

const trackFacebookServer = (uuid, event_name, customer_id) => {
  return api.post(app_send_facebook_conversion_path(), {
    uuid,
    event_name,
    customer_id,
  });
};

const trackFacebookWithRedundancy = (event_name, customer_id) => {
  const uuid = generateUUID();

  trackFacebookBrowser(uuid, event_name);
  trackFacebookServer(uuid, event_name, customer_id);
};

export {
  trackFacebookServer,
  trackFacebookBrowser,
  trackFacebookWithRedundancy,
};
