<template>
  <a id="shareMobileLink" :href="whatsappAuctionUrl">
    <p class="circle is-inline-flex">
      <ShareIcon />
    </p>
    <p class="title has-text-weight-medium">Compartilhar</p>
  </a>
</template>

<script>
import ShareIcon from "icons/cached/share.svg";

export default {
  components: {
    ShareIcon,
  },
  props: {
    auctionTitle: String,
    auctionUrl: String,
  },
  setup(props) {
    const whatsappAuctionUrl = `whatsapp://send?text=${encodeURI(
      `Veja na erural ${props.auctionTitle} em: `,
    )}%20${props.auctionUrl}`;

    return {
      whatsappAuctionUrl,
    };
  },
};
</script>
