<template>
  <div class="phone-section">
    <img
      class="notification-gif"
      :src="ModalNotificationGif"
      alt="Sino de notificação"
    />

    <p class="phone-section-text column">
      Preencha o seu número para <span> ativar um alerta </span> e receber
      notificações no seu Whatsapp de novos lotes com as características que
      você procura.
    </p>

    <div class="phone-section-input">
      <AppInput
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="Digite seu telefone"
        type="tel"
        :value="state.userPhone"
        :showMessage="false"
        :error="false"
        @input="(v) => (state.userPhone = v)"
      />
    </div>

    <button
      class="button is-warning is-fullwidth mt-5"
      :disabled="isDisabled"
      @click="nextTab"
    >
      Confirmar
    </button>
  </div>
</template>

<script>
import ModalNotificationGif from "images/alert_subscription/modal_notification.gif";

import { mask } from "vue-the-mask";
import AppInput from "components/ui/AppInput.vue";
import { computed } from "vue";

import { state, checkPhone, removeAnimation } from "./alertState";

export default {
  directives: {
    mask,
  },
  components: { AppInput },
  props: {},
  setup() {
    const isDisabled = computed(() => {
      return !checkPhone();
    });

    const nextTab = () => {
      state.activeTab =
        state.userPhone == state.conversionData.lp_conversion.phone &&
        state.registered
          ? "registered"
          : "form";
      removeAnimation();
    };

    return { state, ModalNotificationGif, isDisabled, nextTab };
  },
};
</script>
