<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('email')"
      />
      <span class="title">3/6</span>

      <p class="description">{{ title }}</p>

      <div class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            type="tel"
            v-mask="'###.###.###-##'"
            placeholder="Digite o seu CPF"
            required
            :value="cpf"
            @input="(v) => (cpf = v)"
            @enterPressed="$refs.confirmBt.click()"
            :error="cpfError"
            :show-message="cpfError"
            :message="cpfErrorMessage"
          />
        </div>
      </div>

      <div class="button-container">
        <button
          v-if="cpfError"
          class="button is-outlined"
          @click="recoverPassword"
        >
          Esqueci minha senha
        </button>

        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          @click="nextStep"
        >
          Próximo
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('login_review')"
        >
          Já tenho uma conta
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { onMounted, ref, computed, watch } from "vue";
import { cpfValidate } from "utils/documentValidate";
import { mask } from "vue-the-mask";
import api from "services/api";
import { users_name_by_login_path } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
    AppInput,
    FacebookAuthButton,
  },
  directives: {
    mask,
  },
  setup() {
    const { cpf, updateAuthUser, updateActiveAuthStep } =
      useAuthInfos();

    const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
    const isDisabled = computed(() => !cpf.value || !cpfRegex.test(cpf.value));

    const title = ref("");
    onMounted(
      () =>
        (title.value = cpf.value ? "Confirme o seu CPF" : "Qual o seu CPF?"),
    );

    const cpfError = ref(false);
    const cpfErrorMessage = ref("");
    watch(cpf, () => (cpfError.value = false));

    const submitting = ref(false);
    const nextStep = () => {
      const unmaskedCpf = cpf.value.replace(/[^0-9]/g, "");
      if (!cpfValidate(unmaskedCpf)) {
        cpfError.value = true;
        cpfErrorMessage.value = 'CPF inválido. Verifique se digitou corretamente.';
        return;
      }

      submitting.value = true;

      api
        .post(users_name_by_login_path(), { login: cpf.value })
        .then(() => {
          cpfError.value = true;
          cpfErrorMessage.value = "Já existe uma conta com este CPF na erural.";
        })
        .catch(() => updateActiveAuthStep("phone"))
        .finally(() => (submitting.value = false));
    };

    const recoverPassword = () => {
      updateAuthUser({ login: cpf.value });
      updateActiveAuthStep("password_recovery");
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      cpf,
      title,
      cpfError,
      cpfErrorMessage,
      submitting,
      nextStep,
      recoverPassword,
    };
  },
};
</script>
