<template>
  <div class="customer-referral-link-input">
    <AppInput
      ref="linkInput"
      :show-label="false"
      readonly
      is-synchronized
      :value="url"
      @input="
        (value) => {
          url = value;
        }
      "
    />

    <button class="button" :class="{ filled: copyCommand }" @click="copyLink">
      {{ copyText }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import AppInput from "components/ui/AppInput.vue";

export default {
  components: {
    AppInput,
  },
  props: {
    customerId: Number,
  },
  setup(props) {
    const linkInput = ref(null);

    const url = new URL("https://agronegocio.erural.net/boasvindas-erural");
    url.searchParams.append("indicator_id", props.customerId);

    let mixpanelData = {
      "Referral Link": url.href,
    };

    const copyCommand = ref(false);
    const copyText = ref("Copiar link");

    const copyLink = () => {
      linkInput.value.inputEl.select();
      document.execCommand("copy");

      window.getSelection().removeAllRanges();

      copyText.value = "Link copiado!";
      copyCommand.value = true;

      setTimeout(() => {
        copyText.value = "Copiar link";
        copyCommand.value = false;
      }, 2000);

      mixpanel.track("Referral Link Copied", mixpanelData);
    };

    return {
      url,
      copyLink,
      copyText,
      linkInput,
      copyCommand,
    };
  },
};
</script>
