<template>
  <button
    @click="openModalAndTrack()"
    class="action-button"
  >
    <div class="bt is-flex is-justify-content-center">
      <SearchIcon />
    </div>

    <span class="has-text-weight-medium">Mais detalhes</span>
  </button>

  <AuctionInfoModal
    :open="openInfoModal"
    :auction="auction"
    :auctionFiles="auctionFiles"
    :mixpanelData="mixpanelData"
    @close="() => (openInfoModal = !openInfoModal)"
  />
</template>

<script>
import SearchIcon from "icons/cached/search.svg";
import AuctionInfoModal from "components/auction/AuctionInfoModal.vue";

import { ref } from "vue";

export default {
  components: {
    SearchIcon,
    AuctionInfoModal,
  },
  props: {
    auction: Object,
    auctionFiles: Object,
    mixpanelData: Object
  },
  setup(props) {
    const openInfoModal = ref(false);

    const openModalAndTrack = () => {
      openInfoModal.value = true;
      mixpanel.track('More Info Clicked', {
        'Page': 'Product',
        'Section': 'Product CTAs',
        ...props.mixpanelData
      });
    }

    return {
      openInfoModal,
      openModalAndTrack,
    };
  },
};
</script>
