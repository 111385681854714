<template>
  <div
    v-if="state.isModalOpen"
    class="modal auction-registration-modal is-active"
  >
    <div class="modal-background" @click="closeModal" />
    <div class="modal-content">
      <component :is="steps[state.activeTab]" />
    </div>
  </div>
</template>

<script>
import AuctionRegistrationModalIdentification from "./AuctionRegistrationModalIdentification.vue";
import AuctionRegistrationModalConfirm from "./AuctionRegistrationModalConfirm.vue";
import AuctionRegistrationModalInitial from "./AuctionRegistrationModalInitial.vue";
import AuctionRegistrationModalBid from "./AuctionRegistrationModalBid.vue";
import AuctionRegistrationModalMessage from "./AuctionRegistrationModalMessage.vue";
import AuctionRegistrationModalProposal from "./AuctionRegistrationModalProposal.vue";
import AuctionRegistrationModalProposalIdentification from "./AuctionRegistrationModalProposalIdentification.vue";

import { state, closeModal } from "./auctionRegistrationModalContext";
import { onMounted } from "vue";

export default {
  components: {
    AuctionRegistrationModalIdentification,
    AuctionRegistrationModalConfirm,
    AuctionRegistrationModalInitial,
    AuctionRegistrationModalBid,
    AuctionRegistrationModalMessage,
    AuctionRegistrationModalProposal,
    AuctionRegistrationModalProposalIdentification,
  },
  props: {
    batch: Object,
    auction: Object,
  },
  setup(props) {
    const steps = {
      identification: "AuctionRegistrationModalIdentification",
      confirm: "AuctionRegistrationModalConfirm",
      initial: "AuctionRegistrationModalInitial",
      bid: "AuctionRegistrationModalBid",
      message: "AuctionRegistrationModalMessage",
      proposal: "AuctionRegistrationModalProposal",
      proposalIdentification: "AuctionRegistrationModalProposalIdentification",
    };

    onMounted(() => {
      state.batch = props.batch;
      state.auction = props.auction;
    });

    return {
      steps,
      state,
      closeModal,
    };
  },
};
</script>
