<template>
  <transition-group :name="transitionName">
    <BatchReel
      v-for="reel in visibleReels"
      :key="reel.batch.id"
      :reel="reel"
      :totalReels="batchList.length"
      :mixpanelData="reel.batch.mixpanel_data"
      @previous="switchPrevReel"
      @next="switchNextReel"
      @interest="openPreRegModal = true"
    />
  </transition-group>

  <AuctionPreRegModal
    :key="currentBatch.id"
    :cta="'Ir para informações'"
    page="Reels"
    :name="customerData.name"
    :email="customerData.email"
    :phone="customerData.phone"
    :lpConversion="batchConversionData"
    :trackData="trackData"
    :mixpanelData="currentBatch.mixpanel_data"
    :open="openPreRegModal"
    :bannerBtn="false"
    :redirectLink="
      batches_show_path(
        currentBatch.auction.url_friendly,
        currentBatch.url_friendly,
      )
    "
    @close="openPreRegModal = false"
  />

  <ReelsEndModal
    v-if="endModalOpen"
    :mixpanelData="currentBatch.mixpanel_data"
    @close="endModalOpen = false"
    @restart="restartReels"
  />
</template>

<script>
import { ref, reactive, computed, watch } from "vue";

import BatchReel from "components/reels/BatchReel.vue";
import AuctionPreRegModal from "components/auction/AuctionPreRegModal.vue";
import ReelsEndModal from "components/reels/ReelsEndModal.vue";

import { auctions_show_reels_path, batches_show_path } from "utils/routes";

export default {
  components: {
    BatchReel,
    AuctionPreRegModal,
    ReelsEndModal,
  },
  props: {
    batchList: Array,
    initialBatch: Object,
    customerData: Object,
    conversionData: Object,
    trackData: Object,
  },
  setup(props) {
    const batchToReel = (batch) => {
      let index = props.batchList.findIndex((el) => el.id == batch.id);
      return {
        batch: batch,
        index: index,
        prevReelUrl:
          index > 0
            ? auctions_show_reels_path(
                batch.auction.url_friendly,
                props.batchList[index - 1].number,
              )
            : null,
        nextReelUrl:
          index < props.batchList.length - 1
            ? auctions_show_reels_path(
                batch.auction.url_friendly,
                props.batchList[index + 1].number,
              )
            : null,
      };
    };

    const visibleReels = reactive([batchToReel(props.initialBatch)]);
    const transitionName = ref(null);

    const currentReel = computed(() => visibleReels[0]);
    const currentBatch = computed(() => currentReel.value.batch);
    const currentIndex = computed(() => currentReel.value.index);

    const prevBatch = computed(() => props.batchList[currentIndex.value - 1]);
    const nextBatch = computed(() => props.batchList[currentIndex.value + 1]);

    const switchPrevReel = () => {
      if (!prevBatch.value) {
        endModalOpen.value = true;
        return;
      }

      mixpanel.track("Reels - Previous Reel", {
        ...currentBatch.value.mixpanel_data,
        "Previous Batch": prevBatch.value.number,
      });

      transitionName.value = "previous";
      visibleReels.push(batchToReel(prevBatch.value));
      visibleReels.shift();
    };

    const switchNextReel = () => {
      if (!nextBatch.value) {
        endModalOpen.value = true;
        return;
      }

      mixpanel.track("Reels - Next Reel", {
        ...currentBatch.value.mixpanel_data,
        "Next Batch": nextBatch.value.number,
      });

      transitionName.value = "next";
      visibleReels.push(batchToReel(nextBatch.value));
      visibleReels.shift();
    };

    const restartReels = () => {
      endModalOpen.value = false;
      mixpanel.track("Reels - Restart Reels", currentBatch.value.mixpanel_data);

      if (currentIndex.value == 0) {
        return;
      }

      transitionName.value = "previous";
      visibleReels.push(batchToReel(props.batchList[0]));
      visibleReels.shift();
    };

    watch(currentBatch, (value) => {
      window.history.replaceState(
        "",
        "",
        auctions_show_reels_path(value.auction.url_friendly, value.number),
      );
      document.title = `Lote ${value.number} - Reels - ${value.auction.title}`;
    });

    const batchConversionData = computed(() => {
      let data = props.conversionData;

      data.batch_id = currentBatch.value.id;
      data.additional_infos = [
        { question: "Origem", answer: `Lote ${currentBatch.value.number}` },
        {
          question: "Página",
          answer: auctions_show_reels_path(
            currentBatch.value.auction.url_friendly,
            currentBatch.value.number,
          ),
        },
      ];

      return data;
    });

    const openPreRegModal = ref(false);
    watch(openPreRegModal, (value) => {
      if (value)
        mixpanel.track(
          "Reels - Interest Button Clicked",
          currentBatch.value.mixpanel_data,
        );
    });

    const endModalOpen = ref(false);

    return {
      visibleReels,
      transitionName,
      currentReel,
      currentBatch,
      switchPrevReel,
      switchNextReel,
      restartReels,
      batchConversionData,
      openPreRegModal,
      endModalOpen,
      batches_show_path,
    };
  },
};
</script>
