<template>
  <h4 class="is-title">Olá, {{ name }}!</h4>
</template>

<script>
export default {
  props: {
    _name: String,
  },
  computed: {
    name() {
      return this._name || "produtor";
    },
  },
};
</script>
