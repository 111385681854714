<template>
  <div
    class="image profile-picture is-flex is-justify-content-center is-align-items-center is-clipped is-relative"
  >
    <div
      v-if="!hasThumb"
      class="is-flex is-flex-direction-column is-align-items-center"
    >
      <AddPhotoIcon />
      <div
        class="has-text-weigth-semibold px-2 has-text-centered is-hidden-mobile"
      >
        Adicione uma foto
      </div>
    </div>
    <img v-else :src="thumbImage" alt="" class="is-rounded" />

    <input
      v-if="!onlyVisual"
      class="file-input"
      type="file"
      name="user[avatar]"
      accept="image/*"
      @change.prevent="handleFileUpload"
    />

    <button ref="submitButton" class="is-hidden" type="submit" />
  </div>
</template>

<script>
import AddPhotoIcon from "icons/cached/add_a_photo.svg";
import { userState } from "./userContext";
import { onMounted, ref, toRefs, watch } from "vue";

export default {
  components: {
    AddPhotoIcon,
  },

  props: {
    onlyVisual: Boolean,
    currentPhoto: String,
  },
  setup(props) {
    const hasThumb = ref(false);
    const thumbImage = ref(null);
    const submitButton = ref(null);
    const { avatar_url } = toRefs(userState);

    const handleFileUpload = (e) => {
      let reader = new FileReader();

      reader.onload = (e) => {
        thumbImage.value = e.target.result;
        hasThumb.value = true;

        if (!props.onlyVisual) {
          submitButton.value.click();
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    };

    watch(avatar_url, (cur) => {
      if (!!cur) {
        hasThumb.value = true;
        thumbImage.value = avatar_url.value;
      }
    });

    onMounted(() => {
      if (props.onlyVisual && props.currentPhoto) {
        hasThumb.value = true;
        thumbImage.value = props.currentPhoto;
      }
    });

    return {
      hasThumb,
      thumbImage,
      handleFileUpload,
      avatar_url,
      submitButton,
    };
  },
};
</script>
