const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = (element) => {
  let btColor = window
    .getComputedStyle(element, null)
    .getPropertyValue("background-color");
  btColor = btColor.replace(/[rgb() ]/g, "").split(",");

  return (
    "#" +
    componentToHex(parseInt(btColor[0])) +
    componentToHex(parseInt(btColor[1])) +
    componentToHex(parseInt(btColor[2]))
  );
};

const buttonProps = (element) => {
  return {
    Text: element.textContent,
    Color: rgbToHex(element),
    Section: element.getAttribute("track-section"),
  };
};

export { buttonProps };
