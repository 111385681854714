<template>
  <countdown :time="dateInMilliseconds" format="DD HH:mm:ss">
    <template #default="{ resolved }">
      <div class="is-flex is-flex-wrap-wrap">
        <div>
          <span class="countdown-label mr-1">{{ label }}:</span>
        </div>
        <div>
          <b class="countdown-item mr-1"> {{ resolved.D }}d</b>
          <b class="countdown-item mr-1">{{ resolved.H }}h</b>
          <b class="countdown-item mr-1">{{ resolved.m }}m</b>
          <b class="countdown-item mr-1">{{ resolved.s }}s</b>
        </div>
      </div>
    </template>
  </countdown>
</template>

<script>
import Countdown from "vue3-countdown";

import { computed } from "vue";

export default {
  components: {
    Countdown,
  },
  props: {
    endDate: String,
    label: String,
  },
  setup(props) {
    const dateInMilliseconds = computed(
      () => new Date(props.endDate).valueOf() - new Date().valueOf(),
    );

    return {
      dateInMilliseconds,
    };
  },
};
</script>
