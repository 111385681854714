<template>
  <div class="crawled-auction-filters-mobile mb-4">
    <div class="columns is-centered is-hidden-desktop is-marginless is-mobile">
      <div
        class="is-flex is-centered open-modal-filter button"
        @click="modalFilterOpen = true"
      >
        Filtre sua busca
      </div>
    </div>
    <div class="mt-3 filter-msg is-hidden-desktop is-mobile">
      <VerifiedIcon/> <span> Eventos com assessoria da erural </span>
    </div>

    <div
      class="crawled-auction-filters-fixed is-hidden-desktop"
      :class="{ float: isFloat }"
    >
    <div class="columns is-mobile is-multiline">
      <div
        class="column is-narrow is-flex is-align-items-center"
        @click="modalFilterOpen = true"
      >
        <FunnelIcon />
        <span class="px-2 has-text-weight-medium mr-2"> Filtros </span>
        <div class="separator" />
      </div>
      <div class="column pt-1 chip-content is-flex pr-0">
        <div class="column is-12 pb-0 pt-3" @click="modalFilterOpen = true">
          <p
            v-if="!isFiltered"
            class="label has-text-grey-lighter has-text-weight-normal is-size-7 pb-1"
          >
            Que tipo de evento você procura?<br />
            Clique aqui e defina
          </p>
          <p v-else class="filter-text pb-1" v-html="filterText" />
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import FunnelIcon from "icons/cached/filter_alt.svg";
import VerifiedIcon from "icons/cached/verified.svg";
import { useCrawledAuctions } from "hooks/useCrawledAuctions";
import useScrollPosition from "hooks/useScrollPosition";
import { ref, toRefs, watch, computed } from "vue";
export default {
  components: {
    FunnelIcon, VerifiedIcon,
  },
  props: {
    chip: Array,
  },
  setup() {
    const {
      modalFilterOpen,
      filter,
      applyFilters,
      isFiltered,
    } = useCrawledAuctions();

    const isFloat = ref(false);

    const { x, y } = useScrollPosition();

    const filterText = computed(() => {
      let breeds = " animais";

      if (filter.breed.length && filter.breed.length > 1) {
        breeds =
          ' <span class="has-text-weight-semibold">' +
          filter.breed.length +
          " raças" +
          "</span>";
      } else if (filter.breed.length) {
        breeds =
          ' <span class="has-text-weight-semibold">' + filter.breed + "</span>";
      }

      let states = "";

      if (filter.state.length && filter.state.length > 1) {
        states +=
          'em <span class="has-text-weight-semibold">' +
          filter.state.length +
          " estados" +
          "</span>";
      } else if (filter.state.length) {
        states +=
          'em <span class="has-text-weight-semibold">' +
          filter.state +
          "</span>";
      }

      let date = "hoje";

      if (filter.date.length) {
        let dateFilter = filter.date.split("-");
        date =
          '<span class="has-text-weight-semibold">' +
          dateFilter[2] +
          "/" +
          dateFilter[1] +
          "</span>";
      }

      return `Buscando por ${breeds} ${states} a partir de ${date}.`;
    });

    watch(y, () => {
      if (y.value > 260) {
        isFloat.value = true;
      } else {
        isFloat.value = false;
      }
    });

    return {
      modalFilterOpen,
      isFloat,
      filterText,
      applyFilters,
      isFiltered,
      ...toRefs(filter),
    };
  },
};
</script>
