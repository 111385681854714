<template>
  <div class="form-section mt-4">
    <p class="form-section-subtitle">
      Preencha as informações para ativar o alerta
    </p>
    <div class="columns is-multiline mb-5 mt-2 mx-1">
      <div class="column is-12">
        <AppInput
          placeholder="Digite seu nome"
          label="Nome"
          :value="state.userName"
          @input="(v) => (state.userName = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          ref="emailInput"
          name="email"
          type="email"
          placeholder="Digite seu email"
          label="E-mail"
          :required="true"
          :isSynchronized="true"
          :value="state.userEmail"
          @input="(v) => (state.userEmail = v)"
        />
      </div>
      <div class="column is-12">
        <AppInput
          v-mask="['(##) ####-####', '(##) #####-####']"
          label="Telefone"
          placeholder="Digite seu telefone"
          type="tel"
          :value="state.userPhone"
          :disabled="true"
        />
      </div>
      <div v-if="errorMessage" class="column is-12">
        <div class="notification is-light is-danger">Ops, algo deu errado!</div>
      </div>
    </div>
    <button
      class="button is-warning is-fullwidth has-text-centered"
      :class="{
        'is-loading': state.isSubmitting,
      }"
      :disabled="isDisabled"
      @click="finishFlow"
    >
      Ativar alerta
    </button>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import AppInput from "components/ui/AppInput.vue";
import { computed, ref } from "vue";

import { state, checkPhone, checkEmail, addAnimation } from "./alertState";
import { alert_subscriptions_create_url } from "utils/routes";
import api from "services/api";

export default {
  directives: {
    mask,
  },
  components: { AppInput },
  props: {},
  setup() {
    const errorMessage = ref(false);
    const isDisabled = computed(() => {
      return !checkEmail() || !state.userName || !checkPhone();
    });

    const finishFlow = () => {
      state.isSubmitting = true;
      errorMessage.value = false;

      state.conversionData.lp_conversion.name = state.userName;
      state.conversionData.lp_conversion.email = state.userEmail;
      state.conversionData.lp_conversion.phone = state.userPhone;

      api
        .post(state.conversionData.action, state.conversionData)
        .then((data) => {
          const { customer_id, phone_area, phone } = data.data;
          const alert_subscription = {
            customer_id: customer_id,
            customer_phone: phone_area + phone,
            alert_type: state.conversionData.additional_infos[0].answer,
            alert_name: state.conversionData.additional_infos[1].answer,
          };

          api
            .post(alert_subscriptions_create_url(), alert_subscription)
            .then((data) => {
              const { had_alert } = data.data;
              addAnimation();
              state.activeTab = had_alert ? "registered" : "finish";
              state.registered = true;
              state.isSubmitting = false;

              mixpanel.track("Radar - Finished Flow", {
                Origin: state.origin,
              });

              if (!had_alert) {
                mixpanel.track("Radar - Created Alert", {
                  Origin: state.origin,
                });
              }
            });
        })
        .catch(() => {
          state.registered = false;
          errorMessage.value = true;
          state.isSubmitting = false;
        });
    };

    return {
      state,
      isDisabled,
      errorMessage,
      finishFlow,
    };
  },
};
</script>
