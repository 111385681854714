<template>
  <div class="result-field">
    <div class="header">
      <div class="header-pic">
        <figure class="image is-square">
          <img :src="responsible.avatar_url" />
        </figure>
      </div>
      <div class="header-name">
        <span class="is-capitalized"> {{ firstName }} </span>
        da erural
      </div>
    </div>
    <div class="body">
      <div class="body-title has-text-centered">
        Buscando o touro ideal
        <br class="is-hidden-desktop show-for-small-only" />
        para você!
      </div>
      <div class="body-subtitle">
        Logo enviarei o resultado pelo
        <br class="is-hidden-desktop show-for-small-only" />
        WhatsApp,
        <br class="is-hidden-mobile" />
        mas você pode falar
        <br class="is-hidden-desktop show-for-small-only" />
        comigo agora.
      </div>
      <button class="body-btn button is-primary mb-4" @click="openWhatsapp()">
        <WhatsappIcon />
        Falar agora com
        <span class="is-capitalized">
          {{ firstName }}
        </span>
      </button>
    </div>
    <div v-if="batches.length != 0" class="suggestions is-hidden-tablet">
      <div class="suggestions-title">Confira outros lotes:</div>
      <div class="suggestions-offers">
        <ProductCard
          v-for="(batch, i) in batches"
          :key="i"
          :batch="batch"
          :classes="['is-6-mobile', 'is-4-widescreen', 'is-6-tablet']"
          section="Suggestions"
          page="Ideal Bull"
        />
      </div>
      <div class="has-text-centered">
        <a id="moreOffers" :href="search_ad_path({ breeds: breedFilter })">
          Ver mais ofertas
        </a>
      </div>
    </div>
  </div>
  <div class="outside">
    <div v-if="batches.length != 0" class="is-hidden-mobile">
      <div class="outside-title">Confira outros lotes</div>
      <div class="cards">
        <ProductCard
          v-for="(batch, i) in batches"
          :key="i"
          :batch="batch"
          :classes="['is-4-touch', 'is-3-desktop']"
          section="Suggestions"
          page="Ideal Bull"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, onMounted } from "vue";
import { quizState } from "./questions.js";
import { search_ad_path, root_path } from "utils/routes";

import ProductCard from "components/product/ProductCard.vue";

import WhatsappIcon from "icons/cached/whatsapp.svg";
import manoelImg from "images/ideal_bull/Manoel.png";

export default {
  components: {
    WhatsappIcon,
    ProductCard,
  },
  props: {
    responsible: Object,
  },
  setup(props) {
    const { suggestedBatches, breedFilter, newCustomer } = toRefs(quizState);
    const firstName = props.responsible.name.split(" ")[0];

    onMounted(() => {
      mixpanel.track_links("#moreOffers", "Ideal Bull - See Offers Clicked");
    });

    const batches = computed(() => {
      return suggestedBatches.value.slice(0, 4);
    });

    const whatsappText = computed(() => {
      let text = `Oi! Sou ${newCustomer.value["name"]} e gostaria de saber qual é meu Touro Ideal.`;
      return `https://api.whatsapp.com/send?phone=${props.responsible.number}&text=${text}`;
    });

    const openWhatsapp = () => {
      mixpanel.track("Ideal Bull - Contact Us Clicked");
      window.open(whatsappText.value, "_blank");
    };

    return {
      suggestedBatches,
      firstName,
      search_ad_path,
      breedFilter,
      batches,
      root_path,
      openWhatsapp,
      manoelImg,
    };
  },
};
</script>
