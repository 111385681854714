<template>
  <button
    :class="{ 'chico-bt': true, active: openModal }"
    @click="openModal = true"
  >
    <img :src="chicoImg" alt="Chico da erural">
  </button>

  <p v-show="chicoTextVisible" class="chico-text" @click="openModal = true">
    <button class="delete" @click.stop.prevent="(chicoTextVisible = false)" />
    Você ganhou <span class="has-text-weight-semibold">R$500</span> de desconto no frete para os lotes deste evento. Clique aqui para resgatar.
    <br />
    <p class="small">Oferta válida para compras a partir de dois lotes</p>
  </p>

  <div class="modal chico-modal" :class="{ 'is-active': openModal }">
    <div class="modal-background" @click="(openModal = false)" />
    <div class="modal-content">
      <button class="delete" @click.prevent="(openModal = false)" />

      <span class="title is-block has-text-centered has-text-weight-bold">
        Ficou interessado?
      </span>
      <span class="description is-block has-text-centered mb-5">
        Registre-se e resgate o seu benefício com um de nossos consultores no WhatsApp.
      </span>

      <AuctionPreRegistrationForm
        :name="name"
        :email="email"
        :phone="phone"
        lpRedirectLink="https://www.erural.net/wpp/gc-500"
        :lpConversion="lpConversion"
        :trackData="trackData"
        :page="page"
        section="Chico"
        :mixpanelData="mixpanelData"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import AuctionPreRegistrationForm from "components/auction/AuctionPreRegistrationForm.vue";

import chicoImg from "images/chico/chico.png";

export default {
  components: {
    AuctionPreRegistrationForm,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    page: String,
    mixpanelData: Object,
  },
  setup(props) {
    const chicoTextVisible = ref(false);
    onMounted(() => setTimeout(() => {chicoTextVisible.value = true}, 5000));

    const openModal = ref(false);

    props.lpConversion.additional_infos.push({
      question: 'Promoção',
      answer: 'Chico - Desconto no frete'
    })

    return {
      chicoTextVisible,
      openModal,
      chicoImg,
    };
  },
};
</script>
