<template>
  <div class="identification">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
      <a :href="root_path()">
        <img :src="eruralLogo" alt="logo erural" class="logo" />
      </a>

      <ArrowBackIcon
        v-if="state.previuosTab"
        class="arrow-back"
        @click="setActiveTab(state.previuosTab)"
      />
    </div>

    <div class="modal-body">
      <div class="content top">
        <span v-if="state.currentFlow == 'bid'" class="title">
          Estamos quase lá!
        </span>
        <p v-else class="title">Identificação</p>
        <p v-if="state.currentFlow == 'bid'" class="description">
          Para finalizar, precisamos confirmar seus dados. Preencha os seus
          dados abaixo e confirme o seu pré-lance.
        </p>
        <p v-else class="description">
          Estamos animados por você querer participar deste evento. Por favor,
          preencha o formulário abaixo com suas informações para podermos
          identificá-lo melhor.
        </p>
      </div>

      <div class="content middle">
        <form @submit.prevent="createConversion">
          <div class="field">
            <AppInput
              type="text"
              :value="state.currentTrackedUser.name"
              placeholder="Digite seu nome"
              label="Nome"
              is-synchronized
              required
              @input="
                (value) => {
                  state.currentTrackedUser.name = value;
                }
              "
            />
          </div>
          <div class="field">
            <AppInput
              type="text"
              :value="state.currentTrackedUser.email"
              placeholder="Digite seu e-mail"
              label="E-mail"
              is-synchronized
              required
              @input="
                (value) => {
                  state.currentTrackedUser.email = value;
                }
              "
            />
          </div>
          <div class="field">
            <AppInput
              v-mask="['(##) ####-####', '(##) # ####-####']"
              type="tel"
              :value="state.currentTrackedUser.phone"
              placeholder="Digite seu telefone"
              label="Telefone"
              is-synchronized
              @input="
                (value) => {
                  state.currentTrackedUser.phone = value;
                }
              "
            />
          </div>
          <div class="mb-4 field">
            <AppInput
              v-mask="['###.###.###-##']"
              type="tel"
              :value="state.currentTrackedUser.cpf"
              placeholder="Digite seu CPF"
              label="CPF"
              :error="errorCPF"
              message="CPF inválido"
              :show-message="errorCPF"
              is-synchronized
              required
              @input="
                (value) => {
                  state.currentTrackedUser.cpf = value;
                }
              "
            />
          </div>
          <p
            v-if="state.currentFlow == 'register'"
            class="has-text-weight-semibold is-size-7 has-text-grey"
          >
            *Os eventos exigem verificação de documentos devido ao parcelamento
            oferecido. Mas não se preocupe, seus dados estão seguros e serão
            utilizados apenas para fins relacionados aos eventos.
          </p>
          <div v-if="state.currentFlow == 'bid'">
            <input
              type="checkbox"
              v-model="state.bidTermsAccepted"
              class="mt-2 mr-2"
            />
            <label
              >Eu declaro que li e aceito os
              <a
                href="/assets/Pré-Lance - Termo de Compromisso.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos e Condições
              </a>
            </label>
          </div>

          <button
            class="button is-primary mt-6 is-fullwidth"
            :disabled="
              state.isSubmitting ||
              !isValidCPF() ||
              acceptedBidTerms() ||
              !completedIdentification()
            "
            :class="{ 'is-loading': state.isSubmitting }"
            @click="
              () => {
                if (state.currentFlow == 'bid') state.isRegisteringBid = true;
                createConversion();
                setActiveTab('confirm');
              }
            "
          >
            Confirmar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "components/ui/AppInput.vue";
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import eruralLogo from "images/logos/erural_beige_dark.png";

import { root_path } from "utils/routes";

import {
  state,
  createConversion,
  setActiveTab,
  closeModal,
  completedIdentification,
  isValidCPF,
  errorCPF,
} from "./auctionRegistrationModalContext";

import { mask } from "vue-the-mask";
import { onMounted } from "vue";

export default {
  components: {
    AppInput,
    ArrowBackIcon,
  },
  directives: {
    mask,
  },
  setup() {
    onMounted(() => {
      switch (state.currentFlow) {
        case "bid":
          state.previuosTab = "bid";
          break;
        case "register":
          state.previuosTab = "";
          break;
        case "proposal":
          state.previuosTab = "proposal";
          break;
      }
    });

    const acceptedBidTerms = () => {
      if (state.currentFlow == "bid" && !state.bidTermsAccepted) return true;

      return false;
    };

    return {
      state,
      createConversion,
      setActiveTab,
      completedIdentification,
      closeModal,
      isValidCPF,
      acceptedBidTerms,
      eruralLogo,
      root_path,
      errorCPF,
    };
  },
};
</script>
