<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('login')"
      />
      <span class="title">Olá, {{ name }}</span>
      <p class="description">Digite sua senha</p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <PasswordInput
            ref="passwordInput"
            class="password-input"
            placeholder="Digite"
            required
            @input="(v) => (password = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div v-show="error" class="content">
        <span class="has-text-danger">Senha inválida! Tente novamente.</span>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          :class="{ 'is-loading': submitting }"
          @click="signIn"
        >
          Entrar
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('password_recovery')"
        >
          Esqueci minha senha
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import PasswordInput from "components/ui/PasswordInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import api from "services/api";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { ref, watch } from "vue";
import { user_session_path } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
    PasswordInput,
    FacebookAuthButton,
  },
  setup() {
    const { updateActiveAuthStep, name, login } = useAuthInfos();

    const password = ref("");
    const passwordInput = ref(null);

    const isDisabled = ref(true);
    const submitting = ref(false);
    watch([password, submitting], () => {
      isDisabled.value =
        !passwordInput.value.inputEl.checkValidity() || submitting.value;
    });

    const error = ref(false);
    const signIn = () => {
      error.value = false;
      submitting.value = true;

      let url = new URL(window.location.href);
      let redirect_path = url.searchParams.get("redirect_path");

      api
        .post(user_session_path(), {
          user: {
            login: login.value,
            password: password.value,
          },
          redirect_path: redirect_path,
        })
        .then((response) => {
          window.location = response.data.redirect_url;
        })
        .catch((err) => {
          error.value = true;
          submitting.value = false;
          mixpanel.track('User Login Error', { 'Form Login': login.value });
        });
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      password,
      signIn,
      error,
      submitting,
      name,
      passwordInput,
    };
  },
};
</script>
