<template>
  <div
    v-if="isVisible"
    :class="!!classes ? classes : 'column is-narrow custom-size-card px-0'"
  >
    <div class="featured-card-background">
      <a
        :id="`featuredAuction${auction.id}`"
        :href="auctions_show_path(auction.url_friendly)"
        class="featured-card"
      >
        <figure class="auction-banner image">
          <img :src="auction.banner_medium" :alt="auction.title" />
        </figure>
        <div class="auction-content">
          <div class="auction-title-container">
            <span class="auction-title">
              {{ auction.title }}
            </span>
          </div>
          <div
            v-if="auction.breed"
            class="auction-infos has-text-weight-medium"
          >
            <DnaIcon />
            <span class="ml-1">{{ auction.breed }}</span>
          </div>
          <div class="auction-infos has-text-weight-medium">
            <SignalIcon />
            <span class="ml-1">{{ auction.age }}</span>
          </div>
          <div v-if="auction.start_day" class="auction-date is-uppercase">
            <TodayIcon />
            <div class="ml-1">
              <span
                >{{ auction.start_day }}
                <span v-if="monthAbbr != endMonthAbbr">{{
                  monthAbbr
                }}</span></span
              >
              <span v-if="auction.end_day"> a {{ auction.end_day }}</span>
              {{ endMonthAbbr }} | {{ auction.start_hour }}
            </div>
          </div>
          <div v-else class="auction-date">
            <CartIcon />
            <p class="ml-1">Lotes disponíveis agora</p>
          </div>
        </div>
        <div v-if="auction.featured" class="auction-featured is-hidden-tablet">
          <VerifiedIcon />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { useCrawledAuctions } from "hooks/useCrawledAuctions";
import CartIcon from "icons/cached/shopping_cart.svg";
import DnaIcon from "icons/cached/dna.svg";
import TodayIcon from "icons/cached/today.svg";
import SignalIcon from "icons/cached/signal_cellular_alt.svg";
import VerifiedIcon from "icons/cached/verified.svg";
import { onMounted, ref, watch } from "vue";

import { auctions_show_path } from "utils/routes";

export default {
  components: { CartIcon, DnaIcon, TodayIcon, SignalIcon, VerifiedIcon },
  props: {
    classes: String,
    auction: Object,
    page: String,
    section: String,
  },
  setup(props) {
    const isVisible = ref(true);
    const { filter, featuredVisible, auctions } = useCrawledAuctions();

    const monthAbbr = props.auction.month_name.slice(0, 3);
    const endMonthAbbr = props.auction.end_month_name.slice(0, 3);

    watch(auctions, () => {
      isVisible.value = true;

      if (
        !!filter.breed.length &&
        filter.breed.indexOf(props.auction.breed) == -1
      ) {
        isVisible.value = false;
      }

      if (
        !!filter.category.length &&
        filter.category.indexOf(props.auction.category) == -1
      ) {
        isVisible.value = false;
      }

      if (
        !!filter.state.length &&
        filter.state.indexOf(props.auction.state) == -1
      ) {
        isVisible.value = false;
      }

      if (
        !!filter.shipping.length &&
        filter.shipping.indexOf(props.auction.shipping) == -1
      ) {
        isVisible.value = false;
      }

      let dateFilter = new Date(filter.date);
      let dateCard = new Date(props.auction.start_date);

      if (dateFilter > dateCard) {
        isVisible.value = false;
      }

      return isVisible;
    });

    watch(isVisible, () => {
      if (isVisible.value) {
        featuredVisible.value++;
      } else {
        featuredVisible.value--;
      }
    });

    onMounted(() => {
      if (isVisible.value) {
        featuredVisible.value++;
      } else {
        featuredVisible.value--;
      }

      mixpanel.track_links(
        "#featuredAuction" + props.auction.id,
        "Event Clicked",
        function (element) {
          const parent = element.parentNode;
          const pos = [].indexOf.call(parent.parentNode.children, parent);

          return {
            Page: props.page,
            Section: props.section,
            Position: pos + 1,
            "Event Name": props.auction.title,
          };
        },
      );
    });

    return {
      auctions_show_path,
      isVisible,
      monthAbbr,
      endMonthAbbr,
    };
  },
};
</script>
