<template>
  <div class="column is-7-desktop is-12-mobile auth-container">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('login')"
      />
      <span class="title">
        Você é novo aqui.<br />
        Vamos criar uma conta?
      </span>
      <p class="description">Qual o seu nome completo?</p>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            ref="nameInput"
            type="text"
            placeholder="Digite"
            :value="name"
            required
            @input="(v) => (name = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          @click="updateName"
        >
          Próximo
        </button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6">
        <button
          class="button is-outlined is-fullwidth"
          @click="updateActiveAuthStep('login_review')"
        >
          Já tenho uma conta
        </button>
      </div>

      <div class="column is-6">
        <FacebookAuthButton />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import FacebookAuthButton from "components/user/FacebookAuthButton.vue";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { onMounted, ref, watch } from "vue";

export default {
  components: {
    ArrowBackIcon,
    AppInput,
    FacebookAuthButton,
  },
  setup() {
    const {
      updateActiveAuthStep,
      updateAuthUser,
      name,
    } = useAuthInfos();

    const nameInput = ref(null);

    const isDisabled = ref(true);
    watch(name, () => {
      isDisabled.value = !nameInput.value.inputEl.checkValidity();
    });

    onMounted(() => {
      isDisabled.value = !nameInput.value.inputEl.checkValidity();
    });

    const updateName = () => {
      updateAuthUser({ name: name.value });
      updateActiveAuthStep("email");
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      updateName,
      nameInput,
      name,
    };
  },
};
</script>
