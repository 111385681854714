<template>
  <div class="column is-7-desktop is-12-mobile auth-container is-relative">
    <div class="box auth-box">
      <ArrowBackIcon
        class="arrow-back"
        @click="updateActiveAuthStep('login')"
      />
      <span class="title is-size-7 mb-5">Esqueceu sua senha?</span>
      <p v-show="!notificationMessage" class="description">
        Informe-nos o email de sua conta.
      </p>
      <div v-show="!notificationMessage" class="columns">
        <div class="column is-offset-2 is-8">
          <AppInput
            ref="emailInput"
            type="email"
            :value="email"
            placeholder="Digite seu e-mail"
            required
            :error="emailInputError"
            :show-message="emailInputError"
            :message="errorMessage"
            @input="(v) => (email = v)"
            @enterPressed="$refs.confirmBt.click()"
          />
        </div>
      </div>

      <div v-show="!notificationMessage" class="button-container">
        <button
          ref="confirmBt"
          class="button is-warning"
          :disabled="isDisabled"
          :class="{ 'is-loading': submitting }"
          @click="recoverPassword"
        >
          Recuperar senha
        </button>
      </div>

      <div v-show="notificationMessage">
        <p class="mb-5">
          {{ notificationMessage }}
        </p>

        <div class="has-text-centered">
          <button
            class="button is-primary"
            @click="updateActiveAuthStep('login')"
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBackIcon from "icons/cached/arrow_back.svg";
import AppInput from "components/ui/AppInput.vue";
import api from "services/api";
import { useAuthInfos } from "components/user/useAuthInfos.js";
import { ref, watch, onMounted } from "vue";
import { user_password_path } from "utils/routes";

export default {
  components: {
    ArrowBackIcon,
    AppInput,
  },
  setup() {
    const { updateActiveAuthStep, email } = useAuthInfos();

    const emailInput = ref(null);

    const emailInputError = ref(false);
    const errorMessage = ref("");

    const isDisabled = ref(true);
    const submitting = ref(false);
    watch(email, () => {
      isDisabled.value =
        !emailInput.value.inputEl.checkValidity() || submitting.value;

      if (!emailInput.value.inputEl.checkValidity()) {
        emailInputError.value = true;
        errorMessage.value = "E-mail inválido! Tente novamente";
      } else {
        emailInputError.value = false;
      }
    });

    onMounted(() => {
      isDisabled.value =
        !emailInput.value.inputEl.checkValidity() || submitting.value;

      mixpanel.track("Recover Password Clicked", { "Form Email": email.value });
    });

    const notificationMessage = ref(null);
    const recoverPassword = () => {
      submitting.value = true;
      emailInputError.value = false;

      api
        .post(user_password_path(), {
          user: {
            email: email.value,
          },
        })
        .then((response) => {
          notificationMessage.value =
            "Pronto, dentro de instantes você receberá um e-mail com instruções para a troca da sua senha.";
        })
        .catch((err) => {
          submitting.value = false;
          errorMessage.value = "E-mail não cadastrado.";
          emailInputError.value = true;
        });
    };

    return {
      updateActiveAuthStep,
      isDisabled,
      email,
      submitting,
      recoverPassword,
      notificationMessage,
      emailInput,
      emailInputError,
      errorMessage,
    };
  },
};
</script>
