<template>
  <section class="p-4 purchase-history-container">
    <h2
      class="has-text-weight-semibold has-text-centered"
      v-if="!purchases || purchases.length == 0"
    >
      Nenhuma compra encontrada
    </h2>

    <div
      v-else
      v-for="purchase in purchases"
      :key="purchase.purchase_id"
      class="mb-4"
    >
      <p class="has-text-weight-semibold mb-2">
        {{ formatLongDate(purchase.created_at) }}
      </p>

      <a
        :href="`${ERURAL_PAY_URL}/resumo-da-compra/${purchase.purchase_id}?representative_cpf=${cpf}`"
        :id="`purchaseId${purchase.purchase_id}`"
      >
        <div class="purchase-card p-3 is-clickable">
          <h2 class="mb-1">{{ purchase.event_name }}</h2>
          <div class="is-flex">
            <span class="tag mr-2">{{ purchase.batch_info }}</span>
            <span class="tag">
              {{ purchase.animals_count }}
              {{ purchase.animals_count > 1 ? "ANIMAIS" : "ANIMAL" }}
            </span>
          </div>

          <div
            class="is-flex is-justify-content-space-between is-align-items-center"
          >
            <span
              v-if="purchase.step"
              class="step is-light"
              :class="{
                'is-info': ['Em transporte', 'Aguardando rota'].includes(
                  purchase.step,
                ),
                'is-success': ['Compra entregue', 'Pedido realizado'].includes(
                  purchase.step,
                ),
              }"
            >
              <p>{{ purchase.step }}</p>
            </span>
            <span v-else />

            <div class="has-text-right">
              <p class="has-text-weight-semibold">
                {{ formatCurrency(purchase.price) }}
              </p>
              <p class="payment-method">{{ purchase.payment_method }}</p>
            </div>
          </div>

          <div
            class="is-flex is-align-items-center is-justify-content-space-between card-footer px-2 pt-1 mt-2"
          >
            <p>Ver compra</p>
            <ChevronRightIcon class="chevron-icon" />
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import { formatCurrency } from "utils/formatters";
import { formatLongDate } from "utils/formatters";

import ChevronRightIcon from "icons/cached/chevron_right.svg";
import { onMounted } from "vue";

export default {
  components: {
    ChevronRightIcon,
  },
  props: {
    cpf: String,
    purchases: Array,
  },
  setup(props) {
    const ERURAL_PAY_URL = {
      development: "http://localhost:3006",
      staging: "http://ratsap.eruralpay.net",
      production: "http://app.eruralpay.net",
    }[import.meta.env.VITE_RAILS_ENV];

    onMounted(() => {
      props.purchases?.forEach((purchase) => {
        mixpanel.track_links(
          `#purchaseId${purchase.purchase_id}`,
          "History Clicked",
          {
            Page: "Purchase History",
            Section: "Client Menu",
          },
        );
      });
    });

    return { formatLongDate, formatCurrency, ERURAL_PAY_URL };
  },
};
</script>

<style></style>
