<template>
  <button class="alert-button" @click="startFlow">
    <img ref="gif" :src="ButtonNotificationGif" alt="Gif de notificação" />
    <img
      ref="img"
      class="notification-icon"
      :src="NotificationIcon"
      alt="Ícone de notificação"
    />
    <span>Ative um Alerta</span>
  </button>
</template>

<script>
import ButtonNotificationGif from "images/alert_subscription/button_notification.gif";
import NotificationIcon from "images/alert_subscription/alert.png";

import { state } from "./alertState";
import { ref } from "vue";

export default {
  components: {},
  props: {},
  setup() {
    const gif = ref(null);
    const img = ref(null);

    setTimeout(() => {
      gif.value.style.display = "none";
      img.value.style.display = "block";
    }, 3000);

    const startFlow = () => {
      state.activeTab = "phone";
      state.isBottomSheetOpen = true;
      state.origin = "Alert Button";
      mixpanel.track("Radar - Pop Up Viewed", { Origin: state.origin });
    };

    return {
      ButtonNotificationGif,
      NotificationIcon,
      gif,
      img,
      startFlow,
    };
  },
};
</script>
