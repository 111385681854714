<template>
  <div class="columns m-0 user-nav-menu is-mobile is-multiline">
    <div class="column p-0 is-3-mobile is-6-tablet">
      <a
        :href="user_profile_url()"
        class="nav-item"
        :class="{ active: activeButton == 'my-account' }"
      >
        <AccountIcon />
        <span class="has-text-centered"> Minha Conta </span>
      </a>
    </div>
    <div class="column p-0 is-3-mobile is-6-tablet">
      <a
        :href="my_farms_path()"
        class="nav-item"
        :class="{ active: activeButton == 'my-farms' }"
      >
        <FarmIcon />
        <span class="has-text-centered"> Minhas Fazendas </span>
      </a>
    </div>
    <div class="column p-0 is-3-mobile is-6-tablet">
      <a
        :href="my_shoppings_path()"
        class="nav-item"
        :class="{ active: activeButton == 'my-shoppings' }"
      >
        <ReceiptIcon />
        <span class="has-text-centered"> Histórico de Compras </span>
      </a>
    </div>
    <div class="column p-0 is-3-mobile is-6-tablet">
      <a
        :href="my_messages_path()"
        class="nav-item message-nav"
        :class="{ active: activeButton == 'my-message' }"
      >
        <MessageIcon />
        <span class="has-text-centered"> Mensagens </span>
      </a>
    </div>
  </div>
</template>

<script>
import AccountIcon from "icons/cached/account_circle.svg";
import FarmIcon from "icons/cached/farm.svg";
import ReceiptIcon from "icons/cached/receipt.svg";
import MessageIcon from "icons/cached/message.svg";
import {
  user_profile_url,
  my_farms_path,
  my_shoppings_path,
  my_messages_path,
} from "utils/routes";
export default {
  components: {
    AccountIcon,
    FarmIcon,
    ReceiptIcon,
    MessageIcon,
  },
  props: {
    activeButton: String,
  },
  setup() {
    return {
      user_profile_url,
      my_farms_path,
      my_shoppings_path,
      my_messages_path,
    };
  },
};
</script>
