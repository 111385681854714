<template>
  <div v-if="isOpen" class="mobile-filters">
    <div class="mobile-filters-header is-relative">
      <CloseIcon @click.prevent="$emit('close')" />
      <span class="has-text-weight-semibold"> Filtre sua busca </span>
    </div>
    <hr />
    <div class="mobile-filters-body">
      <div class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="
            classificationsSelectOpen = !classificationsSelectOpen
          "
        >
          <div class="left-items">
            <ClassificationsIcon />
            <span class="row-name">Classificação</span>
          </div>
          <div
            class="right-items"
            :class="{ selected: classifications.length > 0 }"
          >
            <span class="row-info">
              {{
                classifications.length > 0
                  ? classifications[0] +
                    " " +
                    greaterThanZero(classifications.length - 1)
                  : "Selecione"
              }}
            </span>
            <ExpandIcon v-if="!classificationsSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="classificationsSelectOpen" class="filter-body">
          <div class="checkbox">
            <input
              id="Reposição"
              v-model="classifications"
              type="checkbox"
              value="Reposição"
            />
            <label for="Reposição">Reposição</label>
          </div>
          <div class="checkbox">
            <input
              id="Genética"
              v-model="classifications"
              type="checkbox"
              value="Genética"
            />
            <label for="Genética">Genética</label>
          </div>
          <div v-if="genetic.length != 0" class="ml-4">
            <div class="checkbox">
              <input
                id="P.O."
                v-model="genetic"
                type="checkbox"
                value="P.O."
              />
              <label for="P.O.">Puro de origem - P.O.</label>
            </div>
            <div class="checkbox">
              <input
                id="P.A."
                v-model="genetic"
                type="checkbox"
                value="P.A."
              />
              <label for="P.A.">Puro por Avaliação - P.A.</label>
            </div>
            <div class="checkbox">
              <input
                id="P.C."
                v-model="genetic"
                type="checkbox"
                value="P.C."
              />
              <label for="P.C.">Puro por Cruzamento - P.C.</label>
            </div>
            <div class="checkbox">
              <input
                id="CEIP"
                v-model="genetic"
                type="checkbox"
                value="CEIP"
              />
              <label for="CEIP">Animal com CEIP</label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="categoriesSelectOpen = !categoriesSelectOpen"
        >
          <div class="left-items">
            <CategoriesIcon />
            <span class="row-name">Categoria</span>
          </div>
          <div class="right-items" :class="{ selected: categories.length > 0 }">
            <span class="row-info">
              {{
                categories.length > 0
                  ? categories[0] + " " + greaterThanZero(categories.length - 1)
                  : "Selecione"
              }}
            </span>
            <ExpandIcon v-if="!categoriesSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="categoriesSelectOpen" class="filter-body">
          <div
            v-for="(category, index) in categoriesOptions"
            :key="index"
            class="checkbox"
          >
            <input
              :id="category"
              v-model="categories"
              type="checkbox"
              :value="category"
            />
            <label :for="category">{{ category }}</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="breedsSelectOpen = !breedsSelectOpen"
        >
          <div class="left-items">
            <BreedsIcon />
            <span class="row-name">Raças</span>
          </div>
          <div class="right-items" :class="{ selected: breeds.length > 0 }">
            <span class="row-info">
              {{
                breeds.length > 0
                  ? breeds[0] + " " + greaterThanZero(breeds.length - 1)
                  : "Selecione"
              }}
            </span>
            <ExpandIcon v-if="!breedsSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="breedsSelectOpen" class="filter-body">
          <div
            v-for="(breed, index) in breedsOptions"
            :key="index"
            class="checkbox"
          >
            <input
              :id="breed"
              v-model="breeds"
              type="checkbox"
              :value="breed"
            />
            <label :for="breed">{{ breed }}</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="agesSelectOpen = !agesSelectOpen"
        >
          <div class="left-items">
            <AgesIcon />
            <span class="row-name">Idade</span>
          </div>
          <div class="right-items" :class="{ selected: ages.length > 0 }">
            <span class="row-info">
              {{
                ages.length > 0
                  ? ages[0] + " " + greaterThanZero(ages.length - 1)
                  : "Selecione"
              }}
            </span>
            <ExpandIcon v-if="!agesSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="agesSelectOpen" class="filter-body">
          <div
            v-for="(age, index) in agesOptions"
            :key="index"
            class="checkbox"
          >
            <input :id="age" v-model="ages" type="checkbox" :value="age" />
            <label :for="age">{{ age }}</label>
          </div>
        </div>
      </div>
      <hr />
      <div v-if="userAdmin" class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="geneticEvaluationSelectOpen = !geneticEvaluationSelectOpen"
        >
          <div class="left-items">
            <GeneticEvaluationIcon />
            <span class="row-name">Avaliações Genéticas</span>
          </div>
          <div class="right-items">
            <span class="row-info">
              Selecione
            </span>
            <ExpandIcon v-if="!geneticEvaluationSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="geneticEvaluationSelectOpen" class="filter-body">
          <div class="filter">
            <div class="has-text-weight-bold mb-2">
              <label>iABCZ</label>
            </div>
            <FlashMessage
              message="Valor máximo do iABCZ não pode ser menor que o valor mínimo."
              :isOpen="showFlashMessage"
              :isSyncronized="true"
              @close="showFlashMessage = false"
            />
            <div class="is-flex is-flex-direction-column is-align-content-center mx-2">
              <div class="is-flex">
                <input
                  v-model="zebuIndexes.min"
                  v-money="zebuIndexes.min != '' ? zebuIndexMask : ''"
                  placeholder="Mín."
                  class="input mr-4"
                >
                <input
                  v-model="zebuIndexes.max"
                  v-money="zebuIndexes.max != '' ? zebuIndexMask : ''"
                  placeholder="Máx."
                  class="input"
                >
              </div>
              <button
                class="button is-outlined is-small is-primary mx-6 mt-4"
                @click="clearZebuIndex"
              >
                Limpar Índice
              </button>
            </div>
          </div>
          <div class="filter mt-4">
            <div class="has-text-weight-bold mb-2">
              <label>DECA</label>
            </div>
            <div class="is-flex is-flex-direction-column">
              <div v-for="(ranking, index) in visibleZebuRanking" :key="index" class="checkbox ml-2">
                <input :id="ranking" v-model="zebu_ranking" type="checkbox" :value="ranking">
                <label :for="ranking">{{ ranking }}</label>
              </div>
              <button
                class="button is-outlined is-small is-primary mx-6"
                @click="expandedFilters.zebuRanking = !expandedFilters.zebuRanking"
              >
                {{ expandedFilters.zebuRanking ? "Ver menos" : "Ver mais" }}
                <ExpandIcon v-if="!expandedFilters.zebuRanking" />
                <ShrinkIcon v-else />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div v-if="userAdmin" class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="ascendanciesSelectOpen = !ascendanciesSelectOpen"
        >
          <div class="left-items">
            <AscendanciesIcon />
            <span class="row-name">Ascendência</span>
          </div>
          <div class="right-items">
            <span class="row-info">Selecione</span>
            <ExpandIcon v-if="!ascendanciesSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="ascendanciesSelectOpen" class="filter-body">
          <div class="filter-input mb-5">
            <input v-model="searchAscendancy" type="text" class="input" placeholder="Pesquise nos filtros">
          </div>
          <div v-for="(ascendancy, index) in visibleAscendancies" :key="index" class="checkbox">
            <input :id="ascendancy" v-model="ascendancies" type="checkbox" :value="ascendancy">
            <label :for="ascendancy">{{ ascendancy }}</label>
          </div>
          <button
            v-if="searchAscendancy == ''"
            class="button is-outlined is-small is-primary mx-6"
            @click="expandedFilters.ascendancies = !expandedFilters.ascendancies"
          >
            {{ expandedFilters.ascendancies ? "Ver menos" : "Ver mais" }}
            <ExpandIcon v-if="!expandedFilters.ascendancies" />
            <ShrinkIcon v-else />
          </button>
        </div>
      </div>
      <hr />
      <div class="mobile-filter">
        <div
          class="filter-row"
          @click.prevent="statesSelectOpen = !statesSelectOpen"
        >
          <div class="left-items">
            <StatesIcon />
            <span class="row-name">Estados</span>
          </div>
          <div class="right-items" :class="{ selected: states.length > 0 }">
            <span class="row-info">
              {{
                states.length > 0
                  ? states[0] + " " + greaterThanZero(states.length - 1)
                  : "Selecione"
              }}
            </span>
            <ExpandIcon v-if="!statesSelectOpen" />
            <ShrinkIcon v-else />
          </div>
        </div>
        <div v-show="statesSelectOpen" class="filter-body">
          <div v-for="(value, key) in statesOptions" :key="key" class="checkbox">
            <input :id="key" v-model="states" type="checkbox" :value="key" />
            <label :for="key">{{ value }}</label>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="buttons-container">
      <button
        class="button"
        @click.prevent="
          () => {
            clearState();
            clearZebuIndex();
            $emit('close');
          }
        "
      >
        Limpar
      </button>
      <button
        class="button is-primary"
        @click.prevent="
          () => {
            filterZebuIndex();
            if (!showFlashMessage) $emit('close');
          }
        "
      >
        Filtrar
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "icons/cached/close.svg";
import ExpandIcon from "icons/cached/expand_more.svg";
import ShrinkIcon from "icons/cached/expand_less.svg";
import BreedsIcon from "icons/cached/dna.svg";
import CategoriesIcon from "icons/cached/inventory.svg";
import AgesIcon from "icons/cached/signal_cellular_alt.svg";
import ClassificationsIcon from "icons/cached/category.svg";
import StatesIcon from "icons/cached/location_on.svg";
import GeneticEvaluationIcon from "icons/cached/military_tech.svg";
import AscendanciesIcon from "icons/cached/bovine.svg"

import { findMatching } from "utils/matchingString"
import FlashMessage from "components/ui/FlashMessage.vue";

import { filtersState, clearState } from "./filtersState";
import { VMoney } from "v-money";
import { computed, reactive, ref, toRefs, watch } from "vue";

export default {
  directives: {
    money: VMoney,
  },
  components: {
    CloseIcon,
    ExpandIcon,
    ShrinkIcon,
    ClassificationsIcon,
    StatesIcon,
    AgesIcon,
    CategoriesIcon,
    BreedsIcon,
    GeneticEvaluationIcon,
    AscendanciesIcon,
    FlashMessage,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    categoriesOptions: Array,
    breedsOptions: Array,
    agesOptions: Array,
    zebuRankingOptions: Array,
    ascendanciesOptions: Array,
    statesOptions: Object,
    userAdmin: Boolean,
  },
  emits: ["close"],
  setup(props) {
    const { categories, breeds, ages, classifications, genetic, states, zebu_ranking,
        zebu_index_min, zebu_index_max, ascendancies, } =
      toRefs(filtersState);

    const agesSelectOpen = ref(false);
    const categoriesSelectOpen = ref(false);
    const breedsSelectOpen = ref(false);
    const classificationsSelectOpen = ref(false);
    const statesSelectOpen = ref(false);
    const geneticEvaluationSelectOpen = ref(false);
    const ascendanciesSelectOpen = ref(false);

    const greaterThanZero = (num) => {
      return num > 0 ? "+ " + String(num) : "";
    };

    const zebuIndexMask = computed(() => {
      return {
        decimal: ',',
        thousands: '',
        prefix: '',
        precision: 2,
        masked: false,
      }
    });

    const expandedFilters = reactive({
      zebuRanking: false,
      ascendancies: false,
    });

    watch([zebu_ranking, ascendancies], () => {
      if (zebu_ranking.value.length > 4)
        expandedFilters.zebuRanking = true;
      if (ascendancies.value.length > 4)
        expandedFilters.ascendancies = true;
    });

    const visibleZebuRanking = computed(() =>
      expandedFilters.zebuRanking ? props.zebuRankingOptions : props.zebuRankingOptions.slice(0, 4),
    );

    const visibleAscendancies = computed(() =>
      searchAscendancy.value != ""
        ? findMatching(searchAscendancy.value, props.ascendanciesOptions)
        : showAscendanciesOptions.value,
    );

    const showAscendanciesOptions = computed(() =>
      expandedFilters.ascendancies ? props.ascendanciesOptions : props.ascendanciesOptions.slice(0, 4),
    );

    const searchAscendancy = ref("");
    const zebuIndexes = ref({min: "", max: ""});
    const clearZebuIndex = () => {
      zebuIndexes.value = {min: "", max: ""};
    };
    const showFlashMessage = ref(false);

    const filterZebuIndex = () => {
      if(zebuIndexes.value.min != "" && zebuIndexes.value.max != "") {
        if(parseFloat(zebuIndexes.value.max.replace(',', '.')) < parseFloat(zebuIndexes.value.min.replace(',', '.'))) {
          return showFlashMessage.value = true;
        }
      };

      showFlashMessage.value = false;
      zebu_index_min.value = zebuIndexes.value.min;
      zebu_index_max.value = zebuIndexes.value.max;
    };

    watch(classifications, ()=>{
      let search = Object.values(classifications.value)

      if (search.includes('Genética')){
        if (genetic.value.length == 0)
          genetic.value = ['P.O.', 'P.A.', 'P.C.', 'CEIP'];
      } else if (genetic.value.length != 0) {
        genetic.value = [];
      }
    });

    watch(genetic, ()=>{
      if (genetic.value.length == 0){
        let search = Object.values(classifications.value);
        search = search.filter(function(item) {
            return item !== 'Genética';
        })
        classifications.value = search;
      }
    });

    return {
      categories,
      breeds,
      ages,
      classifications,
      genetic,
      states,
      zebuIndexes,
      zebu_ranking,
      ascendancies,
      agesSelectOpen,
      categoriesSelectOpen,
      breedsSelectOpen,
      classificationsSelectOpen,
      statesSelectOpen,
      geneticEvaluationSelectOpen,
      ascendanciesSelectOpen,
      zebuIndexMask,
      visibleZebuRanking,
      visibleAscendancies,
      searchAscendancy,
      expandedFilters,
      clearZebuIndex,
      filterZebuIndex,
      greaterThanZero,
      clearState,
      showFlashMessage,
    };
  },
};
</script>
