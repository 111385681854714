<template>
  <AppInput ref="appInput" :type="inputType" has-icon clickable-icon>
    <component :is="icon" @click="() => (showPassword = !showPassword)" />
  </AppInput>
</template>

<script>
import { ref, computed } from "vue";

import AppInput from "components/ui/AppInput.vue";

import OpenEyeIcon from "icons/cached/visibility.svg";
import CloseEyeIcon from "icons/cached/visibility_off.svg";

export default {
  components: {
    AppInput,
    CloseEyeIcon,
    OpenEyeIcon,
  },
  setup() {
    const appInput = ref(null);
    const inputEl = computed(() => appInput.value?.inputEl);

    const showPassword = ref(false);

    const icon = computed(() =>
      showPassword.value ? "OpenEyeIcon" : "CloseEyeIcon",
    );

    const inputType = computed(() =>
      showPassword.value ? "text" : "password",
    );

    return {
      appInput,
      inputEl,
      showPassword,
      icon,
      inputType,
    };
  },
};
</script>
