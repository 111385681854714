<template>
  <div class="identification">
    <div class="modal-header">
      <button class="delete" @click="closeModal" />
    </div>

    <div class="modal-body">
      <div class="content top mb-3">
        <p class="title">Confirme seus dados</p>
        <p class="description">
          Preencha os campos abaixo para iniciarmos uma conversa:
        </p>
      </div>

      <div class="content middle">
        <form @submit.prevent="createConversion">
          <div class="field">
            <AppInput
              type="text"
              :value="state.currentTrackedUser.name"
              placeholder="Digite seu nome"
              label="Nome"
              is-synchronized
              required
              @input="(value) => (state.currentTrackedUser.name = value)"
            />
          </div>
          <div class="field">
            <AppInput
              type="email"
              :value="state.currentTrackedUser.email"
              placeholder="Digite seu e-mail"
              label="E-mail"
              is-synchronized
              required
              @input="(value) => (state.currentTrackedUser.email = value)"
            />
          </div>
          <div class="field">
            <AppInput
              v-mask="['(##) ####-####', '(##) # ####-####']"
              type="tel"
              :value="state.currentTrackedUser.phone"
              placeholder="Digite seu telefone"
              label="Telefone"
              is-synchronized
              required
              @input="(value) => (state.currentTrackedUser.phone = value)"
            />
          </div>
          <div class="mb-4 field">
            <AppInput
              v-mask="['###.###.###-##']"
              type="tel"
              :value="state.currentTrackedUser.cpf"
              placeholder="Digite seu CPF"
              label="CPF"
              :error="errorCPF"
              message="CPF inválido"
              :show-message="errorCPF"
              is-synchronized
              required
              @input="
                (value) => {
                  state.currentTrackedUser.cpf = value;
                }
              "
            />
          </div>
          <div class="field">
            <label class="message-label">Mensagem</label>
            <textarea
              :value="state.message"
              class="textarea"
              placeholder="Digite uma mensagem para introduzir a conversa"
              required
              @input="state.message = $event.target.value"
            />
          </div>

          <button
            class="button is-primary"
            :disabled="
              state.isSubmitting ||
              state.currentTrackedUser.name == '' ||
              state.currentTrackedUser.email == '' ||
              state.currentTrackedUser.phone == '' ||
              state.message == '' ||
              !isValidCPF()
            "
            :class="{ 'is-loading': state.isSubmitting }"
          >
            <WhatsappIcon class="mr-2" />
            Falar agora
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "components/ui/AppInput.vue";
import WhatsappIcon from "icons/cached/whatsapp.svg";

import { mask } from "vue-the-mask";

import {
  state,
  createConversion,
  closeModal,
  isValidCPF,
  errorCPF,
} from "./auctionRegistrationModalContext";

export default {
  components: {
    AppInput,
    WhatsappIcon,
  },
  directives: {
    mask,
  },
  setup() {
    return {
      state,
      createConversion,
      closeModal,
      errorCPF,
      isValidCPF,
    };
  },
};
</script>
